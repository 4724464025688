import { LoaderFunctionArgs, redirect } from "react-router-dom";
import API from "../../utils/api";

/**
 * Combines two loader functions into one, as to always load the dashboard data
 */
export default function AgentsLoader() {
  return async ({ params, request }: LoaderFunctionArgs) => {
    const user = API.user;
    const currentUrl = new URL(request.url);
    const search = currentUrl.searchParams.get('search');
    
    if (!user) {
      return redirect(`/login`);
    }

    if (!params.agent_id) {

      if (!user.memberships?.[0]?.organization) {
        throw new Error("No organization found");
      }
      
      const organization = user.memberships[0].organization.name;
      const cachedAgentsString = localStorage.getItem('auth::agents');
      let cachedAgents = [];
      if (API.user) {
        try {
          cachedAgents = JSON.parse(cachedAgentsString || '[]');
        } catch (e) {
          cachedAgents = [];
        }
      }
      if (cachedAgents.length) {
        const viewAgent = cachedAgents[0];
        currentUrl.pathname = `/chat/${viewAgent.unique_id}`;
        // Remove welcome parameter from URL: avoid auto-welcoming message
        currentUrl.searchParams.delete('welcome');
        // Use Response object instead of redirect
        return new Response(null, {
          status: 302,
          headers: {
            Location: currentUrl.toString()
          }
        });
      } else {
        const agentsResponse = await API.get("v1/agents", { organization, search });
        if (agentsResponse?.data && agentsResponse.data.length > 0) {
          const viewAgent = agentsResponse.data[0];
          currentUrl.pathname = `/chat/${viewAgent.unique_id}`;
          // Remove welcome parameter from URL: avoid auto-welcoming message
          currentUrl.searchParams.delete('welcome');
          // Use Response object instead of redirect
          return new Response(null, {
            status: 302,
            headers: {
              Location: currentUrl.toString()
            }
          });
        } else {
          currentUrl.pathname = `/chat/new`;
          // Use Response object instead of redirect
          return new Response(null, {
            status: 302,
            headers: {
              Location: currentUrl.toString()
            }
          });
        }
      }
    } else if (params.agent_id !== 'new') {
      const agentResponse = await API.get("v1/agents/retrieve", { id: params.agent_id });
      return {
        agent: agentResponse || null,
        newAgent: false
      };
    } else {
      return {
        agent: null,
        newAgent: true
      };
    }
  };
};