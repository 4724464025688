import "./AgentTradingCard.scss";
import { ExternalLink, MessageCircle } from "react-feather";
import { MouseEventHandler, useEffect, useRef } from "react";
import Button from "../button/Button";

interface CardLink {
  title: string;
  url: string;
}

interface AgentTradingCardProps {
  name: string;
  image?: string;
  role?: string;
  description?: string;
  isSpecial?: boolean;
  button?: CardLink;
  links?: CardLink[];
}

export function AgentTradingCard({
  name,
  image,
  role = 'AI',
  description,
  isSpecial = false,
  button,
  links
} : AgentTradingCardProps) {

  const cardRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
    const card = cardRef.current;
    if (!card) return;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const xPos = x - rect.width / 2;
    const yPos = y - rect.height / 2;
    
    // Calculate rotation based on mouse position
    const rotateY = (xPos / rect.width) * -10;
    const rotateX = (yPos / rect.height) * 10;
    
    card.style.setProperty('--rotateY', `${rotateY}deg`);
    card.style.setProperty('--rotateX', `${rotateX}deg`);
    
    // Calculate normalized Y position for gradient (0 = top, 1 = bottom)
    const normalizedY = Math.min(
      Math.max(
        (y / rect.height) * 2 - 0.5,  // bound between -0.5 and 1.5
        0
      ),
      1
    );
    // Set the gradient position to follow the mouse cursor
    // The brightest part (tertiary) should be where the mouse is
    card.style.setProperty('--gradient-pos', `${normalizedY * 100}%`);
  };
  
  const handleMouseLeave: MouseEventHandler<HTMLDivElement> = () => {
    const card = cardRef.current;
    if (!card) return;
    // Reset rotations when mouse leaves
    card.style.setProperty('--rotateY', '0deg');
    card.style.setProperty('--rotateX', '0deg');
    
    // Set gradient highlight to the TOP by default (when no mouse over the card)
    card.style.setProperty('--gradient-pos', '0%');
  };
  
  useEffect(() => {
    const card = cardRef.current;
    if (card) {
      card.style.setProperty('--gradient-pos', '0%');
    }
    const canvas = canvasRef.current;
    let canRender = false;
    const baseRotationSeconds = 300_000;
    const seed1 = Math.random() * baseRotationSeconds;
    const seed2 = Math.random() * baseRotationSeconds;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      canvas.width = canvas.parentElement?.clientWidth || 0;
      canvas.height = canvas.parentElement?.clientHeight || 0;
      canRender = true;
      const start = Date.now();
      const renderLoop = () => {
        if (!canRender || !ctx) return;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // Want this to be a radial gradient from the center with a light purple color 
        const gradient = ctx.createRadialGradient(canvas.width / 2, canvas.height / 2, 0, canvas.width / 2, canvas.height / 2, canvas.width / 2);
        gradient.addColorStop(0, 'rgba(255, 128, 192, 1)');
        gradient.addColorStop(1, 'rgba(128, 64, 96, 0)');
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const clearTriangles = (count: number = 8, degrees: number = 1, rotation: number = 0) => {
          ctx.save();
          ctx.fillStyle = 'rgba(0, 0, 0, 1)';
          ctx.globalCompositeOperation = 'destination-out';
          const iterationAngle = 2 * Math.PI / count;
          const drawAngle = Math.min(iterationAngle, degrees * Math.PI / 180);
          for (let i = rotation; i < rotation + (2 * Math.PI); i += iterationAngle) {
            const startX = canvas.width / 2;
            const startY = canvas.height / 2;
            const endX1 = startX + Math.cos(i) * canvas.width;
            const endY1 = startY + Math.sin(i) * canvas.height;
            const endX2 = startX + Math.cos(i + drawAngle) * canvas.width;
            const endY2 = startY + Math.sin(i + drawAngle) * canvas.height;
            ctx.beginPath();
            ctx.moveTo(startX, startY);
            ctx.lineTo(endX1, endY1);
            ctx.lineTo(endX2, endY2);
            ctx.closePath();
            ctx.fill();
          }
          ctx.restore();
        };
        const t = Date.now();
        clearTriangles( 5, 10,  (13 * (t - start + 3000 + seed1) / baseRotationSeconds) % 2 * Math.PI);
        clearTriangles( 3, 10, -(19 * (t - start + 5000 + seed2) / baseRotationSeconds) % 2 * Math.PI);
        window.requestAnimationFrame(renderLoop);
      };
      renderLoop();
    }
    return () => {
      canRender = false;
    };
  }, []);

  // allow-drag is hack so we can still drag atop it
  return (
    <div 
      data-component="AgentTradingCard"
      data-allow-mobile-drag-through="true"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      ref={cardRef}
    >
      <div className="canvas-background">
        <canvas ref={canvasRef}></canvas>
      </div>
      <div className="agent-card-container">
        <div className="agent-card-background"></div>
        <div className="agent-card-foreground">
          <div className="agent-card-type">{role}</div>
          <div className="agent-card-image">
            {image && (
              <img src={image} alt={name} />
            )}
          </div>
          <div className="agent-card-name">
            {name}
          </div>
          {description && (
            <div className="agent-card-description">
              {description}
            </div>
          )}
          {links && links.length > 0 && (
            <div className="agent-card-links">
              {links.map((link, index) => (
                <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.title}
                  <ExternalLink className="icon" />
                </a>
              ))}
            </div>
          )}
          {button && (
            <div className="agent-card-actions">
              <Button
                size={isSpecial ? 'medium' : 'small'}
                color={'blue'}
                icon={MessageCircle}
                label={button.title}
                href={button.url}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}