import { useState, useRef, useEffect } from "react";
import { X } from "react-feather";
import Button from "../button/Button";
import "./AlertModal.scss";
import Textbox from "../textbox/Textbox";

export function AlertModal(
  {
    message = "Continue?",
    color = "red",
    type = "confirm",
    autoFocus = false,
    confirmLabel = "Okay",
    onClose = () => {},
    onConfirm = void 0,
    onCancel = void 0
  } : {
    message?: string
    color?: "red" | "green",
    type?: "confirm" | "text"
    autoFocus?: boolean,
    confirmLabel?: string,
    onClose?: () => void,
    onConfirm?: (complete: Function, value?: any) => void,
    onCancel?: () => void
  }
) {

  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const okayButtonRef = useRef<HTMLButtonElement>(null);
  const complete = () => setIsLoading(false);

  const handleBackgroundClick = (e: any) => {
    if (!isLoading) {
      onCancel && onCancel();
      onClose();
    }
  };

  const handleModalClick = (e: any) => {
    e.stopPropagation();
  };

  const handleCancelClick = () => {
    if (!isLoading) {
      onCancel && onCancel();
      onClose();
    }
  };

  const handleConfirmClick = () => {
    if (!isLoading) {
      setIsLoading(true);
      onConfirm?.(complete, type === "text" ? value : void 0);
    }
  };

  return (
    <div data-component="AlertModal" onMouseDown={handleBackgroundClick}>
      <div className="modal" onMouseDown={handleModalClick}>
        <div className="message">
          {message}
        </div>
        {type === "text" && (
          <Textbox
            autoFocus={autoFocus}
            onChange={(value) => setValue(value)}
          />
        )}
        <div className="actions">
          {onCancel && (
            <Button
              icon={X}
              label="Cancel"
              size="small"
              disabled={isLoading}
              onClick={handleCancelClick}
              autoFocus={type !== 'text' || !autoFocus}
            />
          )}
          {onConfirm && (
            <Button
              ref={okayButtonRef}
              label={confirmLabel}
              size="small"
              color={color}
              loading={isLoading}
              disabled={type === "text" && !value.trim()}
              onClick={handleConfirmClick}
              autoFocus={type !== 'text' && (!onCancel && !autoFocus)}
            />
          )}
        </div>
      </div>
    </div>
  );

}