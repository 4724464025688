import { Icon, Loader } from "react-feather";

import "./ContentButton.scss";

export function ContentButtonGroup({
  shake = false,
  children
}: {
  shake?: boolean;
  children: React.ReactNode;
}) {
  return <div data-component="ContentButtonGroup" className={shake ? 'shake' : ''}>{children}</div>;
}

export function ContentButton({
  icon,
  endIcon,
  loading = false,
  disabled = false,
  darken = false,
  size = 'default',
  styling = 'default',
  color = 'default',
  iconStyling = 'default',
  shake = false,
  onClick,
  children
}: {
  icon?: Icon;
  endIcon?: Icon;
  loading?: boolean;
  disabled?: boolean;
  darken?: boolean;
  size?: 'default' | 'small';
  styling?: 'default' | 'chat';
  color?: 'default' | 'orange' | 'green' | 'blue' | 'purple' | 'red' | 'grey';
  iconStyling?: 'default' | 'fill' | 'fill-shrink' | 'off';
  shake?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  children?: React.ReactNode;
}) {

  const StartIcon = icon;
  const EndIcon = endIcon;
  const content = children || (!icon && !endIcon) ? (children || 'Submit') : '';
  
  return (
    <div
      data-component="ContentButton"
      onMouseDown={(loading || disabled) ? undefined : onClick}
      className={shake ? 'shake' : ''}
      data-size={size}
      data-loading={loading}
      data-icon-only={!content}
      data-darken={darken}
      data-styling={styling}
      data-icon-styling={iconStyling}
      data-color={color}
      data-disabled={disabled}
    >
      {loading && !EndIcon && <Loader className={`icon start-icon loading-spin ${content ? 'has-content' : ''}`} />}
      {!loading && StartIcon && <span className="icon-container"><StartIcon className={`icon ${content ? 'has-content' : ''}`} /></span>}
      {content && <span className="button-content">{content}</span>}
      {loading && EndIcon && <Loader className={`icon end-icon loading-spin ${content ? 'has-content' : ''}`} />}
      {!loading && EndIcon && <span className="icon-container"><EndIcon className={`icon ${content ? 'has-content' : ''}`} /></span>}
    </div>
  );

}
