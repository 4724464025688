import { useState } from "react";
import { useLoaderData, Link } from "react-router-dom";
import { PackageSchema } from "../../../utils/types";

import "./DashboardPackagesPage.scss";
import { InternalLink } from "../../../components/internal-link/InternalLink";
import { Package } from "react-feather";
import { formatRecent } from "../../../utils/time";
import { ConstructionWarning } from "../../../components/warning/ConstructionWarning";
import PackageCard from "../../packages/PackageCard";

export default function DashboardPackagesPage() {
  const { packages: initialPackages } = useLoaderData() as {
    packages: Array<PackageSchema>
  };

  const [packages, setPackages] = useState<Array<PackageSchema>>(initialPackages);

  return (
    <div data-component="DashboardPackagesPage">
      <div className="content-container">
        <div className="content-description">
          Create custom functions to extend the capabilities of your agents.
          <br />
          You can also browse our available
          &nbsp;<InternalLink to="/packages">public packages</InternalLink>,
          contributed by the community.
        </div>
        <ConstructionWarning />
        {packages.length === 0 && (
          <div className="empty-state">You have not created any packages yet.</div>
        )}
        {packages.length > 0 && (
          <div className="item-group">
            {packages.map((pkg, i) => {
              return (
                <PackageCard key={pkg.display_name} pkg={pkg} />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
