import { Link, useSearchParams } from "react-router-dom";
import { useMobileScreenView } from "../../utils/hooks";

export function InternalLink ({
  to,
  preserveMenu = false,
  preserveSearchParams = [],
  children,
  ...props
} : {
  to: string,
  preserveMenu?: boolean,
  preserveSearchParams?: string[],
  children: React.ReactNode,
  [key: string]: any
}) {
  const [ searchParams ] = useSearchParams();
  const isMobileScreenView = useMobileScreenView();
  const isMenuOpen = searchParams.has('menu');
  let url: URL;
  if (!to.match(/^(https?:\/\/|mailto:|tel:)/)) {
    url = new URL(to, window.location.origin);
  } else {
    url = new URL(to);
  }
  if (
    isMenuOpen &&
    (preserveMenu || !isMobileScreenView)
  ) {
    url.searchParams.set('menu', 't');
  }
  preserveSearchParams.forEach(param => {
    const value = searchParams.get(param);
    if (value) {
      url.searchParams.set(param, value);
    } else {
      url.searchParams.delete(param);
    }
  });
  return (
    <Link to={url.toString().replace(window.location.origin, '')} {...props}>{children}</Link>
  );
}