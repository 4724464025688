export const isMac = function isMac() {
  // Use userAgentData if available (modern browsers)
  if ('userAgentData' in navigator && (navigator.userAgentData as any)?.platform) {
    return ['macOS', 'iOS'].includes((navigator.userAgentData as any).platform);
  }
  // Fallback to userAgent string
  return /Mac|iPhone|iPod|iPad/i.test(navigator.userAgent);
};

export const isWindows = function isWindows() {
  // Use userAgentData if available (modern browsers)
  if ('userAgentData' in navigator && (navigator.userAgentData as any)?.platform) {
    return (navigator.userAgentData as any).platform === 'Windows';
  }
  // Fallback to userAgent string
  return /Win/i.test(navigator.userAgent);
};

export const isLinux = function isLinux() {
  // Use userAgentData if available (modern browsers)
  if ('userAgentData' in navigator && (navigator.userAgentData as any)?.platform) {
    return (navigator.userAgentData as any).platform === 'Linux';
  }
  // Fallback to userAgent string
  return /Linux|X11/i.test(navigator.userAgent);
};