import { useRef, useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Icon, Menu, X, ChevronDown } from "react-feather";
import { createPortal } from "react-dom";

import "./TabList.scss";
import { useAuth } from "../../context/AuthContext";
import { useMobileScreenView } from "../../utils/hooks";
import { InternalLink } from "../internal-link/InternalLink";

export interface TabItem {
  to: string;
  label?: string;
  value?: string;
  image?: string;
  icon?: Icon;
  color?: 'green' | 'blue' | 'orange' | 'purple' | 'default'
};

interface Props {
  tabs: TabItem[];
  endTabs?: TabItem[];
  selectedTab?: string;
  shrinkTabsOnMobile?: boolean;
  mode?: "app" | "chat" | "page";
  preserveSearchParams?: string[];
}

export function TabList({ tabs, endTabs, selectedTab, mode = "app", preserveSearchParams = [], shrinkTabsOnMobile = false }: Props) {
  
  const location = useLocation();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { user } = useAuth();
  const isMobileScreenView = useMobileScreenView();
  const tabListRef = useRef<HTMLDivElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownHeaderRef = useRef<HTMLDivElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

  const forceFullscreen = searchParams.has('fullscreen');
  const showMenuButton = isMobileScreenView || forceFullscreen || (mode === "page" && !user);

  // Use if no page link matches in the tab list
  const title = document.title.split(' | ').slice(0, -1).join(' | ');

  const toggleMenu = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (newSearchParams.has('menu')) {
      newSearchParams.delete('menu');
    } else {
      newSearchParams.set('menu', 't');
    }
    setSearchParams(newSearchParams);
  };

  // Show the active tab only on mobile as a page header of sorts
  // Only if the view mode is a page and the user is not logged in
  const filteredTabs = tabs.filter((tab) => selectedTab ? selectedTab === tab.value : location.pathname === tab.to);
  const showTabs = (mode === "page" && isMobileScreenView)
    ? filteredTabs.length
      ? filteredTabs
      : [ { to: location.pathname, label: title } ]
    : tabs;

  const activeTab = filteredTabs[0] || showTabs[0];
  const ActiveIcon = activeTab?.icon;
  const shouldShowDropdown = isMobileScreenView;

  const formatTab = (tab: TabItem, index: number, isEnd = false) => {
    const Icon = tab.icon;
    const isActive = selectedTab ? selectedTab === tab.value : location.pathname === tab.to;
    return (
      <InternalLink
        key={tab.to}
        className={`tab color-${tab.color || 'default'} ${isActive ? 'active' : ''} ${isEnd ? 'tab-end' : ''} ${index === 0 ? 'tab-first' : ''}`}
        to={tab.to}
        preserveSearchParams={preserveSearchParams}
      >
        {Icon && <Icon />}
        {tab.image && <img src={tab.image} />}
        {tab.label && <div>{tab.label}</div>}
      </InternalLink>
    );
  };

  // This is a hack to keep the tab list fixed when the parent element is scrolled
  // It's necessary for iOS overscroll behavior
  useEffect(() => {
    let lastOffset = 0;
    const handleParentScroll = () => {
      const tabList = tabListRef.current;
      const parent = tabList?.parentElement;
      const offset = parent?.scrollTop || 0;
      const delta = offset - lastOffset;
      lastOffset = offset;
      if (tabList && parent) {
        if (
          offset < 0 ||
          (delta < 0 && offset < 100) // Prevent blip when scrolling up
        ) {
          tabList.setAttribute('data-overscroll-fix', 'true');
        } else {
          tabList.removeAttribute('data-overscroll-fix');
        }
      }
    }
    const tabList = tabListRef.current;
    tabList?.parentElement?.addEventListener('scroll', handleParentScroll);
    // Need these to prevent the tab list showing when menu nav is open
    // window.addEventListener('touchstart', handleParentScroll);
    // window.addEventListener('mousedown', handleParentScroll);
    return () => {
      tabList?.parentElement?.removeEventListener('scroll', handleParentScroll);
      // window.removeEventListener('touchstart', handleParentScroll);
      // window.removeEventListener('mousedown', handleParentScroll);
    }
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownOpen && 
          dropdownHeaderRef.current && 
          !dropdownHeaderRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  useEffect(() => {
    setDropdownOpen(false);
  }, [location.pathname]);

  // Update dropdown position when it's opened
  useEffect(() => {
    if (dropdownOpen && dropdownHeaderRef.current) {
      const rect = dropdownHeaderRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom,
        left: rect.left,
        width: rect.width
      });
    }
  }, [dropdownOpen]);

  return (
    <>
      <div
        data-component="TabList"
        data-mode={mode}
        data-is-mobile={isMobileScreenView}
        ref={tabListRef}
      >
        <div className="expander" />
        <div className="container">
          {showMenuButton && (
            <div className={`tabs ${isMobileScreenView ? 'tabs-mobile' : ''}`}>
              <div className="tab" onClick={toggleMenu}>
                <Menu />
              </div>
            </div>
          )}
          <div className={`tabs main-tabs scrollable ${shrinkTabsOnMobile ? 'shrink-tabs' : ''}`}>
            {shouldShowDropdown && activeTab ? (
              <>
                <div
                  key={`${activeTab.to}-dropdown`}
                  className={`tab color-${activeTab.color || 'default'} active tab-first dropdown-header`}
                  ref={dropdownHeaderRef}
                  onClick={() => tabs.length > 1 && setDropdownOpen(!dropdownOpen)}
                >
                  {ActiveIcon && <ActiveIcon />}
                  {activeTab.image && <img src={activeTab.image} />}
                  {activeTab.label && <div>{activeTab.label}</div>}
                  {tabs.length > 1 && <ChevronDown />}
                </div>
                {dropdownOpen && createPortal(
                  <div 
                    className="tab-dropdown-content-portal"
                    style={{
                      position: 'fixed',
                      top: `${dropdownPosition.top}px`,
                      left: `${dropdownPosition.left}px`,
                      minWidth: `${dropdownPosition.width}px`,
                      zIndex: 1000
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    {tabs.map((tab, index) => formatTab(tab, index))}
                  </div>,
                  document.body
                )}
              </>
            ) : (
              showTabs.map((tab, index) => formatTab(tab, index))
            )}
            {/* {endTabs && <div className="spacer" />}
            {endTabs && endTabs.map((tab, index) => formatTab(tab, index, true))} */}
          </div>
          {endTabs && (
            <div className={`tabs end-tabs scrollable ${shrinkTabsOnMobile ? 'shrink-tabs' : ''}`}>
              <div className="spacer" />
              {endTabs.map((tab, index) => formatTab(tab, index, true))}
            </div>
          )}
          {mode === "page" && !user && (
            <div className="tabs right">
              <InternalLink className="tab login" to="/login">
                Login
              </InternalLink>
              <InternalLink className="tab signup" to="/signup">
                <div className="tab-border" />
                <span>Sign up</span>
              </InternalLink>
            </div>
          )}
        </div>
      </div>
      {/* This is a hack to keep the tab list fixed when the parent element is overscrolled */}
      <div data-component="TabListSpacer" />
    </>
  );
}