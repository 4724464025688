import { Link } from "react-router-dom";
import { PackageSchema } from "../../utils/types";
import { formatRecent } from "../../utils/time";
import { InternalLink } from "../../components/internal-link/InternalLink";
import "./PackageCard.scss"
import { Globe, Lock, Package } from "react-feather";

interface PackageCardProps {
  package: PackageSchema
}

export default function PackageCard({
  pkg
} : {
  pkg: PackageSchema
}) {
  return (
    <div data-component="PackageCard">
      <div className="package-card-icon">
        {(pkg.agent || pkg.is_private) && <Lock className="private" />}
        {!(pkg.agent || pkg.is_private) && <Package />}
      </div>
      <div className="package-card-details">
        <div className="package-card-name">
          <InternalLink to={`/packages/${pkg.display_name}`}>
            {pkg.display_name}
          </InternalLink>
        </div>
        <div className="package-card-description">
          {pkg.agent && (
            <span>
              Code package for{" "}
              <InternalLink to={`/chat/${pkg.agent.unique_id}`}>{pkg.agent.agentConfigs?.[0]?.name}</InternalLink>
            </span>
          )}
          {!pkg.agent && pkg.description}
        </div>
      </div>
      <div className="spacer" />
      <div className="package-card-time">
        {formatRecent(pkg.created_at)}
      </div>
    </div>
  );
}