import { LoaderFunctionArgs } from "react-router-dom";
import API from "../../utils/api";
import { PackageSchema, DetailedPackageFileSchema } from "../../utils/types";

export interface PackageDetailsPageLoaderData {
  packageName: string,
  version: string,
  environment: string,
  pkg: PackageSchema,
  file: DetailedPackageFileSchema | null
};

export default function PackageDetailsPageLoader({ prefix } : { prefix?: string }) {
  return async ({ params } : LoaderFunctionArgs) => {
    let { org, name, environment, '*': filename } = params;
    const packageName = (prefix ? `${prefix}/` : ``) + `${org}/${name}`;
    const version = (environment || '').startsWith('v-')
      ? environment
      : null;
    environment = (environment || '').startsWith('v-')
      ? "production"
      : environment;
    const [ packagesResponse, fileResponse ] = await Promise.all([
      API.get(
        'v1/packages/details',
        {name: packageName}
      ),
      filename && API.get(
        'v1/packages/files/details',
        {
          name: packageName,
          environment,
          version,
          filename
        }
      )
    ]);
    const pkg = packagesResponse as PackageSchema;
    const file = (fileResponse || null) as DetailedPackageFileSchema | null;
    return {
      packageName,
      version,
      environment,
      pkg,
      file
    };
  };
};