import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import {
  AlertCircle,
  Settings,
  X
} from "react-feather";

import "./UserMenu.scss";
import { ActionButton } from "../button/ActionButton";
import { InternalLink } from "../internal-link/InternalLink";
export default function UserMenu({
  isActive = false
}: {
  isActive?: boolean;
}) {

  const { user, organization, wallet } = useAuth();

  return (
    <div data-component="UserMenu">
      {user && (
        <InternalLink className={`user-info`} to="/dashboard" preserveMenu={true}>
          <div className="user-nameplate">
            <div className="user-avatar">
              {organization.formatted_name.charAt(0).toUpperCase()}
            </div>
            <div className="user-name-display">
              <div className="user-name">
                <span>{organization.formatted_name}</span>
                {user?.discordUser?.verified_at ? <img className="icon" src="/icons/verified.png" /> : <AlertCircle className="icon" />}
              </div>
              <div className="user-plan">
                {user.currentPlan?.display_name} plan
              </div>
            </div>
          </div>
          <div className="spacer"></div>
          <ActionButton
            icon={Settings}
            flush={true}
            useOpacity={isActive}
          />
        </InternalLink>
      )}
      {!user && (
        <div className="user-menu-guest">
          <InternalLink to="/login">Login</InternalLink>
          <span>|</span>
          <InternalLink to="/signup">Sign up</InternalLink>
        </div>
      )}
    </div>
  );

}