import { useState, useEffect, useRef } from "react";
import { useParams, useLoaderData, Link } from "react-router-dom";

import { File } from "react-feather";
import {
  DetailedPackageDeploymentSchema,
  DetailedPackageFileSchema,
  PackageSchema
} from "../../utils/types";
import PackageCard from "./PackageCard";

import API, { ErrorWithDetails } from "../../utils/api";
import { PackageDetailsPageLoaderData } from "./PackageDetailsPageLoader";
import { InternalLink } from "../../components/internal-link/InternalLink";
import "./PackageDetailsPage.scss";
import { ConstructionWarning } from "../../components/warning/ConstructionWarning";
import { MiniEditor } from "../../components/editor/MiniEditor";

export default function PackageDetailsPage() {

  const {
    packageName,
    version,
    environment,
    pkg,
    file
  } = useLoaderData() as PackageDetailsPageLoaderData;

  const packageVersion = (
    pkg?.packageVersions.find(v => v.environment === environment && v.version === version) ||
    pkg?.packageVersions.find(v => v.environment === 'production' && v.is_latest) ||
    pkg?.packageVersions.find(v => v.environment === 'staging' && v.is_latest) ||
    pkg?.packageVersions.find(v => v.environment === 'development' && v.is_latest)
  );

  const envList = ['development', 'staging', 'production'];
  const activeEnv = packageVersion?.environment;
  const activeVer = (packageVersion?.version || packageVersion?.environment);

  const packageDeployment = (packageVersion?.packageDeployments[0] || null) as DetailedPackageDeploymentSchema | null;

  return (
    <div data-component="PackageDetailsPage">
      <ConstructionWarning center />
      {pkg && (
        <div className="packages-list">
          <PackageCard key={pkg.display_name} pkg={pkg} />
          {packageVersion && packageDeployment && (
            <div className="package-details">
              <div className="package-version">
                {envList.map(env => {
                  const curPackageVersion = pkg?.packageVersions
                    .find(v => v.environment === env && v.is_latest);
                  const isActive = packageVersion?.environment === env;
                  return (
                    <div
                      key={env}
                      className={`env-name ${isActive ? "selected" : ""}`}
                      >
                      {(
                        (curPackageVersion && !isActive)
                          ? (
                              <a
                                href={`/packages/${packageName}/${curPackageVersion?.version || curPackageVersion?.environment}`}
                                >
                                {env}
                              </a>
                            )
                          : isActive ? <span className="active">{env}</span> : <span className="inactive">{""}</span>
                      )}
                    </div>
                  );
                })}
              </div>
              {(file
                ? (
                    <>
                      <div className="package-filename">
                        {file.filename}
                      </div>
                      <div className="package-file-details">
                        <MiniEditor
                          language="javascript"
                          value={file.text_content || ''}
                          readonly={true}
                        />
                      </div>
                    </>
                  )
                : (
                    <>
                      <div className="package-files">
                        <table>
                          <tbody>
                            {!packageDeployment.packageFiles.length && (
                              <tr>
                                <td>
                                  Files are not visible for this type of package.
                                </td>
                              </tr>
                            )}
                            {
                              packageDeployment.packageFiles
                                .sort((fa, fb) => {
                                  const da = fa.filename.indexOf('/') > -1;
                                  const db = fb.filename.indexOf('/') > -1;
                                  return da === db
                                    ? fa.filename === fb.filename
                                      ? 0
                                      : fa.filename > fb.filename
                                        ? 1
                                        : -1
                                    : da > db
                                      ? -1
                                      : 1;
                                })
                                .map(file => {
                                return (
                                  <tr
                                    key={file.filename}
                                    >
                                    <td className="icon">
                                      <File />
                                    </td>
                                    <td>
                                      <InternalLink to={`/packages/${packageName}/${packageVersion.version || packageVersion.environment}/${file.filename}`}>
                                        {file.filename}
                                      </InternalLink>
                                    </td>
                                    {false && <td className="content-type">
                                      {file.content_type}
                                    </td>}
                                    <td className="size">
                                      {file.size} B
                                    </td>
                                  </tr>
                                );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );

};