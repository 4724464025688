import { useState, useEffect } from "react";

import { PackageSchema } from "../../utils/types";
import PackageCard from "./PackageCard";

import API, { ErrorWithDetails } from "../../utils/api";

import "./PackagesPage.scss";
import { ConstructionWarning } from "../../components/warning/ConstructionWarning";

export default function PackagesPage() {

  const [packages, setPackages] = useState<PackageSchema[]>([]);

  useEffect(() => {
    (async () => {
      const packagesResponse = await API.get(
        'v1/packages'
      );
      if (packagesResponse) {
        const packages = packagesResponse.data as Array<PackageSchema>;
        setPackages(packages);
      }
    })();
  }, []);

  return (
    <div data-component="PackagesPage">
      <div className="packages-title">
        <div className="heading">
          Package registry
        </div>
        <div className="subheading">
          Packages contain functions that can extend your assistant's capabilities.
        </div>
      </div>
      <ConstructionWarning center />
      <div className="packages-list">
        {packages.map(pkg => {
          return <PackageCard key={pkg.display_name} pkg={pkg} />
        })}
      </div>
    </div>
  );

};