import { useState, useEffect, useRef } from "react";
import { AgentSchema, PackageEndpointSchema } from "../../../../utils/types";
import { Plus, X, Search, Check, Save } from 'react-feather';
import Button from "../../../../components/button/Button";
import Textbox from "../../../../components/textbox/Textbox";
import API, { ErrorWithDetails } from "../../../../utils/api";
import { sleep } from "../../../../utils/sleep";
import { formatRecent } from "../../../../utils/time";

import "./AgentsPackages.scss";
import { InfoBox } from "../../../../components/info/InfoBox";
import { InternalLink } from "../../../../components/internal-link/InternalLink";

const serialize = (obj: any) => {
  obj = obj || {};
  if (obj && typeof obj === "object") {
    return Object.keys(obj).sort().map(key => {
      return [key, obj[key]].join('=');
    }).join('&');
  } else {
    return obj;
  }
};

interface Props {
  agent: AgentSchema;
  onAgentUpdated: (agent: AgentSchema) => void;
}

export function AgentsPackages({ agent, onAgentUpdated }: Props) {
  const [viewAgent, setViewAgent] = useState<AgentSchema>(agent);
  const [botPackages, setBotPackages] = useState<{[key: string]: string}>(agent.apiKeychain.keychainConfigs?.[0]?.enabled_packages || {});
  
  const [isSearching, setIsSearching] = useState(false);
  const [packageSearch, setPackageSearch] = useState("");
  const [packageEndpoints, setPackageEndpoints] = useState<Array<PackageEndpointSchema>>([]);
  const packageEndpointsRef = useRef(0);

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<ErrorWithDetails | null>(null);

  const fetchPackageEndpoints = async (delay = 300) => {
    setIsSearching(true);
    const id = ++packageEndpointsRef.current;
    await sleep(delay);
    if (id !== packageEndpointsRef.current) {
      return;
    }
    const endpointsResponse = await API.get(
      'v1/packages/endpoints/search',
      {query: packageSearch, limit: {count: 3, offset: 0}}
    );
    if (id !== packageEndpointsRef.current) {
      return;
    }
    const packageEndpoints = (endpointsResponse?.data || []) as Array<PackageEndpointSchema>;
    setIsSearching(false);
    setPackageEndpoints(packageEndpoints);
  };

  useEffect(() => {
    fetchPackageEndpoints(0);
  }, []);

  useEffect(() => {
    setViewAgent(agent);
    setBotPackages(agent.apiKeychain.keychainConfigs?.[0]?.enabled_packages || {});
  }, [agent]);

  const updateAgent = async () => {
    if (!viewAgent || isSaving) return;
    
    setIsSaving(true);
    try {
      const agentResult = await API.put(
        "v1/agents",
        {
          id: viewAgent.unique_id,
          packages: botPackages
        }
      );
      setViewAgent(agentResult as AgentSchema);
      setError(null);
      onAgentUpdated(agentResult as AgentSchema);
    } catch (e) {
      setError(e as ErrorWithDetails);
    }
    setIsSaving(false);
  };

  return (
    <div data-component="AgentsPackages">
      <div className="packages-content">
        <InfoBox>
          Packages extend the functionality of your agent by giving them access to tools.<br />
          Get started building your own packages at <InternalLink to="https://github.com/functme/cli" target="_blank">github.com/functme/cli</InternalLink>.
        </InfoBox>
        <div className="scrollable">
          <div className="form-row">
            {Object.keys(botPackages).map(name => {
              const version = botPackages[name];
              return (
                <Button
                  key={`${name}/${version}`}
                  label={`${name}/${version}`}
                  icon={X}
                  color="default"
                  size="small"
                  onClick={() => {
                    const pkgs = {...botPackages};
                    delete pkgs[name];
                    setBotPackages(pkgs);
                  }}
                />
              );
            })}
          </div>
          <div className="form-row">
            <Textbox
              heading="Search for packages..."
              endIcon={Search}
              value={packageSearch}
              loading={isSearching}
              onChange={search => {
                setPackageSearch(search);
                fetchPackageEndpoints();
              }}
            />
          </div>
          <div className="form-row">
            <div className="package-endpoints">
              {packageEndpoints.map(packageEndpoint => {
                const info = packageEndpoint.function_info;
                return (
                  <div key={packageEndpoint.function_info.name} className="package-endpoint">
                    <div className="info">
                      <div className="title">
                        <span className="highlight">{info.summary.title}</span>
                        <span className="time">{formatRecent(packageEndpoint.created_at)}</span>
                      </div>
                      <div className="summary">
                        <span className={`method method-${info.method}`}>{info.method}</span>
                        <span>
                          {info.summary.identifier}{info.summary.endpoint ? '/' + info.summary.endpoint : ''}
                        </span>
                      </div>
                    </div>
                    <div className="spacer" />
                    <div className="action">
                      <Button
                        label={botPackages[info.package.name] === info.package.version ? 'Added' : 'Add'}
                        icon={botPackages[info.package.name] === info.package.version ? Check : Plus}
                        color={botPackages[info.package.name] === info.package.version ? "green" : "default"}
                        size="small"
                        onClick={() => {
                          const pkgs = {...botPackages};
                          if (pkgs[info.package.name] === info.package.version) {
                            delete pkgs[info.package.name];
                          } else {
                            pkgs[info.package.name] = info.package.version;
                          }
                          setBotPackages(pkgs);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {error && (
            <div className="form-row margin-top error">
              {error.message}
            </div>
          )}
        </div>
        <div className="form-row margin-top fixed">
          <div className="spacer" />
          <Button
            icon={Save}
            color="orange"
            disabled={serialize(botPackages) === serialize(viewAgent?.apiKeychain.keychainConfigs?.[0]?.enabled_packages)}
            label="Save changes"
            loading={isSaving}
            onClick={updateAgent}
          />
        </div>
      </div>
    </div>
  );
}