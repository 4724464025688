import { Navigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";

export function RequireAuth({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" replace={true} />;
  }
  return <>
    {children}
  </>;
}