import { useState } from "react";
import API from "../../utils/api";
import { useAuth } from "../../context/AuthContext";
import { SubscriptionPlanSchema } from "../../utils/types";
import Button from "../../components/button/Button"
import { AlertModal } from "../../components/modals/AlertModal";

import {
  Check,
  Info,
  PlusCircle,
  ArrowUpCircle,
  X
} from "react-feather";

import "./PricingCard.scss";

interface SubscriptionCreationSchema {
  stripe_publishable_key: string,
  stripe_checkout_session_id: string,
  stripe_checkout_session_url: string
};

export function PricingCard ({
  plan,
  horizontal = false
} : {
  plan: SubscriptionPlanSchema,
  horizontal?: boolean
}) {

  const { user } = useAuth();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ showDowngradeAlert, setShowDowngradeAlert ] = useState(false);

  const isFeatured = plan.name === 'pro_plan';
  const isActive = user?.currentPlan?.name === plan.name;
  const price = user?.currentPlan?.price?.usd || 0;
  const planPrice = plan.price?.usd || 0;
  const isLoggedIn = !!user;
  const isUpgrade = planPrice > price;
  const isHighlighted = (
    (isActive && planPrice > 0) ||
    (isFeatured && (isUpgrade || !isLoggedIn))
  );

  const onSubscribe = async (planName: string) => {
    setIsLoading(true);
    try {
      const result = await API.post('payments/subscriptions', { planName }) as SubscriptionCreationSchema;
      if (result.stripe_checkout_session_url) {
        window.location = result.stripe_checkout_session_url as any;
      } else {
        await API.refreshUser();
        setIsLoading(false);
      }
    } catch (e) {
      const error = e as Error;
      setIsLoading(false);
      alert(error.message);
    }
  };

  const handlePlanAction = () => {
    if (!isLoggedIn) {
      return;
    }
    
    if (isUpgrade) {
      onSubscribe(plan.name);
    } else {
      setShowDowngradeAlert(true);
    }
  };

  return (
    <div data-component="PricingCard" className={(horizontal ? 'pricing-horizontal' : '') + (isHighlighted ? ' featured' : '')}>
      {showDowngradeAlert && (
        <AlertModal
          message="Are you sure you wish to downgrade? This will take effect immediately. If you are over your limits your agents will have reduced functionality."
          onClose={() => setShowDowngradeAlert(false)}
          onConfirm={async (complete) => {
            await onSubscribe(plan.name);
            complete();
            setShowDowngradeAlert(false);
          }}
          onCancel={() => setShowDowngradeAlert(false)}
        />
      )}
      <div className={isHighlighted ? "plan-border featured" : "plan-border"}></div>
      <div className="plan-content">
        <div className="plan-float">
          {isFeatured && isUpgrade && <div className="plan-featured">MOST POPULAR</div>}
          {isActive && <div className="plan-active">ACTIVE</div>}
        </div>
        <div className="plan-title">
          <div className="plan-name">{plan.display_name}</div>
          <div className="plan-price">
            {plan.price ? (
              <>
                <div>
                  <span className="currency">$</span>
                  <span className="amount">{(plan.price.usd / 100).toFixed(0)}</span>
                </div>
                <div className="period">per month</div>
              </>
            ) : (
              'Free'
            )}
          </div>
        </div>
        <div className="plan-body">
          <ul className="plan-features">
            {plan.lineItems
              .filter(item => item.settings.value !== null)
              .map(item => (
                <li key={item.name}>
                  <Check className="feature-icon check" />
                  <span className="feature-details">
                    <span className="feature-name">
                      {item.display_name}
                      <span className="feature-info">
                        <Info className="feature-icon info" />
                        <span className="feature-info-popup">{item.description}</span>
                      </span>
                    </span>
                    <span className="feature-value">{item.settings.display_value}</span>
                  </span>
                </li>
              ))
            }
          </ul>
          <div className="select-plan">
            {(
              isActive
                ? <Button disabled icon={Check} label="Currently active" />
                : !isLoggedIn
                  ? <Button
                      disabled={isLoading}
                      loading={isLoading}
                      color={isFeatured ? 'green' : 'default'}
                      icon={isFeatured ? PlusCircle : void 0}
                      label={plan.price ? `Subscribe to ${plan.display_name}` : "Sign up"}
                      onClick={isLoggedIn ? () => onSubscribe(plan.name) : void 0}
                      href={isLoggedIn ? void 0 : '/signup'}
                    />
                  : <Button
                      disabled={isLoading}
                      loading={isLoading}
                      color={isFeatured && isUpgrade ? 'green' : 'default'}
                      icon={(
                        isUpgrade
                          ? ArrowUpCircle
                          : void 0
                      )}
                      label={(
                        isUpgrade
                          ? `Upgrade to ${plan.display_name}`
                          : "Downgrade plan"
                      )}
                      onClick={handlePlanAction}
                      href={isLoggedIn ? void 0 : '/signup'}
                    />
            )}
          </div>
        </div>
      </div>
    </div>
  );

}