import { Link } from 'react-router-dom';
import {
  Icon,
  Loader,
  Command
} from 'react-feather';
import './Button.scss';
import { MouseEventHandler, forwardRef } from 'react';
import { InternalLink } from '../internal-link/InternalLink';

interface ButtonProps {
  label?: string;
  size?: "small" | "medium" | "large";
  color?: "default" | "special" | "blurple" | "green" | "red" | "orange" | "blue";
  href?: string;
  target?: "_self" | "_blank" | "_parent" | "_top" | string;
  icon?: Icon;
  endIcon?: Icon;
  disabled?: boolean;
  loading?: boolean;
  hotkeys?: string;
  autoFocus?: boolean;
  onClick?: MouseEventHandler;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  label = "Submit",
  size = "medium",
  color = "default",
  href = void 0,
  target = "_self",
  icon,
  endIcon,
  disabled = false,
  loading = false,
  hotkeys = '',
  autoFocus = false,
  onClick = () => {}
} : ButtonProps, ref) => {
  const StartIcon = icon;
  const EndIcon = endIcon;
  const hotkeysDisplay = hotkeys
    ? hotkeys.split('+')
      .map(key => key.toLowerCase())
      .map((key, i) => key === 'cmd' ? <Command key={i} /> : <span key={i}>{key[0].toUpperCase() + key.slice(1)}</span>)
    : '';
  return (
    <div data-component="Button" data-size={size} data-color={color}>
      {hotkeys && (
        <span className="hotkeys">{hotkeysDisplay}</span>
      )}
      <button 
        ref={ref}
        onClick={onClick} 
        disabled={loading || disabled}
        autoFocus={autoFocus}
      >
        <span className="special-border">
          <span className="overflow" />
        </span>
        {!!href &&
          <InternalLink to={href} target={target}>
            {(icon || (!endIcon && loading)) && (
              <span className="prefix">
                {loading ? <Loader className="loading-spin" /> : (StartIcon && <StartIcon />)}
              </span>
            )}
            <span className="label">{label}</span>
            {endIcon && (
              <span className="postfix">
                {loading && !icon ? <Loader className="loading-spin" /> : (EndIcon && <EndIcon />)}
              </span>
            )}
          </InternalLink>
        }
        {!href && 
          <span className="anchor">
            {(icon || (!endIcon && loading)) && (
              <span className="prefix">
                {loading ? <Loader className="loading-spin" /> : (StartIcon && <StartIcon />)}
              </span>
            )}
            <span className="label">{label}</span>
            {endIcon && (
              <span className="postfix">
                {loading && !icon ? <Loader className="loading-spin" /> : (EndIcon && <EndIcon />)}
              </span>
            )}
          </span>
        }
      </button>
    </div>
  );
});

export default Button;
