import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import API, { ErrorWithDetails } from "../../../../utils/api";

import { AlertCircle, CreditCard, DollarSign, RefreshCw, Save } from "react-feather";
import {ReactComponent as DiscordIcon} from "../../../../svg/discord-mark-white.svg";

import "./EditProfilePage.scss";
import Button from "../../../../components/button/Button";
import Textbox from "../../../../components/textbox/Textbox";
import { formatCredits } from "../../../../utils/format";
import { InternalLink } from "../../../../components/internal-link/InternalLink";
import { ContentButton } from "../../../../components/button/ContentButton";
import Toggle from "../../../../components/toggle/Toggle";
import { AlertModal } from "../../../../components/modals/AlertModal";
import { OrganizationAgreementSchema } from "../../../../utils/types";

interface OrganizationSettings {
  display_name: string,
  about_me: string,
  content_filter_settings?: "disabled" | "enabled" | null
};

export default function EditProfilePage() {

  const [isLoading, setIsLoading] = useState(false);
  const [showContentFilterModal, setShowContentFilterModal] = useState(false);
  const [contentFilterModalText, setContentFilterModalText] = useState('');
  const [contentFilterAgreement, setContentFilterAgreement] = useState<OrganizationAgreementSchema | null>(null);
  const { user, organization, wallet }= useAuth();

  const [ username, setUsername ] = useState(organization?.name || '');

  // Store original values to compare against for detecting changes
  const originalFields = {
    display_name: organization.display_name || '',
    about_me: organization.about_me || '',
    content_filter_settings: organization.content_filter_settings || "enabled"
  };

  const [ fields, setFields ] = useState<OrganizationSettings>(originalFields);

  // Function to check if any fields have changed
  const hasChanges = () => {
    return fields.display_name !== originalFields.display_name || 
           fields.about_me !== originalFields.about_me || 
           fields.content_filter_settings !== originalFields.content_filter_settings;
  };

  const handleDiscordLink = async () => {
    try {
      const result = await API.post('v1/discord_user_auths', { mode: 'link' });
      window.location = result?.url;
    } catch (e) {
      const error = e as ErrorWithDetails;
      alert(error.message);
    }
  };  

  const saveProfile = async () => {
    setIsLoading(true);
    try {
      // If trying to disable content filter, check for agreement
      if (fields.content_filter_settings === "disabled" && organization.content_filter_settings !== "disabled") {
        try {
          const agreementResponse = await API.post('v1/organization_agreements/', {
            organization: organization.name,
            label: 'disable_content_filter'
          });

          const organizationAgreement = agreementResponse as OrganizationAgreementSchema;
          
          if (!organizationAgreement.is_accepted) {
            setContentFilterAgreement(organizationAgreement);
            setShowContentFilterModal(true);
            setContentFilterModalText(organizationAgreement.content);
            return;
          }
        } catch (e) {
          const error = e as Error;
          alert(error.message);
          setIsLoading(false);
          return;
        }
      }
      
      await API.put(
        'v1/organizations',
        {
          name: organization.name,
          fields
        }
      );
    } catch (e) {
      const error = e as Error;
      alert(error.message);
    }
    await API.refreshUser();
    setIsLoading(false);
  };

  const acceptContentFilterAgreement = async (complete: Function) => {
    try {
      await API.put('v1/organization_agreements/', {
        organization: organization.name,
        label: 'disable_content_filter',
        is_accepted: true
      });
      
      // Now save the profile with content filter disabled
      await API.put(
        'v1/organizations',
        {
          name: organization.name,
          fields
        }
      );
      
      await API.refreshUser();
      complete();
      setShowContentFilterModal(false);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const error = e as Error;
      alert(error.message);
      complete();
    }
  };

  return (
    <div data-component="EditProfilePage">
      <div className="profile-edit">
        <div className="profile-header">
          <div className="profile-name">
            <span className="name">{organization.display_name || organization.name}</span>
            {user?.discordUser?.verified_at && (
              <>
                <span className="verified">
                  <img className="icon" src="/icons/verified.png" />
                </span>
              </>
            )}
            {!user?.discordUser?.verified_at && (
              <span className="verified">
                <AlertCircle className="icon-unverified"/>
                <span className="popover">Account is unverified. Please verify via Discord</span>
              </span>
            )}
          </div>
          <div className="profile-details">
            <span className="username">@{organization.name}</span>
            <span className="email">|</span>
            <span className="email">{organization.email}</span>
          </div>
        </div>
        <div className="profile-row">
          <div className="profile-title">
            <span>Discord verification</span>
            <span className="description">
              Verify your account on our community Discord server
            </span>
          </div>
          <div className="profile-entry">
            {user?.discordUser?.verified_at && (
              <Button 
                color="blurple" 
                icon={DiscordIcon} 
                label="Re-verify via Discord"
                onClick={handleDiscordLink} 
                />
            )}
            {!user?.discordUser?.verified_at && (
              <Button 
              color="blurple" 
              icon={DiscordIcon} 
              label="Verify via Discord"
              onClick={handleDiscordLink} 
              />
            )}
          </div>
        </div>
        <div className="profile-row">
          <div className="profile-title">
            <span>Display name</span>
            <span className="description">
              What would you prefer to be called?
            </span>
          </div>
          <div className="profile-entry">
            <Textbox
              placeholder={organization.name}
              value={fields.display_name || ''}
              onChange={value => {
                const f = { ...fields };
                f.display_name = value;
                setFields(f);
              }}
              />
          </div>
        </div>
        <div className="profile-row">
          <div className="profile-title">
            <span>About me</span>
            <span className="description">
              Tell us about yourself
            </span>
          </div>
          <div className="profile-entry">
            <Textbox
              value={fields.about_me || ''}
              type="multiline"
              lines={3}
              maxLines={10}
              onChange={value => {
                const f = { ...fields };
                f.about_me = value;
                setFields(f);
              }}
              />
          </div>
        </div>
        {('content_filter_settings' in organization) && (
          <div className="profile-row">
            <div className="profile-title">
              <span>Content filter</span>
              <span className="description">
                Maintains a friendly environment for all users.
              </span>
            </div>
            <div className="profile-entry">
              <Toggle
                size="small"
                type="inverse"
                value={fields.content_filter_settings !== "disabled"}
                onClick={() => {
                  const f = { ...fields };
                  f.content_filter_settings = f.content_filter_settings === "disabled" ? "enabled" : "disabled";
                  setFields(f);
                }}
              />
            </div>
          </div>
        )}
        <div className="profile-actions">
          <div className="spacer" />
          <Button
            label="Save changes"
            color="orange"
            icon={Save}
            loading={isLoading}
            disabled={!hasChanges()}
            onClick={() => saveProfile()}
            />
        </div>
      </div>
      
      {showContentFilterModal && contentFilterAgreement && (
        <AlertModal
          message={contentFilterModalText}
          type="confirm"
          confirmLabel="Accept"
          onClose={() => {
            setShowContentFilterModal(false);
            setIsLoading(false);
          }}
          onCancel={() => {
            const f = { ...fields };
            f.content_filter_settings = "enabled";
            setFields(f);
          }}
          onConfirm={acceptContentFilterAgreement}
        />
      )}
    </div>
  );
}