import { Link } from "react-router-dom"
import { AgentSchema } from "../../utils/types"
import { formatRecent } from "../../utils/time";
import { ReactComponent as DiscordLogo } from "../../svg/discord-mark-white.svg";

import "./AgentCard.scss";
import { InternalLink } from "../internal-link/InternalLink";

interface Props {
  agent: AgentSchema;
  selected?: boolean;
}

export default function AgentCard({ agent, selected }: Props) {

  return (
    <InternalLink
      to={`/chat/${agent.unique_id}`}
      data-component="AgentCard"
      className={selected ? 'selected' : ''}
      preserveSearchParams={['search']}
    >
      <div className="agent-card-container">
        <div className="avatar">
          <img src={agent.agentConfigs?.[0]?.profile_image_url || '/logo/funct-logo-avatar-circle.svg'} alt={agent.agentConfigs?.[0]?.name} />
        </div>
        <div className="conversation-info">
          <div className="title">
            <div className="name">
              {agent.agentConfigs?.[0]?.name}
            </div>
            <div className="timestamp">
              {formatRecent(agent.last_modified_at || '')}
            </div>
          </div>
          <div className="description">
            {/* Only grab the latest message */}
            {agent.conversations.length > 0 && agent.conversations.slice(agent.conversations.length - 1).map(conversation => {
              return (
                <div className="message" key={conversation.created_at}>{conversation.response}</div>
              );
            })}
            {agent.conversations.length === 0 && (
              <div className="message empty">No messages yet</div>
            )}
            {agent.discordAgents?.length > 0 && (
              <div className="servers">
                <DiscordLogo />
                <span>{agent.discordAgents.length} server{agent.discordAgents.length === 1 ? '' : 's'}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </InternalLink>
  );

}