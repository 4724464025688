import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import API from '../utils/api';
import { AgentSchema, OrganizationSchema, UserSchema, WalletSchema } from '../utils/types';
import { useSearchParams } from 'react-router-dom';

// Define the shape of the authentication context
interface AuthContextType {
  user: UserSchema | null;
  organization: OrganizationSchema,
  wallet: WalletSchema,
  agents: AgentSchema[],
  setUser: (user: UserSchema | null) => void;
}

// Create the authentication context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a provider component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Load cached agents from localStorage
  const cachedAgentsString = localStorage.getItem('auth::agents');
  let cachedAgents = [];
  if (API.user) {
    try {
      cachedAgents = JSON.parse(cachedAgentsString || '[]');
    } catch (e) {
      cachedAgents = [];
    }
  } else {
    localStorage.removeItem('auth::agents');
  }

  const [user, setUser] = useState<UserSchema | null>(API.user);
  const [agents, setAgents] = useState<AgentSchema[]>(cachedAgents);
  const organization = user?.memberships[0]?.organization || ({
    name: '?',
    email: '?'
  } as OrganizationSchema);
  const wallet = organization.wallet || ({microcredit_balance: 0} as WalletSchema);
  const fetchAgents = async (user: UserSchema) => {
    const url = new URL(window.location.href);
    const search = url.searchParams.get('search') || '';
    try {
      const response = await API.get(
        "v1/agents",
        {
          organization: user.memberships[0]?.organization?.name,
          search
        }
      );
      const agents = response?.data || [];
      if (!search) {
        localStorage.setItem('auth::agents', JSON.stringify(agents));
      }
      setAgents(agents);
    } catch (e) {
      localStorage.removeItem('auth::agents');
      setAgents([]);
    }
  };
  useEffect(() => {
    API.setUserCallback((user) => {
      setUser(user);
      user && fetchAgents(user);
    });
    if (API.user) {
      fetchAgents(API.user);
    }
  }, []);
  return (
    <AuthContext.Provider value={{ user, organization, wallet, agents, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// Create a custom hook to use the authentication context
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};