import "./Footer.scss";
import { InternalLink } from "../internal-link/InternalLink";

export default function Footer() {
  return (
    <div data-component="Footer">
      <div className="footer-column">
        <div className="footer-title">Navigation</div>
        <InternalLink to="/home">Home</InternalLink>
        <InternalLink to="/packages">Package registry</InternalLink>
        <InternalLink to="/pricing">Pricing</InternalLink>
        <InternalLink to="/about">About us</InternalLink>
      </div>
      <div className="footer-column">
        <div className="footer-title">Social</div>
        <InternalLink to="https://discord.gg/funct">Discord</InternalLink>
        <InternalLink to="https://github.com/functme">GitHub</InternalLink>
        <InternalLink to="https://x.com/functme">X / @functme</InternalLink>
      </div>
      <div className="footer-column">
        <div className="footer-title">Legal</div>
        <InternalLink to="/privacy-policy">Privacy policy</InternalLink>
        <InternalLink to="/terms-of-service">Terms of service</InternalLink>
      </div>
      <div className="spacer" />
      <div className="footer-column footer-right">
        <span className="copyright">
          <img src="/logo/funct-logo.svg" />
          <span>
            &copy; 2025 Funct, Inc.<br />
            Made in San Francisco
          </span>
        </span>
      </div>
    </div>
  )
};