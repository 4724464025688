import { AgentSchema, DownloadedPackage, PackageSchema } from "../../../../utils/types";
import CodeEditor from "../../../../components/editor/CodeEditor";
import Textbox from "../../../../components/textbox/Textbox";
import { Loader, Play, PlayCircle, Plus, Save, X } from "react-feather";
import Button from "../../../../components/button/Button";
import { ContentButton } from "../../../../components/button/ContentButton";
import { useMobileScreenView } from "../../../../utils/hooks";
import API, { atou, ErrorWithDetails, utoa } from "../../../../utils/api";
import { useState, useEffect, useRef } from "react";
import { StreamEvent, ChatMessage, CodeChat } from "./CodeChat";
import { isMac } from "../../../../utils/os";

import "./AgentsCode.scss";

interface Props {
  agent: AgentSchema;
  onAgentUpdated: (agent: AgentSchema) => void;
}

const defaultPackageBase64Files = {
  'functions/hello.js': window.btoa(`/**
 * Responds with a simple greeting
 * @param {string} name Your name
 * @returns {string} greeting
 */
export default async function (name = 'world', context) {
  return \`Hello \${name}, this is your first package!\`;
}`)
};

const EMPTY_PACKAGE: DownloadedPackage = {
  'functions/hello.js': {
    value: {
      _base64: defaultPackageBase64Files['functions/hello.js']
    },
    type: 'text/javascript'
  }
};

// Cache to store downloaded package files
type PackageFilesCache = {
  [codeSha256: string]: DownloadedPackage;
};
type SaveError = {
  message: string;
};
const SECRET_KEYS_CACHE: {[key: string]: string} = {};
const SYSTEM_FILES_CACHE: PackageFilesCache = {};
const SYSTEM_FILES_DOWNLOADING: {[key: string]: boolean} = {};

export function AgentsCode({ agent, onAgentUpdated }: Props) {

  const isMobileScreen = useMobileScreenView();
  const pkg = agent.package as PackageSchema | undefined;
  const pkgRef = useRef<PackageSchema | undefined>(pkg);
  const packageDeployment = pkg?.packageVersions?.[0]?.packageDeployments?.[0];
  const packageDeploymentError = packageDeployment?.error_json as SaveError | null;
  const [packageSaveError, setPackageSaveError] = useState<{ message: string } | null>(packageDeploymentError);
  const [isDownloadingFiles, setIsDownloadingFiles] = useState(true);
  const [isSavingFiles, setIsSavingFiles] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isDebugging, setIsDebugging] = useState(!isMobileScreen);
  const [files, setFiles] = useState<DownloadedPackage>({});

  const [messages, setMessages] = useState<ChatMessage[]>([
    {
      role: 'assistant',
      status: 'success',
      avatar: '/images/avatars/gem.svg',
      username: 'Gem',
      unique_id: '',
      created_at: new Date().toISOString(),
      events: [],
      content: {
        text:  [
          `Your code package is an [Instant API](https://github.com/instant-dev/api) project.\n`,
          `- Functions exported from the \`functions/\` directory are exposed as API endpoints\n`,
          `- API endpoints are added to your agent as tools\n`,
          `- JSDoc comments above the exports are used to generate the API documentation\n`,
          `- Input, return types are **validated at runtime** based on JSDoc comments\n`,
          `- \`export default\` will handle all HTTP request methods\n`,
          `- \`export async function GET\` will exclusively handle GET requests\n`,
          `- \`POST\`, \`PUT\`, \`DELETE\` can be handled in this way as well\n`,
          `- \`context\` parameter is optional and contains useful runtime information\n`,
          `- NPM dependencies will install automatically upon import\n`,
          `\n`,
          `Click **[Post]** or press`,
          isMac() ? `**[cmd]+[enter]**` : `**[ctrl]+[enter]**`,
          `to run your code, I'll show you the results here.`,
        ].join(' ')
      }
    }
  ]);

  const saveQueue = useRef<DownloadedPackage[]>([]);

  useEffect(() => {
    if (!packageDeployment) {
      setFiles(EMPTY_PACKAGE);
      setIsDownloadingFiles(false);
      return;
    }
    const codeSha256 = packageDeployment.code_sha256 || 'none';
    // Check if we already have this package in cache
    if (SYSTEM_FILES_CACHE[codeSha256]) {
      console.log(`Using cached package files for ${codeSha256}`);
      setFiles(SYSTEM_FILES_CACHE[codeSha256]);
      setIsDownloadingFiles(false);
      return;
    } else if (SYSTEM_FILES_DOWNLOADING[codeSha256]) {
      console.log(`Awaiting package download for ${codeSha256}`);
      return;
    }
    // Download the package files
    const downloadFiles = async () => {
      SYSTEM_FILES_DOWNLOADING[codeSha256] = true;
      setIsDownloadingFiles(true);
      try {
        const result = await API.get('v1/packages/download', {
          agent_id: agent.unique_id,
          environment: 'development',
          format: 'buffer'
        });
        // Clear previous cache to save memory, we only keep the most recent
        for (const key in SYSTEM_FILES_CACHE) {
          delete SYSTEM_FILES_DOWNLOADING[key];
          delete SYSTEM_FILES_CACHE[key];
        }
        SYSTEM_FILES_CACHE[codeSha256] = result as DownloadedPackage;
        setIsDownloadingFiles(false);
        setFiles(SYSTEM_FILES_CACHE[codeSha256]);
      } catch (error) {
        console.error('Failed to download package files:', error);
        setIsDownloadingFiles(false);
      }
    };
    downloadFiles();
  }, [packageDeployment?.code_sha256]);

  useEffect(() => {
    setPackageSaveError(packageDeploymentError);
  }, [packageDeploymentError]);

  const savePackage = async (files: DownloadedPackage) => {
    saveQueue.current.push(files);
    if (saveQueue.current.length === 1) {
      setIsSavingFiles(true);
      try {
        let result;
        do {
          const saveFiles = saveQueue.current[saveQueue.current.length - 1];
          const base64Files: {[key: string]: string} = {};
          for (const pathname in saveFiles) {
            base64Files[pathname] = saveFiles[pathname].value._base64;
          }
          result = await API.post(
            'v1/packages',
            {
              agent_id: agent.unique_id,
              environment: 'development',
              base64Files,
              publishError: true,
              autoInstall: true,
              timeout: 10, // FIXME: Configurable timeout
              _stream: {
                log: ({ event, data }: { event: string, data: any }) => {
                  // Push deploy logs to console
                  console.log(data);
                }
              }
            }
          );
          const codeSha256 = result?.packageVersions?.[0]?.packageDeployments?.[0]?.code_sha256 || '';
          if (!packageDeployment) {
            try {
              const result = await API.get('v1/packages/download', {
                agent_id: agent.unique_id,
                environment: 'development',
                format: 'buffer'
              });
              // Clear previous cache to save memory, we only keep the most recent
              for (const key in SYSTEM_FILES_CACHE) {
                delete SYSTEM_FILES_DOWNLOADING[key];
                delete SYSTEM_FILES_CACHE[key];
              }
              SYSTEM_FILES_CACHE[codeSha256] = result as DownloadedPackage;
              setFiles(SYSTEM_FILES_CACHE[codeSha256]);
            } catch (error) {
              console.error('Failed to download package files:', error);
            }
          } else {
            SYSTEM_FILES_CACHE[codeSha256] = files;
            setFiles(SYSTEM_FILES_CACHE[codeSha256]);
          }
          // If we're still on the same save files, we can clear the queue
          if (saveFiles === saveQueue.current[saveQueue.current.length - 1]) {
            saveQueue.current = [];
          }
        } while (saveQueue.current.length > 0);
        // Update pkgRef to the new package
        const pkg = pkgRef.current = result as PackageSchema;
        if (pkg) {
          const updatedAgent = { ...agent, package: result } as AgentSchema;
          onAgentUpdated(updatedAgent);
        }
        setIsSavingFiles(false);
      } catch (e) {
        console.error(e);
        setIsSavingFiles(false);
      }
      return true;
    }
  };

  const onSubmit = (value: string) => {
    console.log(value);
  };

  const runPackage = async ({
    requestMethod,
    activeFile,
    selections,
    payload
  }: { requestMethod: string, activeFile: any, selections: any[], payload: { [key: string]: any } }) => {
    if (!packageDeployment && saveQueue.current.length === 0) {
      savePackage(EMPTY_PACKAGE);
    }
    setIsRunning(true);
    setIsDebugging(true);
    const chatMessage: ChatMessage = {
      role: 'assistant',
      status: 'pending',
      avatar: '/images/avatars/gem.svg',
      username: 'Gem',
      created_at: new Date().toISOString(),
      unique_id: new Date().toISOString(),
      events: []
    };
    setMessages(messages => [...messages,chatMessage]);
    if (saveQueue.current.length > 0) {
      chatMessage.events?.push({
        event: '@info',
        id: null,
        data: 'Awaiting save...',
        created_at: new Date().toISOString(),
        elapsed: 0
      });
      setMessages(messages => [...messages]);
      while (saveQueue.current.length > 0) {
        await new Promise(r => setTimeout(() => r(1), 1));
      }
      chatMessage.events?.push({
        event: '@info',
        id: null,
        data: 'Save complete!',
        created_at: new Date().toISOString(),
        elapsed: 0
      });
      setMessages(messages => [...messages]);
    }
    // Fetch the current root URL from the package
    const usePkg = pkg || pkgRef.current;
    const root = usePkg?.version_urls['development'];
    if (root && activeFile?.pathname) {
      try {
        const filename = activeFile.pathname;
        let pathname;
        if (filename.startsWith('functions/')) {
          pathname = filename.replace('functions/', '');
          pathname = pathname.replace(/\.[^\/]*$/, '');
          const end = pathname.split('/').pop();
          if (end === 'index' || end === '__main__') {
            pathname = pathname.split('/').slice(0, -1).join('/');
          }
        } else if (filename.startsWith('www/')) {
          pathname = filename.replace('www/', '');
          const end = pathname.split('/').pop();
          if (end === 'index.htm' || end === 'index.html') {
            pathname = pathname.split('/').slice(0, -1).join('/');
          }
        }
        const url = `${root}/${pathname}`;
        chatMessage.events?.push({
          event: '@info',
          id: null,
          data: `${requestMethod} /${pathname}`,
          created_at: new Date().toISOString(),
          elapsed: 0
        });
        setMessages(messages => [...messages]);
        let secretKey = SECRET_KEYS_CACHE[agent.apiKeychain.unique_id];
        if (!secretKey) {
          const keychainResult = await API.get(
            'v1/api_keychains/details',
            {
              id: agent.apiKeychain.unique_id
            }
          );
          secretKey = SECRET_KEYS_CACHE[agent.apiKeychain.unique_id] = keychainResult?.secret_key || '';
        }
        const params: {[key: string]: any} = {
          ...payload,
          _debug: {
            '*': ({id, event, data}: {id?: string, event: string, data: any}) => {
              const [ logId, uuid, timestamp ] = id?.split('/') || [];
              if (event === '@begin') {
                chatMessage.unique_id = uuid;
              } else if (event === '@error') {
                chatMessage.status = 'error';
              } else if (event === '@response') {
                chatMessage.status = 'success';
              }
              const now = new Date().toISOString();
              const t = new Date(timestamp || now);
              const elapsed = chatMessage.events?.length
                ? t.valueOf() - new Date(chatMessage.events[0].created_at).valueOf()
                : 0;
              chatMessage.events?.push({
                id: id || null,
                event,
                data,
                created_at: timestamp || now,
                elapsed
              });
              setMessages(messages => [...messages]);
            }
          }
        };
        const result = await API.request(
          url,
          requestMethod,
          {
            requestHeaders: {Authorization: `Bearer ${secretKey}`},
            queryParams: requestMethod === 'GET' || requestMethod === 'DELETE' ? params : void 0,
            params: requestMethod === 'POST' || requestMethod === 'PUT' ? params : void 0
          }
        );
        // If we do not stream for any reason, succeed
        if (chatMessage.status === 'pending') {
          chatMessage.status = 'success';
        }
        chatMessage.events?.push({
          event: '@result',
          id: null,
          data: result.fileData || result.json,
          created_at: new Date().toISOString(),
          elapsed: 0
        });
        setMessages(messages => [...messages]);
      } catch (e) {
        console.error(e);
        const error = e as ErrorWithDetails;
        chatMessage.status = 'error';
        chatMessage.events?.push({
          event: '@error',
          id: null,
          data: error.rawError || error.message,
          created_at: new Date().toISOString(),
          elapsed: 0
        });
        setMessages(messages => [...messages]);
      }
    } else {
      chatMessage.status = 'error';
      chatMessage.events?.push({
        event: '@error',
        id: null,
        data: 'Invalid package or filename. This is probably a bug. Please report to support@funct.me.',
        created_at: new Date().toISOString(),
        elapsed: 0
      });
      setMessages(messages => [...messages]);
    }
    setIsRunning(false);
  }

  return (
    <div data-component="AgentsCode"
      data-is-mobile={isMobileScreen}
      data-is-debugging={isDebugging}>
      <div className="agent-code-container">
        <div className="agent-code-editor">
          <CodeEditor
            rootName={agent.agentConfigs?.[0]?.name}
            rootIcon={isSavingFiles ? 'refresh-cw' : packageDeploymentError ? 'alert-circle' : 'circle'}
            rootColor={isSavingFiles ? 'orange spin stroke' : packageDeploymentError ? 'red stroke' : !packageDeployment ? 'grey shrink' : 'green shrink'}
            files={files}
            loading={isDownloadingFiles}
            running={isRunning}
            onSave={savePackage}
            onRun={runPackage}
            onChat={() => setIsDebugging(isDebugging => !isDebugging)}
            chatVisible={isDebugging}
            agent={agent}
            onAgentUpdated={onAgentUpdated}
          />
          {packageSaveError && (
            <div className="package-save-error">
              <span>{packageSaveError?.message || 'Your package could not be saved properly. Please try again.'}</span>
              <ContentButton
                icon={X}
                onClick={() => setPackageSaveError(null)}
              />
            </div>
          )}
        </div>
        <div className="agent-code-chat">
          <div className="agent-code-chat-actions">
            <ContentButton
              icon={X}
              onClick={() => setIsDebugging(false)}
              darken={true}
            />
          </div>
          <div className="agent-code-chat-box">
            <CodeChat messages={messages} />
          </div>
        </div>
      </div>
      <div className="fixed"> 
        <Textbox
          type="chat"
          lines={1}
          maxLines={8}
          placeholder={`Message @${agent.agentConfigs?.[0]?.name}`}
          onSubmit={value => onSubmit(value)}
        />
      </div>
    </div>
  );
}