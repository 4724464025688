import {
  Icon,
  Menu
} from 'react-feather';

import './ActionButton.scss';

export function ActionButton({
  icon = Menu,
  image,
  flush = false,
  button = false,
  useOpacity = false,
  size = 'medium',
  onClick = () => {}
}: {
  icon?: Icon | null,
  image?: string,
  flush?: boolean,
  button?: boolean,
  useOpacity?: boolean,
  size?: 'medium' | 'large',
  onClick?: () => void
}) {

  const EndIcon = icon || Menu;

  return (
    <div data-component="ActionButton" data-flush={flush} data-use-opacity={useOpacity} data-size={size} data-button={button}>
      <div className="icon-container" onClick={onClick}>
        {image && (
          <img src={image} alt="Action Button" />
        )}
        {icon && <EndIcon className="icon" />}
      </div>
    </div>
  );

}