import "./AboutPage.scss";
import { AgentTradingCard } from "../components/cards/AgentTradingCard";

export default function AboutPage() {
  return (
    <div data-component="AboutPage">
      <div className="about-container">
        <div className="about-section">
          <h1>Funct, Inc.</h1>
          <p className="about-description">
            Custom and extensible AI for every person, organization, and business.
          </p>
        </div>

        <div
          className="team-section"
        >
          <AgentTradingCard
            name="Keith Horwood"
            image="/images/team/keith_headshot.jpg"
            role="Engineer"
            description={`Loves building. Proud Canadian.\nFormerly Autocode, OpenAI.`}
            button={{
              title: "Follow Keith",
              url: "https://x.com/keithwhor"
            }}
          />
          <AgentTradingCard
            name="Scott Gamble"
            image="/images/team/scott_headshot.jpg"
            role="Designer"
            description={`Game developer, designer, and engineer.\nMakes games you may have played.`}
            links={[
              {
                title: "Defense of the Dauntless",
                url: "https://store.steampowered.com/app/2923180/Defense_of_the_Dauntless/"
              },
              {
                title: "Quantum Joe",
                url: "https://store.steampowered.com/app/3272180/Quantum_Joe/"
              }
            ]}
            button={{
              title: "Follow Scott",
              url: "https://x.com/threesided"
            }}
          />
        </div>

        <div className="company-section">
          <p>
            Based in San Francisco, California.<br />
            Investors include SV Angel and Liquid 2 Ventures.
          </p>
          <p>
            Join us &mdash; <a href="mailto:careers@funct.me">careers@funct.me</a><br />
            Other inquiries &mdash; <a href="mailto:hello@funct.me">contact@funct.me</a>
          </p>
        </div>
      </div>
    </div>
  )
}