import { useMemo } from "react";
import Button from "../components/button/Button";
import { ArrowRight, Globe } from "react-feather";
import { ReactComponent as DiscordLogo } from "../svg/discord-mark-white.svg";
import { ChatBox } from "../components/chat/ChatBox";
import { ChatMessageEntry } from "../components/chat/ChatMessage";
import GemShard from "../components/flair/GemShard";

import "./IndexPage.scss";

export default function IndexPage() {

  const messages: ChatMessageEntry[] = [
    {
      app: false,
      message: 'Hello!',
      time: new Date(new Date().valueOf() - 5 * 60 * 1000).toISOString()
    },
    {
      app: true,
      message: 'Hey there! How can I help you today?',
      time: new Date(new Date().valueOf() - 5 * 60 * 1000 + 2000).toISOString()
    },
    {
      app: false,
      message: 'Can you help me with a problem I\'m having?',
      time: new Date(new Date().valueOf() - 4 * 60 * 1000).toISOString()
    },
    {
      app: true,
      message: 'I\'ll give it a shot! Tell me what you need from me. 😊',
      time: new Date(new Date().valueOf() - 4 * 60 * 1000 + 2000).toISOString()
    }
  ];

  return (
    <div data-component="IndexPage">
      <div className="hero-container">
        <div className="hero">
          <div className="hero-demo">
            <ChatBox
              botAvatar="/logo/funct-logo-avatar-circle.svg"
              welcomeMessage="Your own custom AI at your fingertips"
              messages={messages}
            />
          </div>
          <div className="hero-info">
            <div className="hero-image">
              <img src="/logo/funct-logo.svg" />
            </div>
            <div className="hero-title">
              ai + chat + functions
            </div>
            <div className="hero-desc">
              Build your own AI &mdash;
              a personal assistant,
              a customer support bot,
              or just a friend to talk to.
            </div>
            <div className="hero-cta">
              <Button
                label="Try free"
                color="special"
                endIcon={ArrowRight}
                size="large"
                href="/signup"
                />
              <span className="hero-cta-explainer">
                Available for
                <Globe /> Web and
                <DiscordLogo className="discord-logo"/> Discord
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}