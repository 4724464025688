import { AuthProvider } from './context/AuthContext';
import Router from './Router';
import { ToastProvider, useToast } from "./components/toast/ToastContainer";
import { toast } from "./services/toast";

import './App.scss';
import { useEffect } from 'react';

function App() {
  const { showToast } = useToast();
  
  useEffect(() => {
    toast.setToastCallback(({ icon, type = 'info', message, duration }) => {
      showToast({ icon, type, message, duration });
    });
  }, [showToast]);

  useEffect(() => {
    const root = document.getElementById('root');
    const resizeListener = () => {
      const activeElement = document?.activeElement as HTMLInputElement;
      if (activeElement && activeElement.getAttribute('data-type') === 'chat') {
        const height = window.visualViewport?.height || window.innerHeight;
        const width = window.visualViewport?.width || window.innerWidth;
        document.documentElement.style.height = height + 'px';
        document.documentElement.style.width = width + 'px';
        document.body.style.height = height + 'px';
        document.body.style.width = width + 'px';
        root && (root.style.height = height + 'px');
        root && (root.style.width = width + 'px');
      } else {
        document.documentElement.style.height = '';
        document.documentElement.style.width = '';
        document.body.style.height = '';
        document.body.style.width = '';
        root && (root.style.height = '');
        root && (root.style.width = '');
      }
    };
    window.visualViewport?.addEventListener('resize', resizeListener);
    window.addEventListener('resize', resizeListener);
    return () => {
      window.visualViewport?.removeEventListener('resize', resizeListener);
      window.removeEventListener('resize', resizeListener);
    }
  }, []);

  return (
    <div data-component="App">
      <AuthProvider>
        <Router />
      </AuthProvider>
    </div>
  );
}

function AppWithToast() {
  return (
    <ToastProvider>
      <App />
    </ToastProvider>
  );
}

export default AppWithToast;
