export const getTimeDelta = (s: string) => {
  const d = Math.floor(new Date(s).valueOf() / 1000);
  const now = Math.floor(new Date().valueOf() / 1000);
  const delta = Math.abs(now - d);
  const sign = Math.sign(now - d);
  return {
    delta,
    sign
  };
};

export const formatRecent = (s: string) => {
  const { delta, sign } = getTimeDelta(s);
  if (delta < 15) {
    return sign >= 0 ? `Just now` : `in ${delta}s`;
  } else if (delta < 60) {
    return sign > 0 ? `${delta}s ago` : `in ${delta}s`;
  } else if (delta < (60 * 60)) {
    const mm = Math.floor(delta / 60);
    return sign > 0 ? `${mm}m ago` : `in ${mm}m`;
  } else if (delta < (60 * 60 * 24)) {
    const hh = Math.floor(delta / (60 * 60));
    return sign > 0 ? `${hh}h ago` : `in ${hh}h`;
  } else if (delta < (60 * 60 * 24 * 7)) {
    const dd = Math.floor(delta / (60 * 60 * 24));
    return sign > 0 ? `${dd}d ago` : `in ${dd}d`;
  } else if (delta < (60 * 60 * 24 * 7 * 4)) {
    const ww = Math.floor(delta / (60 * 60 * 24 * 7));
    return sign > 0 ? `${ww}w ago` : `in ${ww}w`;
  } else if (delta < (60 * 60 * 24 * 31 * 12)) {
    const MM = Math.floor(delta / (60 * 60 * 24 * 31));
    return sign > 0 ? `${MM}mo ago` : `in ${MM}mo`;
  } else {
    const yy = Math.floor(delta / (60 * 60 * 24 * 365));
    return sign > 0 ? `${yy}y ago` : `in ${yy}y`;
  }
}

export const formatDate = (d: Date) => {
  const nth = (d: number) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  };
  let months = 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec'.split(' ');
  let month = months[d.getMonth()];
  let day = d.getDate();
  let h = d.getHours();
  let p = h >= 12 ? 'PM' : 'AM';
  if (h > 12) {
    h = h % 12;
  }
  if (h === 0) {
    h = 12;
  }
  let m = d.getMinutes() + '';
  if (m.length === 1) {
    m = `0${m}`;
  }
  return `${month} ${day}${nth(day)} at ${h}:${m} ${p}`;
};