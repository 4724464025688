import { useState } from "react";
import { ReactComponent as DiscordLogo } from "../../svg/discord-mark-white.svg";
import { DiscordLinkSchema, StandaloneDiscordLinkSchema } from "../../utils/types"
import API, { ErrorWithDetails } from "../../utils/api";
import { AlertModal } from "../modals/AlertModal";

import { Check, ExternalLink, MessageCircle, X } from "react-feather";

import "./DiscordLinkCard.scss";
import { InternalLink } from "../internal-link/InternalLink";
import { ContentButton } from "../button/ContentButton";

export default function DiscordLinkCard(
  {
    discordLink,
    standalone = false,
    onUnlink = (id: string) => {}
  } :
  {
    discordLink: DiscordLinkSchema | StandaloneDiscordLinkSchema,
    standalone?: boolean,
    onUnlink?: (id: string) => void;
  }
) {

  const [showAlert, setShowAlert] = useState(false);

  const metadata = discordLink.discord_guild_metadata;
  const avatarUrl = metadata.icon
    ? `https://cdn.discordapp.com/icons/${discordLink.discord_guild_id}/${metadata.icon}.png`
    : null;
  const bannerUrl = metadata.banner
    ? `https://cdn.discordapp.com/banners/${discordLink.discord_guild_id}/${metadata.banner}.png`
    : null;
  const initials = metadata.name
    .split(/\b/)
    .map((name: string) => name[0])
    .join('')
    .replaceAll(' ', '');
  const discordUrl = `https://discord.com/channels/${discordLink.discord_guild_id}`;
  const agent = 'discordAgent' in discordLink
    ? discordLink.discordAgent?.agent || null
    : null;

  const unlinkServer = async () => {
    const id = discordLink.unique_id;
    try {
      const unlinkResponse = await API.del('v1/discord_links', {id});
      if (unlinkResponse) {
        onUnlink(id);
      }
    } catch (e) {
      const error = e as ErrorWithDetails
      alert(error.message);
    }
  };

  return (
    <>
      {showAlert && (
        <AlertModal
          message="Unlink this Discord server?"
          onClose={() => setShowAlert(false)}
          onConfirm={async (complete) => {
            await unlinkServer();
            complete();
          }}
          onCancel={() => {}}
        />
      )}
      <div data-component="DiscordLinkCard">
        <div className="actions">
          {standalone && (
            <div className="linked">
              <Check /> Linked to <DiscordLogo /> Discord
            </div>
          )}
          {!standalone && (
            <ContentButton icon={X} onClick={() => setShowAlert(true)}>
              Unlink
            </ContentButton>
          )}
        </div>
        <div className="card-banner">
          {bannerUrl ? (
            <img src={bannerUrl} alt="Guild Banner" />
          ) : (
            <div className="banner-placeholder"></div>
          )}
        </div>
        <div className="card-content">
          <div className="card-avatar">
            {avatarUrl ? (
              <img src={avatarUrl} alt="Guild Avatar" />
            ) : (
              <div className="avatar-placeholder">{initials}</div>
            )}
          </div>
          <div className="card-title">
            <span>
              {discordLink.discord_guild_metadata.name}
            </span>
            <InternalLink to={discordUrl} target="_blank">
              <ExternalLink />
            </InternalLink>
          </div>
          {!standalone && (
            <div className="card-agent">
              <MessageCircle />
              {agent && (
                <>
                  <div className="card-agent-avatar">
                    {(
                      agent.agentConfigs[0].profile_image_url
                        ? <img src={agent.agentConfigs[0].profile_image_url} />
                        : <img src="/logo/funct-logo-avatar-circle.svg" />
                    )}
                  </div>
                  <div className="card-agent-name">
                    <InternalLink to={`/chat/${agent.unique_id}`}>
                      {agent.agentConfigs[0].name}
                    </InternalLink>
                  </div>
                </>
              )}
              {!agent && (
                <div className="no-agent">No agent assigned</div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );

}