import { useEffect, useRef, useState } from "react";
import { useNavigate, Link, useLoaderData, useParams, useLocation, useSearchParams, Navigate } from "react-router-dom";
import { AgentSchema } from "../../utils/types";
import { AlertModal } from "../../components/modals/AlertModal";
import { RequireAuth } from "../../RequireAuth";
import { useMobileScreenView } from "../../utils/hooks";

import {
  PlusCircle,
  CreditCard,
  LogOut,
  Package,
  Link2,
  MessageSquare,
  ExternalLink,
  FileText,
  Info,
  Menu,
  ChevronRight,
  MessageCircle,
  Home,
  X,
  Search,
  ShoppingBag,
  User,
  Cpu,
} from 'react-feather';
import { ReactComponent as DiscordLogo } from "../../svg/discord-mark-white.svg";
import { ReactComponent as GitHubLogo } from "../../svg/octicon-mark-github.svg";
import { ReactComponent as XLogo } from "../../svg/x-mark-original.svg";
import { useAuth } from "../../context/AuthContext";
import AgentCard from "../../components/cards/AgentCard";
import AgentsChat from "./agents-chat/AgentsChat";
import Button from "../../components/button/Button";
import API, { ErrorWithDetails } from "../../utils/api";
import { formatCredits } from "../../utils/format";

import "./DashboardPage.scss";
import UserMenu from "../../components/nav/UserMenu";
import Textbox from "../../components/textbox/Textbox";
import { ActionButton } from "../../components/button/ActionButton";
import { InternalLink } from "../../components/internal-link/InternalLink";
import { ContentButton } from "../../components/button/ContentButton";
import { NewAgentFlow } from "./agents-chat/NewAgentFlow";

export function DashboardPage({
  dashboardView = null,
  backgroundColor = 'default',
  children
} : {
  dashboardView?: 'user' | 'agents' | null,
  backgroundColor?: 'default' | 'dark',
  children?: React.ReactNode
}) {

  const { user, organization, agents: initialAgents } = useAuth();
  const { agent: initialAgent, newAgent } = useLoaderData() as {
    agent?: AgentSchema | null,
    newAgent?: boolean
  };

  const initialAgentsRef = useRef<AgentSchema[]>(initialAgents);

  const pageContentRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const dragPointRef = useRef<{
    x: number,
    y: number,
    startTime: number,
    initialWidth: number,
    maxWidth: number,
    delta: number,
    aborted: boolean,
    drag: boolean,
    menu: boolean
  } | null>(null);

  const isLoadedRef = useRef(false);

  const location = useLocation();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const searchTerm = searchParams.get('search') || '';
  const forceFullscreen = searchParams.has('fullscreen');
  // This is used to prevent the search from being run on initial load
  // We do this because useEffect runs twice in dev mode
  const lastSearch = useRef(searchTerm);

  const navigate = useNavigate();

  const isMobileScreenView = useMobileScreenView('menu');
  const isMenuOpen = (!!user && !isMobileScreenView && !forceFullscreen) || searchParams.has('menu');

  const [agent, setAgent] = useState<AgentSchema | null>(initialAgent || null);
  const [viewAgents, setViewAgents] = useState<Array<AgentSchema>>(initialAgents || []);
  const [isSearching, setIsSearching] = useState(false);
  const searchRequestRef = useRef<number>(0);

  const handleLogout = async (e: any) => {
    e.preventDefault();
    await API.logout();
    navigate('/');
  };

  const searchAgents = async (query: string) => {
    if (!organization) return;
    setIsSearching(true);
    const currentRequestId = ++searchRequestRef.current;
    await new Promise(resolve => setTimeout(resolve, 300));
    try {
      const params: Record<string, string> = { organization: organization.name };
      if (query) params.search = query;
      const response = await API.get("v1/agents", params);
      // Only update if this is the most recent request
      if (currentRequestId === searchRequestRef.current) {
        setViewAgents((response?.data || []) as Array<AgentSchema>);
        setIsSearching(false);
      }
    } catch (error) {
      if (currentRequestId === searchRequestRef.current) {
        console.error("Failed to search agents", error);
        setIsSearching(false);
      }
    }
  };

  useEffect(() => {
    // On initial load, refresh the user
    if (!isLoadedRef.current) {
      isLoadedRef.current = true;
      API.refreshUser();
    }
  }, []);

  useEffect(() => {
    setAgent(initialAgent || null);
    // Update the view agents to be the new initial agents
    const filteredAgents = initialAgents
      .filter(agent => viewAgents.find(a => a.unique_id === agent.unique_id));
    setViewAgents(filteredAgents);
  }, [initialAgent, initialAgents]);

  useEffect(() => {
    if (pageContentRef.current) {
      pageContentRef.current.scrollTo(0, 0);
    }
  }, [children]);

  useEffect(() => {
    // We do this because useEffect runs twice in dev mode
    if (
      lastSearch.current === searchTerm &&
      initialAgentsRef.current === initialAgents
    ) {
      return;
    } else if (!searchTerm) {
      lastSearch.current = searchTerm;
      setViewAgents(initialAgents || []);
    } else {
      lastSearch.current = searchTerm;
      searchAgents(searchTerm);
    }
    initialAgentsRef.current = initialAgents;
  }, [searchTerm, initialAgent, initialAgents]);

  const handleAgentUpdated = (updatedAgent: AgentSchema) => {
    // Just refresh the user
    // This will trigger initialAgents change
    API.refreshUser();
  };

  return (
    <div
      data-component="DashboardPage"
      data-background-color={backgroundColor}
      data-menu-open={isMenuOpen}
      data-is-mobile={isMobileScreenView}
      onTouchStart={(e) => {
        // Check if we might want to scroll an element horizontally first
        let target: HTMLElement = e.target as HTMLElement;
        while (target !== e.currentTarget && target !== null) {
          if (target.hasAttribute('data-allow-mobile-drag-through')) {
            break;
          } else if (
            (
              target.clientWidth < target.scrollWidth &&
              window.getComputedStyle(target).overflowX !== 'hidden'
            ) ||
            (
              (
                target.tagName === 'INPUT' ||
                target.tagName === 'TEXTAREA'
              ) &&
              !target.hasAttribute('disabled') &&
              !target.hasAttribute('readonly')
            )
          ) {
            return;
          }
          target = target.parentElement as HTMLElement;
        }
        // If we can't scroll, we can start the drag
        if (
          isMobileScreenView &&
          sidebarRef.current
        ) {
          const sidebar = sidebarRef.current;
          const sidebarContent = sidebar.childNodes[0] as HTMLDivElement;
          dragPointRef.current = {
            x: e.touches[0].clientX,
            y: e.touches[0].clientY,
            startTime: Date.now(),
            initialWidth: sidebar.offsetWidth,
            maxWidth: sidebarContent.offsetWidth || 300,
            delta: 0,
            aborted: false,
            drag: false,
            menu: searchParams.has('menu')
          };
        }
      }}
      onTouchMove={(e) => {
        if (dragPointRef.current && sidebarRef.current) {
          const sidebar = sidebarRef.current;
          const point = dragPointRef.current;
          const deltaX = e.touches[0].clientX - point.x;
          const deltaY = e.touches[0].clientY - point.y;
          point.delta = deltaX;
          if (!point.drag) {
            if (point.aborted || Math.abs(deltaY) > 10) {
              point.aborted = true;
            } else if (
              (searchParams.has('menu') && deltaX < -10) ||
              (!searchParams.has('menu') && deltaX > 10)
            ) {
              const activeElement = document.activeElement;
              if (
                activeElement &&
                activeElement.tagName !== 'BODY' &&
                (activeElement as HTMLInputElement).blur
              ) {
                (activeElement as HTMLInputElement).blur();
              }
              point.drag = true;
              sidebar.classList.add('dragging')
              sidebar.style.width = `${Math.min(Math.max(0, point.initialWidth + deltaX), point.maxWidth)}px`;
              if (!searchParams.has('menu')) {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set('menu', 't');
                setSearchParams(newSearchParams);
              }
            }
          } else {
            if (e.cancelable) {
              e.preventDefault();
            }
            sidebar.style.width = `${Math.min(Math.max(0, point.initialWidth + deltaX), point.maxWidth)}px`;
          }
        }
      }}
      onTouchEnd={(e) => {
        if (dragPointRef.current && sidebarRef.current) {
          const point = dragPointRef.current;
          if (point.aborted) {
            return;
          }
          if (point.drag && e.cancelable) {
            e.preventDefault();
          }
          if (point.delta < -50 && point.menu) {
            sidebarRef.current.style.width = '';
            sidebarRef.current.classList.remove('dragging');
            searchParams.delete('menu');
            setSearchParams(searchParams);
          } else if (point.delta > 50 && !point.menu) {
            sidebarRef.current.style.width = '';
            sidebarRef.current.classList.remove('dragging');
            searchParams.set('menu', 't');
            setSearchParams(searchParams);
          } else {
            sidebarRef.current.style.width = '';
            sidebarRef.current.classList.remove('dragging');
            if (!point.menu) {
              searchParams.delete('menu');
              setSearchParams(searchParams);
            }
          }
          dragPointRef.current = null;
        }
      }}
    >
      <div className="left-sidebar-container" ref={sidebarRef}>
        <div className="left-sidebar">
          <div className="sidebar-header">
            <InternalLink className={`sidebar-logo`} to="/home" preserveMenu={true}>
              <img src="/logo/funct-logo-wordmark-filled.svg" />
              <div className="spacer"></div>
              {dashboardView !== null && (
                <ActionButton
                  flush={true}
                  useOpacity={true}
                  icon={ChevronRight}
                />
              )}
            </InternalLink>
            {isMobileScreenView && (
              <div className="sidebar-close">
                <ActionButton
                  useOpacity={true}
                  icon={X}
                  onClick={() => {
                    searchParams.delete('menu');
                    setSearchParams(searchParams);
                  }}
                />
              </div>
            )}
          </div>
          <div className="sidebar-home-container" style={{ maxHeight: dashboardView === null ? '1500px' : '0px' }}>
            <div className="sidebar-menu">
              <ul className="menu">
                <li>
                  <InternalLink to="/home" className={location.pathname === '/home' ? 'active' : ''}>
                    <Home className="icon" />
                    <span>Home</span>
                  </InternalLink>
                </li>
                <li>
                  <InternalLink to="/packages" className={location.pathname === '/packages' ? 'active' : ''}>
                    <Package className="icon" />
                    <span>Package registry</span>
                  </InternalLink>
                </li>
                <li>
                  <InternalLink to="/pricing" className={location.pathname === '/pricing' ? 'active' : ''}>
                    <CreditCard className="icon" />
                    <span>Pricing</span>
                  </InternalLink>
                </li>
                <li>
                  <InternalLink to="/about" className={location.pathname === '/about' ? 'active' : ''}>
                    <Info className="icon" />
                    <span>About us</span>
                  </InternalLink>
                </li>
                <hr />
                <li>
                  <InternalLink to="/privacy-policy" className={location.pathname === '/privacy-policy' ? 'active' : ''}>
                    <FileText className="icon" />
                    <span>Privacy policy</span>
                  </InternalLink>
                </li>
                <li>
                  <InternalLink to="/terms-of-service" className={location.pathname === '/terms-of-service' ? 'active' : ''}>
                    <FileText className="icon" />
                    <span>Terms of service</span>
                  </InternalLink>
                </li>
                <hr />
                <li>
                  <a href="https://discord.gg/funct" target="_blank">
                    <DiscordLogo className="icon fill" />
                    <span>Discord</span>
                    <div className="spacer"></div>
                    <ExternalLink className="icon" />
                  </a>
                </li>
                <li>
                  <a href="https://github.com/functme" target="_blank">
                    <GitHubLogo className="icon fill" />
                    <span>GitHub</span>
                    <div className="spacer"></div>
                    <ExternalLink className="icon" />
                  </a>
                </li>
                <li>
                  <a href="https://x.com/functme" target="_blank">
                    <XLogo className="icon fill" />
                    <span>@functme</span>
                    <div className="spacer"></div>
                    <ExternalLink className="icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {user && (
            <>
              {dashboardView !== 'agents' && (
                <div className="sidebar-agents">
                  <InternalLink className={`sidebar-agents-title`} to="/chat" preserveMenu={true}>
                    <MessageCircle className="icon" />
                    <span>Agents</span>
                    <div className="spacer"></div>
                    <ActionButton
                      flush={true}
                      useOpacity={true}
                      icon={ChevronRight}
                    />
                  </InternalLink>
                </div>
              )}
              <div className="sidebar-agents-list-container" style={{ maxHeight: dashboardView === 'agents' ? '1500px' : '0px' }}>
                <div className="sidebar-search">
                  <Textbox
                    placeholder="Search agents..."
                    size="small"
                    startIcon={Search}
                    loading={isSearching}
                    value={searchParams.get('search') || ''}
                    onChange={(value) => {
                      if (!value) {
                        searchParams.delete('search');
                      } else {
                        searchParams.set('search', value);
                      }
                      setSearchParams(searchParams);
                    }}
                    onClear={() => {
                      searchParams.delete('search');
                      setSearchParams(searchParams);
                    }}
                  />
                </div>
                <div className="sidebar-agents-list">
                  {viewAgents.map((agentItem, i) => {
                    return <AgentCard
                      key={agentItem.unique_id}
                      agent={agentItem}
                      selected={agent?.unique_id === agentItem.unique_id}
                    />;
                  })}
                </div>
                <div className="sidebar-agents-actions">
                  <ContentButton
                    icon={PlusCircle}
                    onClick={() => navigate('/chat/new')}
                  >
                    Create a new agent
                  </ContentButton>
                </div>
              </div>
            </>
          )}
          <div className="sidebar-user">
            <UserMenu isActive={dashboardView === 'user' && location.pathname === '/dashboard'} />
          </div>
          {user && (
            <div className="sidebar-user-container" style={{ maxHeight: dashboardView === 'user' ? '1500px' : '0px' }}>
              <div className="sidebar-menu">
                <ul className="menu">
                  <li>
                    <InternalLink to="/dashboard" className={location.pathname === '/dashboard' ? 'active' : ''}>
                      <User className="icon" />
                      <span>Profile</span>
                    </InternalLink>
                  </li>
                  <li>
                    <InternalLink to="/dashboard/packages" className={location.pathname === '/dashboard/packages' ? 'active' : ''}>
                      <Package className="icon" />
                      <span>Packages</span>
                    </InternalLink>
                  </li>
                  <li>
                    <InternalLink to="/dashboard/connections" className={location.pathname === '/dashboard/connections' ? 'active' : ''}>
                      <Link2 className="icon" />
                      <span>Connections</span>
                    </InternalLink>
                  </li>
                  <hr />
                  <li>
                    <InternalLink to="/dashboard/billing" className={location.pathname === '/dashboard/billing' ? 'active' : ''}>
                      <ShoppingBag className="icon" />
                      <span>Billing and subscription</span>
                    </InternalLink>
                  </li>
                  <li>
                    <InternalLink to="/dashboard/billing/credits" className={location.pathname === '/dashboard/billing/credits' ? 'active' : ''}>
                      <Cpu className="icon" />
                      <span>Function credits</span>
                    </InternalLink>
                  </li>
                  <li>
                    <InternalLink to="/dashboard/billing/methods" className={location.pathname === '/dashboard/billing/methods' ? 'active' : ''}>
                      <CreditCard className="icon" />
                      <span>Payment methods</span>
                    </InternalLink>
                  </li>
                </ul>
                <div className="menu-spacer"></div>
                <ul className="menu">
                  <hr />
                  <li>
                    <InternalLink to="#" onClick={handleLogout}>
                      <LogOut className="icon" />
                      <span>Log out</span>
                    </InternalLink>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="dashboard-content"
        data-is-blurred={isMenuOpen && isMobileScreenView}
        onMouseDown={(e) => {
          if (searchParams.has('menu') && !forceFullscreen) {
            e.preventDefault();
            searchParams.delete('menu');
            setSearchParams(searchParams);
          }
        }}
        ref={pageContentRef}
      >
        {children}
        {!children && (
          <RequireAuth>
            {newAgent && !agent &&(
              <NewAgentFlow onAgentUpdated={handleAgentUpdated} />
            )}
            {agent && (
              <AgentsChat 
                agent={agent}
                onAgentUpdated={handleAgentUpdated}
                onAgentArchived={(id) => {
                  setViewAgents(agents => agents.filter(a => a.unique_id !== id));
                  setAgent(null);
                  navigate('/chat');
                }}
              />
            )}
          </RequireAuth>
        )}
      </div>
    </div>
  );

}

export default DashboardPage;