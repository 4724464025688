import { useEffect, useRef } from "react";
import { ContentButton } from "../button/ContentButton";
import { Copy, Icon } from 'react-feather';
import { toast } from "../../services/toast";

import "./MiniEditor.scss";
import "./EditorStyleOverride.scss";

export function MiniEditor({
  icon,
  label = '',
  copyable = false,
  value = '',
  type = 'default',
  language = 'javascript',
  showLines = true,
  readonly = false,
  maximized = false,
  errorPos = null,
  onRun,
  onSave,
  onChange
} : {
  icon?: Icon;
  label?: string;
  copyable?: boolean;
  value?: string | { value: string | { _base64: string }, type: string };
  type?: 'default' | 'error';
  language?: string;
  showLines?: boolean;
  readonly?: boolean;
  maximized?: boolean;
  errorPos?: { lineIndex: number, column: number } | null;
  onRun?: () => void;
  onSave?: () => void;
  onChange?: (value: string) => void;
}) {

  const editorRef = useRef<HTMLDivElement>(null);
  const cphRef = useRef<any>(null);
  const showHeader = !!(icon || label || copyable);
  const StartIcon = icon;

  useEffect(() => {
    if (!cphRef.current) {
      const editor = new window.Copenhagen.Editor({
        language: language,
        value: value,
        nolines: !showLines,
        readonly: readonly,
        maximized: maximized
      });
      cphRef.current = editor;
      if (editorRef.current && cphRef.current) {
        const editorEl = editorRef.current as HTMLDivElement;
        const editor = cphRef.current;
        editor.open(editorEl, false);
        editor.fileTabs.hide();
        editor.treeView.hide();
      }
    }
    return () => {
      cphRef.current?.close();
      cphRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (cphRef.current) {
      const editor = cphRef.current;
      if (errorPos) {
        editor.setError(errorPos.lineIndex, errorPos.column);
      } else {
        editor.setError(null);
      }
    }
  }, [errorPos]);

  useEffect(() => {
    if (cphRef.current) {
      const editor = cphRef.current;
      editor.off('change');
      let x = Math.random();
      editor.on('change', (editor: any, text: string, cursors: any[]) => {
        onChange?.(text);
      });
    }
  }, [onChange]);

  // Only change value if it's different from the current value
  // Otherwise we keep resetting the value completely
  useEffect(() => {
    if (cphRef.current) {
      cphRef.current.setReadOnly(readonly);
      const currentValue = cphRef.current.getValue();
      if (currentValue !== value) {
        cphRef.current.setValue(value);
      }
    }
  }, [value]);
    
  useEffect(() => {
    if (cphRef.current) {
      cphRef.current.setReadOnly(readonly);
      cphRef.current.setLanguage(language);
      cphRef.current.setMaximized(maximized);
      cphRef.current.setNoLines(!showLines);
      cphRef.current.render(cphRef.current.value, true);
      if (onRun) {
        cphRef.current.addHotkey('ctrl+enter', () => onRun());
      } else {
        cphRef.current.addHotkey('ctrl+enter', () => {});
      }
      if (onSave) {
        cphRef.current.addHotkey('ctrl+s', () => onSave());
      } else {
        cphRef.current.addHotkey('ctrl+s', () => {});
      }
    }
  }, [readonly, language, maximized, showLines, onRun, onSave]);

  return (
    <div data-component="MiniEditor" data-type={type}>
      {showHeader && (
        <div className="mini-editor-header">
          {StartIcon && <StartIcon className="mini-editor-icon" />}
          {label && <div className="mini-editor-label">{label}</div>}
          <div className="spacer" />
          {typeof value === 'string' && copyable && (
            <ContentButton
              size="small"
              icon={Copy}
              onClick={() => {
                navigator.clipboard.writeText(value);
                toast.message({ type: 'success', message: 'Copied to clipboard' });
              }}
            />
          )}
        </div>
      )}
      <div className="mini-editor-content" ref={editorRef}></div>
    </div>
  );
}