import { useState } from "react";
import { useLoaderData, Link } from "react-router-dom";
import { DiscordLinkSchema } from "../../../utils/types";
import { Plus } from 'react-feather';
import Button from "../../../components/button/Button";
import DiscordLinkCard from "../../../components/cards/DiscordLinkCard";

import "./DashboardLinksPage.scss";

export default function DashboardLinksPage() {
  const { discordLinks: initialDiscordLinks } = useLoaderData() as {
    discordLinks: Array<DiscordLinkSchema>
  };

  const [discordLinks, setDiscordLinks] = useState<Array<DiscordLinkSchema>>(initialDiscordLinks);

  return (
    <div data-component="DashboardLinksPage">
      <div className="content-container">
        <div className="content-description">
          You can link your agent to a Discord server from its profile.
        </div>
        {!discordLinks.length && (
          <div className="no-connections">
            You do not currently have any linked servers.<br />
            To connect a server, create an agent and visit its settings.
          </div>
        )}
        <div className="item-group">
          {discordLinks.map((discordLink) => {
            return <div className="item-entry" key={discordLink.unique_id}>
              <DiscordLinkCard
                discordLink={discordLink}
                onUnlink={id => {
                  setDiscordLinks(discordLinks.filter(discordLink => discordLink.unique_id !== id));
                }}
              />
            </div>
          })}
        </div>
      </div>
    </div>
  );
}
