import { ReactElement, Children, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  LoaderFunction,
  useLocation,
  useNavigation,
} from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { RequireAuth } from './RequireAuth';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { TabList, TabItem } from './components/nav/TabList';
import { PageLoading, PageNavigating } from './components/loading/PageLoading';
import Footer from './components/nav/Footer';

import IndexPage from './pages/IndexPage';
import PricingPage from './pages/pricing/PricingPage';
import PricingPageLoader from './pages/pricing/PricingPageLoader';
import SignupPage from './pages/signup/SignupPage';
import LoginPage from './pages/signup/LoginPage';
import Login3pPage from './pages/signup/Login3pPage';

import PackagesPage from './pages/packages/PackagesPage';
import PackageDetailsPage from './pages/packages/PackageDetailsPage';
import PackageDetailsPageLoader from './pages/packages/PackageDetailsPageLoader';
import CodeEditor from './components/editor/CodeEditor';
import { ErrorPage, RouteErrorPage } from './pages/error/ErrorPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';

// Dashboard pages

import DashboardPage from './pages/dashboard/DashboardPage';

import EditProfilePage from './pages/dashboard/profile/edit/EditProfilePage';
import BillingCreditsPage from './pages/dashboard/billing/credits/BillingCreditsPage';
import BillingSubscriptionPage from './pages/dashboard/billing/subscription/BillingSubscriptionPage';
import BillingSubscriptionPageLoader from './pages/dashboard/billing/subscription/BillingSubscriptionPageLoader';
import BillingMethodsPage from './pages/dashboard/billing/methods/BillingMethodsPage';
import BillingMethodsPageLoader from './pages/dashboard/billing/methods/BillingMethodsPageLoader';
import DashboardLinksPage from './pages/dashboard/links/DashboardLinksPage';
import DashboardLinksPageLoader from './pages/dashboard/links/DashboardLinksPageLoader';
import DashboardPackagesPage from './pages/dashboard/packages/DashboardPackagesPage';
import DashboardPackagesPageLoader from './pages/dashboard/packages/DashboardPackagesPageLoader';

import AboutPage from './pages/AboutPage';
import AgentsLoader from './pages/dashboard/AgentsLoader';

import './components/loading/PageLoading.scss';

interface Page {
  path: string,
  element?: ReactElement,
  loader?: LoaderFunction,
  requireLogin?: boolean,
  redirect?: string,
  shouldRevalidate?: (args: { currentUrl: URL, nextUrl: URL }) => boolean,
  title?: string,
  description?: string
};

function RootPage() {
  const { user } = useAuth();
  return (
    <>
      <PageLoading />
      <Navigate to={user ? `/chat` : `/home`} replace={true} />
    </>
  );
}

const Tabs: {[key: string]: TabItem[]} = {
  Home: [
    {
      to: '/home',
      label: 'Home'
    },
    {
      to: '/packages',
      label: 'Package registry'
    },
    {
      to: '/pricing',
      label: 'Pricing'
    },
    {
      to: '/about',
      label: 'About us'
    }
  ],
  Legal: [
    {
      to: '/home',
      label: 'Home'
    },
    {
      to: '/privacy-policy',
      label: 'Privacy policy'
    },
    {
      to: '/terms-of-service',
      label: 'Terms of service'
    }
  ],
  Profile: [
    {
      to: '/dashboard',
      label: 'Profile'
    },
  ],
  Packages: [
    {
      to: '/dashboard/packages',
      label: 'Packages'
    }
  ],
  Connections: [
    {
      to: '/dashboard/connections',
      label: 'Connections'
    }
  ],
  Billing: [
    {
      to: '/dashboard/billing',
      label: 'Subscription'
    },
    {
      to: '/dashboard/billing/credits',
      label: 'Function credits'
    },
    {
      to: '/dashboard/billing/methods',
      label: 'Payment methods'
    }
  ]
};

const Pages: Page[] = [
  {
    path: "/",
    title: "&ai + chat + functions",
    description: "Build your own AI — a personal assistant, a customer support bot, or just a friend to talk to.",
    element: <RootPage />,
  },
  /** Redirects */
  {
    path: "/billing",
    redirect: "/dashboard/billing"
  },
  {
    path: "/billing/credits",
    redirect: "/dashboard/billing/credits"
  },
  {
    path: "/profile",
    redirect: "/dashboard/profile"
  },
  /**
   * hideNav pages: we want the user to finish this task
   */
  {
    path: "/login",
    title: "Log in",
    description: "Sign in to your Funct account",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    title: "Sign up", 
    description: "Create a new account on Funct",
    element: <SignupPage />
  },
  {
    path: "/login-3p",
    element: <Login3pPage mode="login" />,
  },
  /**
   * Navigable pages: Global
   */
  {
    path: "/home",
    title: "&ai + chat + functions",
    description: "Build your own AI — a personal assistant, a customer support bot, or just a friend to talk to.",
    element: (
      <DashboardPage backgroundColor="dark">
        <TabList tabs={Tabs.Home} mode="page" />
        <IndexPage />
        <Footer />
      </DashboardPage>
    ),
  },
  {
    path: "/pricing",
    title: "Pricing",
    description: "Simple pricing. Subscribe for better rate limits, pay as you go for function usage.",
    loader: PricingPageLoader(),
    element: (
      <DashboardPage backgroundColor="dark">
        <TabList tabs={Tabs.Home} mode="page" />
        <PricingPage />
        <Footer />
      </DashboardPage>
    ),
  },
  {
    path: "/packages",
    title: "Package registry",
    description: "Explore packages created by the Funct community.",
    element: (
      <DashboardPage backgroundColor="dark">
        <TabList tabs={Tabs.Home} mode="page" />
        <PackagesPage />
        <Footer />
      </DashboardPage>
    )
  },
  {
    path: "/about",
    title: "About us",
    description: "Meet the team behind Funct.",
    element: (
      <DashboardPage backgroundColor="dark">
        <TabList tabs={Tabs.Home} mode="page" />
        <AboutPage />
        <Footer />
      </DashboardPage>
    ),
  },
  {
    path: "/packages/private/:org/:name/:environment?/*",
    title: "Package details",
    description: "Explore details of a specific package.",
    loader: PackageDetailsPageLoader({ prefix: 'private' }),
    element: (
      <DashboardPage backgroundColor="dark">
        <TabList tabs={Tabs.Home} mode="page" />
        <PackageDetailsPage />
        <Footer />
      </DashboardPage>
    ),
  },
  {
    path: "/packages/agent/:org/:name/:environment?/*",
    title: "Package details",
    description: "Explore details of a specific package.",
    loader: PackageDetailsPageLoader({ prefix: 'agent' }),
    element: (
      <DashboardPage backgroundColor="dark">
        <TabList tabs={Tabs.Home} mode="page" />
        <PackageDetailsPage />
        <Footer />
      </DashboardPage>
    ),
  },
  {
    path: "/packages/:org/:name/:environment?/*",
    title: "Package details",
    description: "Explore details of a specific package.",
    loader: PackageDetailsPageLoader({}),
    element: (
      <DashboardPage backgroundColor="dark">
        <TabList tabs={Tabs.Home} mode="page" />
        <PackageDetailsPage />
        <Footer />
      </DashboardPage>
    ),
  },
  {
    path: "/privacy-policy",
    title: "Privacy policy",
    description: "Privacy policy for Funct, Inc.",
    element: (
      <DashboardPage backgroundColor="dark">
        <TabList tabs={Tabs.Legal} mode="page" />
        <PrivacyPolicyPage />
        <Footer />
      </DashboardPage>
    ),
  },
  {
    path: "/terms-of-service",
    title: "Terms of service",
    description: "Terms of service for Funct, Inc.",
    element: (
      <DashboardPage backgroundColor="dark">
        <TabList tabs={Tabs.Legal} mode="page" />
        <TermsOfServicePage />
        <Footer />
      </DashboardPage>
    )
  },
  /**
   * Navigable pages: Auth only
   */
  {
    path: "/link-3p",
    title: "Link account",
    description: "Link your Funct account to a third party service.",
    element: (
      <RequireAuth>
        <Login3pPage mode="link" />
      </RequireAuth>
    ),
  },
  {
    path: "/code",
    element: <CodeEditor />,
  },
  /**
   * Dashboard pages
   */
  {
    path: "/chat/:agent_id?",
    title: "Chat",
    description: "",
    loader: AgentsLoader(),
    element: (
      <DashboardPage dashboardView="agents" />
    ),
  },
  {
    path: "/dashboard",
    title: "Edit profile",
    description: "Your user profile on Funct.",
    element: (
      <DashboardPage dashboardView="user">
        <RequireAuth>
          <TabList tabs={Tabs.Profile} />
          <EditProfilePage />
        </RequireAuth>
      </DashboardPage>
    ),
  },
  {
    path: "/dashboard/billing",
    title: "Billing",
    description: "Billing and subscription details.",
    loader: BillingSubscriptionPageLoader(),
    element: (
      <DashboardPage dashboardView="user">
        <RequireAuth>
          <TabList tabs={Tabs.Billing} />
          <BillingSubscriptionPage />
        </RequireAuth>
      </DashboardPage>
    ),
  },
  {
    path: "/dashboard/billing/credits",
    title: "Function credits",
    description: "Function credit balance for Funct",
    element: (
      <DashboardPage dashboardView="user">
        <RequireAuth>
          <TabList tabs={Tabs.Billing} />
          <BillingCreditsPage />
        </RequireAuth>
      </DashboardPage>
    ),
  },
  {
    path: "/dashboard/billing/methods",
    title: "Payment methods",
    description: "Your payment methods for Funct",
    loader: BillingMethodsPageLoader(),
    element: (
      <DashboardPage dashboardView="user">
        <RequireAuth>
          <TabList tabs={Tabs.Billing} />
          <BillingMethodsPage />
        </RequireAuth>
      </DashboardPage>
    )
  },
  {
    path: "/dashboard/connections",
    title: "Connections",
    description: "Linked accounts and services.",
    loader: DashboardLinksPageLoader(),
    element: (
      <DashboardPage dashboardView="user">
        <RequireAuth>
          <TabList tabs={Tabs.Connections} />
          <DashboardLinksPage />
        </RequireAuth>
      </DashboardPage>
    )
  },
  {
    path: "/dashboard/packages",
    title: "Packages",
    description: "Your published packages",
    loader: DashboardPackagesPageLoader(),
    element: (
      <DashboardPage dashboardView="user">
        <RequireAuth>
          <TabList tabs={Tabs.Packages} />
          <DashboardPackagesPage />
        </RequireAuth>
      </DashboardPage>
    ),
  },
  /**
   * Handle errors if nothing else is reached
   */
  {
    path: "/*",
    title: "Error",
    description: "An error occurred.",
    element: (
      <DashboardPage backgroundColor="dark">
        <ErrorPage />,
      </DashboardPage>
    ),
  }
];

function Layout({
  children,
  title,
  description
}: {
  children: ReactElement,
  title?: string,
  description?: string
}) {
  const location = useLocation();
  const navigation = useNavigation();

  // Show loading during navigation
  const isNavigating = navigation.state === "loading";

  const defaultsRef = useRef({
    title: document.title,
    description: document
    .querySelector('meta[name="description"]')?.getAttribute('content') || ''
  });
  const defaults = defaultsRef.current;
  const titlePosition = title
    ? title.startsWith('&') ? 'right' : 'left'
    : 'none';
  if (title && title.startsWith('&')) {
    title = title.slice(1);
  }

  // Force favicon on location update
  // Needs to execute before children incase they need to update favicon
  useLayoutEffect(() => {
    const favicon = document.querySelector('link[rel="shortcut icon"]') as HTMLLinkElement;
    if (favicon) {
      favicon.href = '/logo/funct-logo-bw-256-outline.png';
    }
  }, [location.pathname]);
  
  return (
    <>
      <PageNavigating inProgress={isNavigating} />
      <div className="page-content" style={{pointerEvents: isNavigating ? 'none' : 'auto'}}>
        <Helmet>
          <title>{titlePosition === 'left' ? `${title} | ` : ''}{defaults.title}{titlePosition === 'right' ? ` | ${title}` : ''}</title>
          <meta name="description" content={description === void 0 ? defaults.description : description} />
        </Helmet>
        {children}
      </div>
    </>
  );
}

function Router() {
  const router = useMemo(() => {
    return createBrowserRouter(Pages.map(page => {
      const elementWithLayout = (
        <Layout title={page.title} description={page.description}>
          {(
            page.redirect
              ? <Navigate to={page.redirect} replace={true} />
              : (page.element || <></>)
          )}
        </Layout>
      );

      return {
        path: page.path,
        element: elementWithLayout,
        loader: page.loader || (() => async () => { return {}; }),
        shouldRevalidate: page.shouldRevalidate || (
          ({ currentUrl, nextUrl }) => currentUrl.pathname !== nextUrl.pathname
        ),
        errorElement: (
          <Layout>
            <RouteErrorPage />
          </Layout>
        ),
      };
    }));
  }, []);

  return (
    <HelmetProvider>
      <RouterProvider router={router} fallbackElement={<PageLoading />} />
    </HelmetProvider>
  );
}

export default Router;
