import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Textbox from "../textbox/Textbox";
import ChatMessage, { ChatMessageEntry } from "./ChatMessage";

import "./ChatBox.scss";
import { ActionButton } from "../button/ActionButton";
import { useSearchParams } from "react-router-dom";
import { ContentButton } from "../button/ContentButton";
import { ArrowUpCircle, X } from "react-feather";
import { useMobileDevice, useMobileScreenView } from "../../utils/hooks";

export function ChatBox ({
  activeId,
  botName = 'Funct',
  botAvatar,
  userName = 'User',
  userAvatar,
  welcomeMessage,
  messages = [],
  notificationElement,
  showTypingIndicator = false,
  onSubmit,
  onEditProfileClick,
  onDismissNotification
} : {
  activeId?: string,
  botName?: string,
  botAvatar?: string,
  userName?: string,
  userAvatar?: string,
  welcomeMessage?: string,
  messages?: ChatMessageEntry[],
  notificationElement?: React.ReactNode,
  showTypingIndicator?: boolean,
  onSubmit?: (value: string) => void,
  onEditProfileClick?: () => void,
  onDismissNotification?: () => void
}) {

  const navigate = useNavigate();
  const isMobileScreenView = useMobileScreenView();
  const isMobileDevice = useMobileDevice();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const scrollRef = useRef<HTMLDivElement>(null);
  const isScrolledToBottom = useRef(false);
  const textboxRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  const [ isVisible, setIsVisible ] = useState(false);

  const customNotificationElement = (
    typeof notificationElement === 'string' && isMobileScreenView
      ? notificationElement.split('\n')[0]
      : notificationElement
  );

  const handleScroll = () => {
    if (scrollRef.current) {
      const scrollEl = scrollRef.current;
      isScrolledToBottom.current = scrollEl.scrollTop + scrollEl.clientHeight >= scrollEl.scrollHeight - 1;
    }
  };

  useEffect(() => {
    // Make sure the chat box is scrolled to the bottom when the window is resized
    const resizeListener = () => {
      if (scrollRef.current && isScrolledToBottom.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    window.visualViewport?.addEventListener('resize', resizeListener);
    window.addEventListener('resize', resizeListener);
    return () => {
      window.visualViewport?.removeEventListener('resize', resizeListener);
      window.removeEventListener('resize', resizeListener);
    }
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      const scrollEl = scrollRef.current;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (showTypingIndicator && scrollRef.current) {
      const scrollEl = scrollRef.current;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  }, [showTypingIndicator]);

  useEffect(() => {
    if (notificationElement && scrollRef.current) {
      const scrollEl = scrollRef.current;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  }, [notificationElement]);

  useEffect(() => {
    setIsVisible(false);
    if (textboxRef.current) {
      const textbox = textboxRef.current;
      // Only focus if there is no menu open
      // and it is not a mobile device; otherwise keyboard pops up
      if (!searchParams.has('menu') && !isMobileDevice) {
        textbox.focus();
      }
    }
    if (scrollRef.current) {
      const scrollEl = scrollRef.current;
      scrollEl.scrollTop = scrollEl.scrollHeight;
      // This is a hack to ensure there's no flash of the chat box when scrolling
      setTimeout(() => {
        setIsVisible(true);
      }, 1);
    }
  }, [activeId]);

  return (
    <div
      data-component="ChatBox"
      data-is-mobile-device={isMobileDevice}
    >
      <div
        className="scrollable"
        style={{visibility: !isVisible ? 'hidden' : 'visible'}}
        onScroll={handleScroll}
        ref={scrollRef}
      >
        <div className="description">
          {welcomeMessage ? welcomeMessage : (
            <span>
              This is the beginning of your conversation with <mark onClick={onEditProfileClick}>@{botName}</mark>
            </span>
          )}
        </div>
        {messages.map((message, i) => {
          const msg = { ...message };
          if (msg.app) {
            if ((msg.avatar === null || msg.avatar === void 0) && botAvatar) {
              msg.avatar = botAvatar;
            }
            if (!msg.name && botName) {
              msg.name = botName;
            }
          } else {
            if ((msg.avatar === null || msg.avatar === void 0) && userAvatar) {
              msg.avatar = userAvatar;
            }
            if (!msg.name && userName) {
              msg.name = userName;
            }
          }
          return <ChatMessage key={i} {...msg} />;
        })}
        {showTypingIndicator && (
          <ChatMessage
            app={true}
            name={botName}
            avatar={botAvatar}
            isTyping={true}
            message="Thinking..."
          />
        )}
      </div>
      {notificationElement && (
        <div
          className="fixed-notification"
          onMouseDown={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="notification-content">
            <div className="notification-message">
              {customNotificationElement}
            </div>
            <ContentButton
              icon={ArrowUpCircle}
              onClick={() => navigate('/dashboard/billing')}
            >
              Upgrade
            </ContentButton>
            <ContentButton
              icon={X}
              onClick={() => {
                onDismissNotification?.();
              }}
            />
          </div>
        </div>
      )}
      {onSubmit && (
        <div className="fixed"> 
          <Textbox
            type="chat"
            lines={1}
            maxLines={8}
            placeholder={`Message @${botName}`}
            onSubmit={value => onSubmit(value)}
            ref={textboxRef}
          />
        </div>
      )}
    </div>
  );

}