import { LoaderFunctionArgs } from "react-router-dom";
import { DiscordLinkSchema } from "../../../utils/types";
import API from "../../../utils/api";

export default function DashboardLinksPageLoader() {
  return async ({ params }: LoaderFunctionArgs) => {
    const user = API.user;
    if (!user?.memberships?.[0]?.organization) {
      throw new Error('No organization found');
    }
    const organization = user.memberships[0].organization.name;
    const discordLinksResponse = await API.get('v1/discord_links', { organization });

    return {
      discordLinks: discordLinksResponse?.data || []
    };
  };
};
