import { useEffect, useRef } from "react";
import { Navigate, useRouteError } from "react-router-dom";
import "./ErrorPage.scss";

interface ErrorPageProps {
  code?: number,
  message?: string
};

export function RouteErrorPage() {
  const error = useRouteError() as { message?: string, status?: number };
  const errorLoggedRef = useRef(false);
  useEffect(() => {
    if (!errorLoggedRef.current) {
      errorLoggedRef.current = true;
      console.error(error);
    }
  }, []);
  if (error.status === 401) {
    return <Navigate to="/login?error=401" replace={true} />;
  }
  return (
    <ErrorPage code={error.status} message={error.message} />
  );
} 

export function ErrorPage({
  code = 404,
  message = `The page you're looking for doesn't exist.`
} : ErrorPageProps) {
  return (
    <div data-component="ErrorPage">
      <div className="content">
        <div className="error-code">
          {code}.
        </div>
        <div className="error-description">
          Oops. That's an error.<br /><br />
          {message}
        </div>
      </div>
    </div>
  );
}