import { Info } from "react-feather";
import "./InfoBox.scss";

export function InfoBox({ children }: { children: React.ReactNode }) {
  return (
    <div data-component="InfoBox">
      <Info />
      <span>
        {children}
      </span>
    </div>
  );
}