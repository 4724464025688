import { useEffect, useState } from 'react';
import Select from '../select/Select';
import { ContentButton } from '../button/ContentButton';
import { MiniEditor } from './MiniEditor';
import { Save, Play, X } from 'react-feather';
import './PayloadEditor.scss';

export function PayloadEditor({
  method = 'POST',
  payload,
  onMethodChange = () => {},
  onChange = () => {},
  onSave,
  onRun,
  onClose
}: {
  method: string,
  payload: string,
  onMethodChange?: (method: string) => void,
  onChange: (payload: string, json: { [key: string]: any } | null) => void,
  onSave: (payload: string, json: { [key: string]: any }) => void,
  onRun: (payload: string, json: { [key: string]: any } | null) => void,
  onClose?: () => void
}) {

  const [payloadValue, setPayloadValue] = useState(payload);
  const [error, setError] = useState('');
  const [errorPos, setErrorPos] = useState<{ lineIndex: number, column: number } | null>(null);
  const [isShakingNo, setIsShakingNo] = useState(false);
  const canSave = (payloadValue !== payload) && !error;
  
  const methodOptions = [
    { label: 'GET', value: 'GET' },
    { label: 'POST', value: 'POST' },
    { label: 'PUT', value: 'PUT' },
    { label: 'DELETE', value: 'DELETE' }
  ];

  useEffect(() => {
    setPayloadValue(payload);
  }, [method, payload]);

  return (
    <div data-component="PayloadEditor">
      <div className="payload-header">
        <div className="method-selector">
          <Select 
            options={methodOptions}
            value={method}
            onChange={onMethodChange}
            size="small"
          />
        </div>
        <ContentButton 
          color="orange"
          disabled={!canSave}
          icon={Save} 
          shake={isShakingNo}
          onClick={() => {
            let json;
            try {
              json = JSON.parse(payloadValue);
            } catch (e) {
              // do nothing
            }
            onSave(payloadValue, json);
          }}
        />
        <div className="payload-actions">
          {onClose && (
            <ContentButton
              color="grey" 
              icon={X}
              onClick={onClose}
            />
          )}
        </div>
      </div>
      <div className="payload-editor">
        <MiniEditor
          language="json"
          value={payloadValue}
          maximized={true}
          readonly={false}
          showLines={false}
          errorPos={errorPos}
          onSave={() => {
            let json;
            try {
              json = JSON.parse(payloadValue);
            } catch (e) {
              // do nothing
            }
            if (!canSave) {
              setIsShakingNo(true);
              setTimeout(() => {
                setIsShakingNo(false);
              }, 300);
            } else {
              onSave(payloadValue, json);
            }
          }}
          onChange={value => {
            let json;
            try {
              json = JSON.parse(value);
              setError('');
            } catch (e) {
              const error = e as Error;
              let message = error.message;
              let lineNumber = -1;
              let columnNumber = -1;
              message = message
                .replace(/\s*\(line (\d+) column (\d+)\)\s*$/gi, ($0, $1, $2) => {
                  lineNumber = parseInt($1);
                  columnNumber = parseInt($2);
                  return '';
                })
                .replace(/\s*at position \d+\s*$/gi, '')
                .replace(/\s*in JSON\s*$/gi, '')
                .replace(/\s*,[\s\S]*$/gi, '');
              if (lineNumber > -1 && columnNumber > -1) {
                let lineIndex = lineNumber - 1;
                let column = columnNumber - 1;
                setErrorPos({ lineIndex, column });
              } else {
                setErrorPos(null);
              }
              setError(message);
            }
            setPayloadValue(value);
            onChange(value, json);
          }}
          onRun={() => {
            let json;
            try {
              json = JSON.parse(payloadValue);
            } catch (e) {
              // do nothing
            }
            onRun(payloadValue, json);
          }}
        />
      </div>
      {error && (
        <div className="payload-error">
          {error}
        </div>
      )}
    </div>
  );
}