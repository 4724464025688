import React, { useEffect, useState } from "react";
import "./Toast.scss";
import { Check, Icon } from "react-feather";

export type ToastType = "success" | "error" | "info" | "warning";

export interface ToastProps {
  id: string;
  type: ToastType;
  icon?: Icon;
  message: string;
  duration?: number;
  onDismiss: (id: string) => void;
}

export function Toast({
  id,
  type,
  icon = Check,
  message,
  duration = 1000,
  onDismiss,
}: ToastProps) {

  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const StartIcon = icon;

  useEffect(() => {
    const displayTimer = setTimeout(() => {
      setLoaded(true);
      setVisible(true);
    }, 1);
    const timer = setTimeout(() => {
      setVisible(false);
      setTimeout(() => onDismiss(id), duration); // Allow fade-out animation
    }, duration);
    return () => {
      clearTimeout(displayTimer);
      clearTimeout(timer);
    };
  }, []);

  return (
    <div data-component="Toast" className={`toast toast-${type} ${visible ? 'visible' : loaded ? 'hidden' : ''}`}>
      <StartIcon className="toast-icon" />
      <span className="toast-message">{message}</span>
    </div>
  );
} 