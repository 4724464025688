import { Icon } from "react-feather";

type ToastType = "success" | "error" | "info" | "warning";

interface ToastOptions {
  type?: ToastType;
  icon?: Icon;
  message: string;
  duration?: number;
}

let toastCallback: ((options: ToastOptions) => void) | null = null;

export const toast = {
  setToastCallback: (callback: (options: ToastOptions) => void) => {
    toastCallback = callback;
  },
  
  message: ({ icon, type = "info", message, duration }: ToastOptions) => {
    if (toastCallback) {
      toastCallback({ icon, type, message, duration });
    } else {
      console.warn("Toast callback not set. Make sure ToastProvider is in your component tree.");
    }
  }
}; 