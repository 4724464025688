import { useState, useEffect, useRef, useCallback } from "react";
import { useMobileScreenView } from "../../utils/hooks";
import LoadingContentDiv from "./LoadingContentDiv";
import { AgentSchema, DownloadedPackage } from "../../utils/types";
import { useNavigationType, useLocation, useBlocker } from "react-router-dom";

import "./CodeEditor.scss";
import "./EditorStyleOverride.scss";
import { ContentButton, ContentButtonGroup } from "../button/ContentButton";
import { Save, Play, ChevronLeft, ChevronRight, ChevronDown, Settings, MessageSquare, Check, AlertCircle, Delete, Code, Database, Table } from "react-feather";
import { PayloadEditor } from "./PayloadEditor";
import API from "../../utils/api";
import { toast } from "../../services/toast";
import { Link, useSearchParams } from "react-router-dom";
import { DatabaseViewer } from "../../pages/databases/DatabaseViewer";

const LOCKED_FILES = ['package.json', 'funct.json', 'sample_payloads.json'];
const NODE_BUILT_INS = [
  'assert',
  'async_hooks',
  'buffer',
  'child_process',
  'cluster',
  'console',
  'constants',
  'crypto',
  'dgram',
  'diagnostics_channel',
  'dns',
  'domain',
  'events',
  'fs',
  'http',
  'http2',
  'https',
  'inspector',
  'module',
  'net',
  'os',
  'path',
  'perf_hooks',
  'process',
  'punycode',
  'querystring',
  'readline',
  'repl',
  'stream',
  'string_decoder',
  'timers',
  'tls',
  'trace_events',
  'tty',
  'url',
  'util',
  'v8',
  'vm',
  'worker_threads',
  'zlib'
];
const NODE_BUILT_INS_LOOKUP = new Set(NODE_BUILT_INS);

export default function CodeEditor({
  rootName,
  rootIcon,
  rootColor,
  loading = false,
  running = false,
  files = {},
  chatVisible = false,
  onSave = () => {},
  onRun = () => {},
  onChange = () => {},
  onCursorChange = () => {},
  onChat,
  agent,
  onAgentUpdated,
}: {
  rootName?: string,
  rootIcon?: string,
  rootColor?: string,
  loading?: boolean,
  running?: boolean,
  files?: DownloadedPackage,
  chatVisible?: boolean,
  onSave?: (files: DownloadedPackage) => void,
  onRun?: ({ requestMethod, activeFile, isModified, selections, payload } : { requestMethod: string, activeFile: any, isModified: boolean, selections: any[], payload: { [key: string]: any } }) => void,
  onChange?: ({ activeFile, isModified, selections } : { activeFile: any, isModified: boolean, selections: any[] }) => void,
  onCursorChange?: ({ activeFile, selections, language } : { activeFile: any, isModified: boolean, selections: any[], language: string }) => void,
  onChat?: () => void,
  agent?: AgentSchema;
  onAgentUpdated?: (agent: AgentSchema) => void;
}) {

  const isMobileScreen = useMobileScreenView();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const isLoadedRef = useRef(false);
  const editorRef = useRef(null);
  const cphRef = useRef<any>(null);
  const activeRef = useRef<{ activeFile: any, selections: any[] } | null>(null);
  const npmInstallTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [ isShakingNo, setIsShakingNo ] = useState(false);
  const canFileRun = () => (
    activeRef.current?.activeFile?.pathname.startsWith('functions/') ||
    activeRef.current?.activeFile?.pathname.startsWith('www/')
  );
  const canRun = () => !running && canFileRun();

  const [filename, setFilename] = useState('');
  const [language, setLanguage] = useState('');
  const [position, setPosition] = useState({ lineNumber: 0, column: 0 });
  const [canSave, setCanSave] = useState(false);
  const [treeViewOpen, setTreeViewOpen] = useState(true);
  const [payloadEditorOpen, setPayloadEditorOpen] = useState(false);
  const [requestMethod, setRequestMethod] = useState('POST');
  const [activePayload, setActivePayload] = useState<{ [key: string]: any } | null>(null);
  const [editPayload, setEditPayload] = useState('');

  // Database mode settings
  const selectedTable = searchParams.get('table');
  const setSelectedTable = (table: string | null) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      table: table || ''
    });
  };
  const sqliteDatabase = agent?.agentDbs?.[0]?.sqliteDatabase;
  const tableSchema = sqliteDatabase?.table_schema || null;
  const mode = ({
    'code': 'code',
    'database': 'database'
  }[searchParams.get('mode') || ''] || 'code') as 'code' | 'database';

  const getSamplePayloads = () => {
    if (cphRef.current) {
      const editor = cphRef.current;
      const payloadFile = editor.localFiles['sample_payloads.json'] || { value: '{}' };
      let payloads: { [key: string]: any } = {};
      try {
        payloads = JSON.parse(payloadFile.value);
      } catch (e) {
        console.error('Error parsing payloads', e);
        payloads = {};
      }
      return payloads;
    } else {
      return {};
    }
  };

  const getNpmDependencies = () => {
    if (cphRef.current) {
      const editor = cphRef.current;
      const packageFile = editor.localFiles['package.json'] || { value: '{}' };
      let packageJSON: { [key: string]: any } = {};
      try {
        packageJSON = JSON.parse(packageFile.value);
      } catch (e) {
        console.error('Error parsing package.json', e);
        packageJSON = {};
      }
      return packageJSON.dependencies || {};
    } else {
      return {};
    }
  };

  const saveNpmDependencies = (deps: { [key: string]: string }) => {
    if (cphRef.current) {
      const editor = cphRef.current;
      const files = { ...editor.localFiles };
      const packageFile = files['package.json'] || { value: '{}' };
      let packageJSON: { [key: string]: any } = {};
      try {
        packageJSON = JSON.parse(packageFile.value);
      } catch (e) {
        console.error('Error parsing package.json', e);
        packageJSON = {};
      }
      packageJSON.dependencies = deps || {};
      files['package.json'] = {
        value: JSON.stringify(packageJSON, null, 2)
      };
      editor.loadFiles(files, LOCKED_FILES, isLoadedRef.current);
      // onSave(editor.export()); do not save until file is run
    }
  };

  const getRequiredNpmDependencies = () => {
    const currentDeps = getNpmDependencies();
    const addDeps: { [key: string]: string } = {};
    const keepDeps: { [key: string]: string } = {};
    const removeDeps: { [key: string]: string } = {};
    if (cphRef.current) {
      const activeFile = cphRef.current?.fileManager.activeFile;
      const editor = cphRef.current;
      const files = editor.localFiles;
      for (const pathname in files) {
        if (
          !pathname.startsWith('www/') &&
          pathname.endsWith('.js')
        ) {
          const file = editor.fileManager.openFiles[pathname] || files[pathname];
          const value = file.value || '';
          const importRE = /import\s+.*\s+from\s+(['"])([^'"]+)\1/g;
          let match;
          while (match = importRE.exec(value)) {
            const dep = match[2];
            if (
              dep.startsWith('.') ||
              dep.startsWith('/') ||
              dep.startsWith('node:') ||
              NODE_BUILT_INS_LOOKUP.has(dep)
            ) {
              continue;
            }
            if (!keepDeps[dep] && !addDeps[dep]) {
              if (currentDeps[dep]) {
                keepDeps[dep] = currentDeps[dep];
              } else {
                addDeps[dep] = '';
              }
            }
          }
        }
      }
      for (const dep in currentDeps) {
        if (!keepDeps[dep] && !addDeps[dep]) {
          removeDeps[dep] = currentDeps[dep];
        }
      }
      return { current: currentDeps, keep: keepDeps, add: addDeps, remove: removeDeps };
    } else {
      return { current: currentDeps, keep: { ...currentDeps }, add: addDeps, remove: removeDeps };
    }
  }

  const installRequiredNpmDependencies = async () => {
    const { keep, add, remove } = getRequiredNpmDependencies();
    const installed: { [key: string]: string } = {};
    if (Object.keys(add).length > 0) {
      const depsList = Object.keys(add);
      await Promise.all(
        depsList.map(async (dep) => {
          try {
            const result = await API.get(`v1/code_helpers/npm`, { name: dep });
            const version = `${result?.version || 'latest'}`;
            installed[dep] = version;
          } catch (e) {
            // skip; do nothing
          }
        })
      );
    }
    const removedList = Object.keys(remove);
    if (removedList.length > 0) {
      const depsDisplay = removedList.map((dep) => `${dep}@${remove[dep]}`).join(', ');
      toast.message({
        icon: Delete,
        type: 'warning',
        message: `Dependencies removed:\n${depsDisplay}`,
        duration: 3000
      });
    }
    const installedList = Object.keys(installed);
    if (installedList.length > 0) {
      const depsDisplay = installedList.map((dep) => `${dep}@${installed[dep]}`).join(', ');
      toast.message({
        type: 'success',
        message: `Dependencies added:\n${depsDisplay}`,
        duration: 3000
      });
    }
    if (Object.keys(installed).length > 0 || Object.keys(remove).length > 0) {
      saveNpmDependencies({ ...keep, ...installed });
    }
  }

  const parseCodeForErrors = async () => {
    const editor = cphRef.current;
    if (editor) {
      const filename = editor.fileManager.activeFile?.pathname;
      const content = editor.getValue();
      if (filename?.endsWith('.js')) {
        try {
          const result = await API.get(`v1/code_helpers/parse`, { filename, content });
          if (result?.parseError) {
            editor.setError(result.parseError.line - 1, result.parseError.column);
          }
        } catch (e) {
          // only log; if this service fails it fails silently
          console.error(e);
        }
      }
    }
  };

  const checkForErrorsAndNpmDependencies = () => {
    if (cphRef.current) {
      const editor = cphRef.current;
      editor.setError(null);
    }
    if (npmInstallTimeoutRef.current) {
      clearTimeout(npmInstallTimeoutRef.current);
      npmInstallTimeoutRef.current = null;
    }
    npmInstallTimeoutRef.current = setTimeout(() => {
      installRequiredNpmDependencies();
      parseCodeForErrors();
    }, 300);
  };

  const readActiveFilePayload = () => {
    if (cphRef.current) {
      const payloads = getSamplePayloads();
      const activePayload = payloads[filename]?.[requestMethod] || {};
      return activePayload;
    } else {
      return {};
    }
  };

  const saveActiveFilePayload = (json: { [key: string]: any }) => {
    if (cphRef.current) {
      const payloads = getSamplePayloads();
      payloads[filename] = payloads[filename] || {};
      payloads[filename][requestMethod] = json;
      const editor = cphRef.current;
      const files = editor.localFiles;
      files['sample_payloads.json'] = {
        value: JSON.stringify(payloads, null, 2)
      };
      editor.loadFiles(files, LOCKED_FILES, isLoadedRef.current);
      setActivePayload(json);
      setEditPayload(JSON.stringify(json, null, 2));
      onSave(editor.export());
    }
  };

  const fileHelpers = {
    move: (pathname: string, newPathname: string) => {
      if (cphRef.current) {
        const payloads = getSamplePayloads();
        if (payloads[pathname]) {
          payloads[newPathname] = payloads[pathname];
          delete payloads[pathname];
          const editor = cphRef.current;
          const files = editor.localFiles;
          files['sample_payloads.json'] = {
            value: JSON.stringify(payloads, null, 2)
          };
          editor.loadFiles(editor.localFiles, LOCKED_FILES, isLoadedRef.current);
        }
      }
    },
    copy: (pathname: string, newPathname: string) => {
      if (cphRef.current) {
        const payloads = getSamplePayloads();
        if (payloads[pathname]) {
          payloads[newPathname] = payloads[pathname];
          const editor = cphRef.current;
          const files = editor.localFiles;
          files['sample_payloads.json'] = {
            value: JSON.stringify(payloads, null, 2)
          };
          editor.loadFiles(editor.localFiles, LOCKED_FILES, isLoadedRef.current);
        }
      }
    },
    unlink: (pathname: string) => {
      if (cphRef.current) {
        const payloads = getSamplePayloads();
        if (payloads[pathname]) {
          delete payloads[pathname];
          const editor = cphRef.current;
          const files = editor.localFiles;
          files['sample_payloads.json'] = {
            value: JSON.stringify(payloads, null, 2)
          };
          editor.loadFiles(editor.localFiles, LOCKED_FILES, isLoadedRef.current);
        }
      }
    }
  };

  useEffect(() => {
    if (cphRef.current) {
      cphRef.current.treeView.setRootInfo(rootName, rootIcon, rootColor);
    }
  }, [rootName, rootIcon, rootColor]);

  const updateInterface = (editor: any) => {
    const activeFile = editor.fileManager.activeFile || null;
    const isModified = editor.fileManager.isModified(activeFile?.pathname);
    const text = editor.getValue();
    const selections = editor.user.cursors.map((cursor: any) => cursor.getSelectionInformation(text));
    const language = editor.getActiveLanguage();
    setLanguage(activeFile ? language : '');
    setFilename(editor.fileManager.getFormattedPathname(activeFile?.pathname) || '');
    setPosition((activeFile && selections[0])
      ? { lineNumber: selections[0].lineNumber, column: selections[0].column }
      : { lineNumber: 0, column: 0 }
    );
    setCanSave(isModified);
    activeRef.current = { activeFile, selections };
    return { activeFile, isModified, selections, language };
  };

  const dispatchRun = (payload: { [key: string]: any } | null) => {
    const editor = cphRef.current;
    if (payload && canRun() && activeRef.current && editor) {
      const { activeFile, selections } = activeRef.current;
      const isModified = editor.fileManager.isModified(activeFile?.pathname);
      if (isModified) {
        editor.save();
      }
      onRun({ requestMethod, activeFile, isModified, selections, payload });
    } else {
      setIsShakingNo(true);
      setTimeout(() => {
        setIsShakingNo(false);
      }, 200);
    }
  };

  useEffect(() => {
    if (cphRef.current) {
      const editor = cphRef.current;
      editor.off('local.files.commit');
      editor.on('local.files.commit', async (e: any, files: { [key: string]: any }) => {
        onSave(files);
      });
      editor.off('language.changed');
      editor.on('language.changed', (editor: any) => {
        const { activeFile, isModified, selections } = updateInterface(editor);
        onChange({ activeFile, isModified, selections });
      });
      editor.off('file.active');
      editor.on('file.active', (editor: any) => {
        const { activeFile, isModified, selections } = updateInterface(editor);
        checkForErrorsAndNpmDependencies();
        onChange({ activeFile, isModified, selections });
      });
      editor.off('change');
      editor.on('change', (editor: any, text: string, cursors: any[]) => {
        const { activeFile, isModified, selections } = updateInterface(editor);
        checkForErrorsAndNpmDependencies();
        onChange({ activeFile, isModified, selections });
      });
      editor.off('cursor');
      editor.on('cursor', (editor: any, text: string, cursors: any[]) => {
        const { activeFile, isModified, selections, language } = updateInterface(editor);
        onCursorChange({ activeFile, isModified, selections, language });
      });
      editor.off('file.move');
      editor.on('file.move', (editor: any, pathname: string, newPathname: string) => {
        fileHelpers.move(pathname, newPathname);
        const { activeFile, isModified, selections } = updateInterface(editor);
        onChange({ activeFile, isModified, selections });
      });
      editor.off('file.copy');
      editor.on('file.copy', (editor: any, pathname: string, newPathname: string) => {
        fileHelpers.copy(pathname, newPathname);
        const { activeFile, isModified, selections } = updateInterface(editor);
        onChange({ activeFile, isModified, selections });
      });
      editor.off('file.unlink');
      editor.on('file.unlink', (editor: any, pathname: string) => {
        fileHelpers.unlink(pathname);
        const { activeFile, isModified, selections } = updateInterface(editor);
        onChange({ activeFile, isModified, selections });
      });
      editor.addHotkey('ctrl+enter', (text: string, cursors: any[]) => {
        const { activeFile, isModified, selections } = updateInterface(editor);
        dispatchRun(activePayload);
      });
    }
  }, [onRun, onSave, onChange, onCursorChange, activePayload]);

  useEffect(() => {
    if (!cphRef.current) {
      const editor = new window.Copenhagen.Editor({
        language: 'javascript',
        maximized: true
      });
      editor.treeView.show();
      editor.fileTabs.show();
      cphRef.current = editor;
      editor.treeView.setRootInfo(rootName, rootIcon, rootColor);
    }
    if (editorRef.current && cphRef.current) {
      const editorEl = editorRef.current as HTMLDivElement;
      const editor = cphRef.current;
      editor.open(editorEl.querySelector('.text-editor.main'));
      editor.treeView.open(editorEl.querySelector('.tree-view-container.main'));
      editor.fileTabs.open(editorEl.querySelector('.file-tabs-container.main'));
    }
    return () => {
      cphRef.current?.close();
      cphRef.current?.treeView?.close();
      cphRef.current?.fileTabs?.close();
      cphRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (cphRef.current && Object.keys(files).length > 0) {
      const editor = cphRef.current;
      editor.loadFiles(files, LOCKED_FILES, isLoadedRef.current);
      if (!isLoadedRef.current) {
        isLoadedRef.current = true;
        if (files['functions/index.js']) {
          editor.openFile('functions/index.js');
        } else {
          const pathnames = Object.keys(files);
          const functionPathnames = pathnames
            .filter(pathname => pathname.startsWith('functions/') && pathname.endsWith('.js'))
            .sort((a, b) => {
              const aSlashes = a.split('/').length;
              const bSlashes = b.split('/').length;
              if (aSlashes !== bSlashes) {
                return aSlashes - bSlashes;
              }
              return a.localeCompare(b);
            });
          if (functionPathnames[0]) {
            editor.openFile(functionPathnames[0]);
          }
        }
      }
    } else {
      isLoadedRef.current = false;
    }
  }, [files]);

  useEffect(() => {
    if (isMobileScreen) {
      setTreeViewOpen(false);
    } else {
      setTreeViewOpen(true);
    }
  }, [isMobileScreen]);

  useEffect(() => {
    const json = readActiveFilePayload();
    setActivePayload(json);
    setEditPayload(JSON.stringify(json, null, 2));
  }, [filename, requestMethod]);

  useEffect(() => {
    if (mode === 'code') {
      const editor = cphRef.current;
      if (editor && !isMobileScreen) {
        editor.focus();
      }
    }
  }, [mode]);
  
  // Function to check if there are unsaved changes
  const hasUnsavedChanges = useCallback(() => {
    if (cphRef.current) {
      return cphRef.current.fileManager.hasUnsavedChanges();
    }
    return false;
  }, []);
  
  // Set up navigation blocker
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => {
      // Only block if navigating to a different path and there are unsaved changes
      const currentSearchParams = new URLSearchParams(currentLocation.search);
      const newSearchParams = new URLSearchParams(nextLocation.search);
      return (
        (
          currentLocation.pathname !== nextLocation.pathname ||
          currentSearchParams.get('tab') !== newSearchParams.get('tab')
         ) &&
        hasUnsavedChanges()
      );
    }
  );
  
  useEffect(() => {
    if (blocker.state === 'blocked') {
      const confirmed = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (confirmed) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

  return (
    <div data-component="CodeEditor"
      data-is-treeview-open={treeViewOpen}
      ref={editorRef}
    >
      <div className="editor-container">
        <div className="editor-left" data-mode={mode}>
          {mode !== 'code' && (
              <div
              className="editor-left-header"
              onClick={() => {
                const entries = Object.fromEntries(searchParams.entries());
                delete entries.mode;
                setSearchParams({
                  ...entries
                });
              }}
            >
              <span className="header-icon"><Code /></span>
              <span>Code</span>
              <div className="spacer" />
              <span className="header-chevron">
                <ChevronRight />
              </span>
            </div>
          )}
          <div className="tree-view-container main mode-code" style={{ display: loading ? 'none' : '' }} />
          {loading && (
            <div className="tree-view-container loading mode-code">
                <LoadingContentDiv width={300} offset={0} />
                <LoadingContentDiv width={100} offset={32} />
                <LoadingContentDiv width={200} offset={64} />
                <LoadingContentDiv width={150} offset={64} />
                <LoadingContentDiv width={100} offset={32} />
                <LoadingContentDiv width={200} offset={64} />
                <LoadingContentDiv width={100} offset={32} />
                <LoadingContentDiv width={100} offset={32} />
            </div>
          )}
          {agent && agent.agentDbs?.[0]?.sqliteDatabase && (
            <>
              <div className="editor-left-header"
                onClick={() => {
                  const entries = Object.fromEntries(searchParams.entries());
                  setSearchParams({
                    ...entries,
                    mode: 'database'
                  });
                }}
              >
                <span className="header-icon"><Database /></span>
                <span>Database</span>
                <div className="spacer" />
                <span className="header-chevron">
                  {mode !== 'database' && <ChevronRight />}
                  {mode === 'database' && <ChevronDown />}
                </span>
              </div>
              <div className="sqlite-database-list mode-database">
                {!(tableSchema?.tables.length) && (
                  <div className="list-empty">
                    No tables found
                  </div>
                )}
                {!!(tableSchema?.tables.length) && (
                  <div className="list-table-container">
                    {tableSchema?.tables.map((table: any) => (
                      <div
                        className="list-table" key={table.name}
                        data-selected={selectedTable === table.name}
                        onClick={() => {
                          setSearchParams({
                          ...Object.fromEntries(searchParams.entries()),
                          table: table.name
                        });
                      }}
                      >
                        <span className="table-icon"><Table /></span>
                        <span className="table-name">{table.name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="editor-right">
          {isMobileScreen && (
            <div className="tree-view-toggle" onClick={() => setTreeViewOpen(treeViewOpen => !treeViewOpen)}>
              <div className="tree-view-toggle-grab"></div>
              <div className="tree-view-toggle-grab">
                {treeViewOpen ? <ChevronLeft /> : <ChevronRight />}
              </div>
              <div className="tree-view-toggle-grab"></div>
            </div>
          )}
          <div className="editor-right-code" style={{ display: mode === 'code' ? '' : 'none' }}>
            <div className="file-tabs-container main" style={{ display: loading ? 'none' : '' }}/>
            <div className="text-editor main" style={{ display: loading ? 'none' : '' }}/>
            {loading && (
              <>
                <div className="file-tabs-container loading">
                  <LoadingContentDiv width={100} offset={0} />
                </div>
                <div className="text-editor loading">
                  <LoadingContentDiv width={200} offset={0} />
                  <LoadingContentDiv width={200} offset={0} />
                  <LoadingContentDiv width={0} offset={0} />
                  <LoadingContentDiv width={300} offset={0} />
                  <LoadingContentDiv width={250} offset={0} />
                  <LoadingContentDiv width={400} offset={0} />
                  <LoadingContentDiv width={100} offset={32} />
                  <LoadingContentDiv width={130} offset={32} />
                  <LoadingContentDiv width={170} offset={64} />
                  <LoadingContentDiv width={375} offset={64} />
                  <LoadingContentDiv width={150} offset={64} />
                  <LoadingContentDiv width={50} offset={32} />
                  <LoadingContentDiv width={100} offset={32} />
                  <LoadingContentDiv width={50} offset={0} />
                </div>
              </>
            )}
          </div>
          {mode === 'database' && agent && (
            <div className="editor-right-database">
              <DatabaseViewer
                agent={agent}
                selectedTable={selectedTable}
                onTableSelect={setSelectedTable}
                onAgentUpdated={onAgentUpdated}
              />
            </div>
          )}
          {payloadEditorOpen && canFileRun() &&(
            <PayloadEditor
              method={requestMethod}
              payload={editPayload}
              onMethodChange={setRequestMethod}
              onChange={(payload, json) => {
                // only change EditPayload after saving...
                setActivePayload(json);
              }}
              onSave={(payload, json) => {
                saveActiveFilePayload(json);
              }}
              onRun={(payload, json) => {
                dispatchRun(json);
              }}
              onClose={() => {
                // Closing, reset payload to active file
                setPayloadEditorOpen(false);
                const json = readActiveFilePayload();
                setActivePayload(json);
                setEditPayload(JSON.stringify(json, null, 2));
              }}
            />
          )}
        </div>
      </div>
      <div className="editor-footer">
        <span className="editor-filename">
          <span>{filename || '(no file selected)'}</span>
        </span>
        {position && <span className="editor-position">{position.lineNumber}:{position.column}</span>}
        {language && <span className="editor-language">{language}</span>}
        <span className="spacer" />
        <div className="editor-footer-actions">
          <ContentButton
            color="orange"
            icon={Save}
            disabled={!canSave}
            onClick={() => {
              const editor = cphRef.current;
              if (canRun() && activeRef.current && editor) {
                const { activeFile, selections } = activeRef.current;
                const isModified = editor.fileManager.isModified(activeFile?.pathname);
                if (isModified) {
                  editor.save();
                }
              }
            }}
          >
            {isMobileScreen ? '' : 'Save'}
          </ContentButton>
          <ContentButtonGroup shake={isShakingNo}>
            <ContentButton
              color={activePayload ? 'green' : 'red'}
              icon={payloadEditorOpen ? activePayload ? Check : AlertCircle : Settings}
              disabled={!canRun() || running}
              onClick={() => {
                if (!payloadEditorOpen) {
                  setPayloadEditorOpen(true);
                  const json = readActiveFilePayload();
                  setActivePayload(json);
                  setEditPayload(JSON.stringify(json, null, 2));
                }
              }}
            />
            <ContentButton
              color={activePayload ? 'green' : 'red'}
              endIcon={Play}
              iconStyling="fill-shrink"
              disabled={!canRun()}
              loading={running}
              onClick={(e) => dispatchRun(activePayload)}
            >
              {isMobileScreen ? '' : `${requestMethod[0] + requestMethod.slice(1).toLowerCase()}`}
            </ContentButton>
          </ContentButtonGroup>
          {onChat && !chatVisible && (
            <ContentButton
              color="grey"
              icon={MessageSquare}
              onClick={onChat}
            />
          )}
        </div>
      </div>
    </div>
  );

};