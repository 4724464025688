import { LoaderFunctionArgs } from "react-router-dom";
import { PackageSchema } from "../../../utils/types";
import API from "../../../utils/api";

export default function DashboardPackagesPageLoader() {
  return async ({ params }: LoaderFunctionArgs) => {
    const user = API.user;
    if (!user?.memberships?.[0]?.organization) {
      throw new Error('No organization found');
    }
    const organization = user.memberships[0].organization.name;
    const packagesResponse = await API.get('v1/packages', { organization });

    return {
      packages: packagesResponse?.data || []
    };
  };
};
