import { useLoaderData, Link } from "react-router-dom";
import { PricingPageLoaderData} from "./PricingPageLoader";

import { PricingCard } from "./PricingCard";
import { InternalLink } from "../../components/internal-link/InternalLink";
import "./PricingPage.scss";

export default function PricingPage() {

  const { plans } = useLoaderData() as PricingPageLoaderData;

  return (
    <div data-component="PricingPage">
      <div className="pricing-title">
        <div className="heading">
          Simple pricing
        </div>
        <div className="subheading">
          Subscribe for better rate limits, pay as you go for function usage
          </div>
      </div>
      <div className="pricing-plans">
        {plans.map(plan => <PricingCard plan={plan} key={plan.name} />)}
      </div>
      <div className="pricing-faq">

        <h1>Frequently asked questions</h1>

        <h2>What are function credits?</h2>
        <p>
          Function credits can be used to execute <InternalLink to="/packages">functs</InternalLink>, which are
          hosted functions that your bots can run. These functions can do anything you program them
          to and are written in JavaScript. You can use community-provided functs or write your
          own private functs.
        </p>

        <h2>How many function calls can I make with $1.00 in function credits?</h2>
        <p>
          For simple web requests you can expect to be able to make between
          1,000 - 10,000 function calls per $1.00 of function
          credit, depending on the amount of time your functions take to run.<br /><br />

          Function credits are billed at <strong>$0.50 per 1,000 GB-s of usage</strong>.
          GB-s represents a "gigabyte-second" and is calculated by the function RAM &times; execution time.
          For example, a function with 512 MB (0.5 GB) of RAM running for 200ms would use:<br /><br />

          Used GB-s = 0.5GB &times; 0.2s = 0.1 GB-s<br />
          Used credits = $0.50 / 1,000 GB-s &times; 0.1 GB-s = $0.00005<br /><br />

          $0.00005 is five <em>thousandths</em> of a cent, meaning you could run this function 1,000 times
          for $0.05 in function credits, or make about 20,000 calls per $1.00.
          Please be advised that most functions that make external web requests will run longer than this,
          and time-intensive applications like video processing or token generation from other LLMs will
          be more expensive.
        </p>

        <h2>What happens if I go over my function credit limit?</h2>

        <p>
          Your bot will continue to function if it is within its rate limits,
          but your functions will throw a Payment Required error, status code 402.
          You can <InternalLink to="/billing/credits">purchase function credits</InternalLink> at any time for
          $1.00 USD per $1.00 in function credits.
        </p>

        <h2>What happens to unused function credits?</h2>

        <p>
          Unused function credits roll over &mdash; they continue to accrue in your account.
          In the future we will be introducing more ways to spend your credits.
        </p>

        <h2>What is your cancelation and refund policy?</h2>

        <p>
          You can cancel at any time in the month. We will credit your account for future usage that will
          be applied to your next payment or subscription as a proration. However, by default you will not be refunded.<br /><br />

          Refunds will be issued only in the following circumstances:<br /><br />

          (1) If you have no function usage and under 10 total messages sent, we will issue full refunds
          for the last month of payment anytime inside of the month.<br /><br />

          (2) If you have any function usage at all but less than 10 total messages sent, we will refund
          only the difference between your free function credit reward and your monthly charge. For
          a <strong>Pro</strong> account, this would be $19 - $5 = $14 refunded.<br /><br />

          Please email
          {" "}<a href="mailto:support@funct.me">support@funct.me</a> if you have a cancelation or refund
          question.
        </p>

        <h2>What is your Service Level Agreement policy?</h2>

        <p>
          For accounts with an Enterprise SLA, we will refund $20 for every hour of downtime
          beyond 43 minutes and 28 seconds (99.9% uptime) each month, up to a maximum of 40% of your monthly bill. Please email
          {" "}<a href="mailto:support@funct.me">support@funct.me</a> and refer to this FAQ if you wish to inquire
          about your business SLA.
        </p>

      </div>
    </div>
  );

}