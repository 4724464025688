import { ReactElement, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Package, ChevronDown, ChevronRight, Database, AlertTriangle, ArrowDown, Slash, Shield, ShieldOff } from "react-feather";
import { FunctionResult, SQLResult } from "../../utils/types";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';
import { formatDate } from "../../utils/time";

import "./ChatMessage.scss";
import { InternalLink } from "../internal-link/InternalLink";
import { useMobileDevice } from "../../utils/hooks";

export interface ChatMessageEntry {
  name?: string,
  avatar?: string,
  time?: Date | string,
  app?: boolean,
  message?: string,
  functions?: FunctionResult[] | null,
  sql?: SQLResult | null,
  notification?: string,
  created_at?: string,
  is_text_warned?: boolean,
  is_text_filtered?: boolean,
  is_text_blocked?: boolean,
  isTyping?: boolean
};

// Add this function to the file, outside the component
const preserveMultipleNewlines = (text: string) => {
  return text.replace(/\n{2,}/g, match => {
    return '\n\n' + Array(match.length - 1).fill('&nbsp;').join('\n') + '\n\n';
  });
};

export default function ChatMessage({
  name = "User",
  avatar,
  time,
  app = false,
  message = "",
  functions = [],
  sql,
  notification = "",
  is_text_warned = false,
  is_text_filtered = false,
  is_text_blocked = false,
  isTyping = false
} : ChatMessageEntry) {

  const isMobileDevice = useMobileDevice();
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedFunctions, setExpandedFunctions] = useState<number[]>([]);
  time = new Date(time || Date.now());

  const markdown = useMemo(() => {
    return (
      <ReactMarkdown 
        rehypePlugins={[]}
        remarkPlugins={[remarkGfm]}
        components={{
          a: ({node, ...props}) => <a target="_blank" rel="noopener noreferrer" {...props} />,
          table: ({node, ...props}) => <table {...props} />,
        }}
      >
        {preserveMultipleNewlines(message)}
      </ReactMarkdown>
    );
  }, [message]);

  // Deprecated for now: shows up in main chat box
  // const notificationParts: ReactElement[] = notification
  //   .split(/(^|\s)(https?\:\/\/[^\s]+)/gi)
  //   .map((text, i) => {
  //     if (text.startsWith('http://') || text.startsWith('https://')) {
  //       if (text.endsWith('.')) {
  //         return <span key={i}><a href={text.slice(0, -1)} target="_blank">{text.slice(0, -1)}</a>.</span>;
  //       } else {
  //         return <span key={i}><a href={text} target="_blank">{text}</a></span>;
  //       }
  //     } else {
  //       return <span key={i}>{text}</span>;
  //     }
  //   });

  return (
    <div
      data-component="ChatMessage"
      data-is-mobile-device={isMobileDevice}
      data-is-app-response={!!app}
    >
      <div className="chat-body">
        {(
          avatar
            ? <div className="chat-avatar image">
                <img src={avatar} />
              </div>
            : <div className={app ? `chat-avatar app` : `chat-avatar`}>
                {(name[0] || "A").toUpperCase()}
              </div>
        )}
        <div className="chat-content">
          {isTyping && (
            <div className="chat-typing">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          )}
          {!isTyping && (
            <div className="chat-message-container">
              <div className="chat-author">
                <div className="chat-author-name">
                  {name}
                  {app && <span className="app">AI</span>}
                </div>
                <div className="chat-time">{formatDate(time)}</div>
                {notification && (
                  <div className="chat-notification">
                    <AlertTriangle />
                    Rate limited
                    <ArrowDown />
                    Decreased intelligence
                  </div>
                )}
                {is_text_warned && (
                  is_text_filtered 
                    ? <div className="chat-notification">
                        <ShieldOff />
                        Content filtered
                      </div>
                    : <div className="chat-notification">
                        <Shield />
                        Content warning
                      </div>
                )}
                {is_text_blocked && (
                  <div className="chat-notification blocked">
                    <Slash />
                    Blocked
                  </div>
                )}
              </div>
              {sql && (
                <div className="sql">
                  <div className="query-container" onClick={() => setIsExpanded(!isExpanded)}>
                    <span className="expand-icon">
                      {isExpanded ? <ChevronDown size={14} /> : <ChevronRight size={14} />}
                    </span>
                    <Database />
                    <pre className="query">{sql.query}</pre>
                  </div>
                  {isExpanded && (
                    <pre className="result">{JSON.stringify(sql.result, null, 2)}</pre>
                  )}
                </div>
              )}
              {functions && functions.length > 0 && (
                functions.map((f, i) => {
                  let args = f.arguments;
                  let result = f.result;
                  try {
                    args = JSON.stringify(JSON.parse(args), null, 2);
                  } catch (e) {
                    // do nothing
                  }
                  try {
                    result = JSON.stringify(JSON.parse(result), null, 2);
                  } catch (e) {
                    // do nothing
                  }
                  const isExpanded = expandedFunctions.includes(i);
                  return (
                    <div className="function" key={i}>
                      <div className="function-header" onClick={() => {
                        setExpandedFunctions(prev => 
                          isExpanded 
                            ? prev.filter(idx => idx !== i)
                            : [...prev, i]
                        );
                      }}>
                        <span className="expand-icon">
                          {isExpanded ? <ChevronDown size={14} /> : <ChevronRight size={14} />}
                        </span>
                        <span className="function-name">
                          <Package />
                          <InternalLink to={`${f.package.url}/${f.filename}`} target="_blank">
                            {f.package.name}/{f.package.version}/{f.filename}
                          </InternalLink>
                        </span>
                      </div>
                      {isExpanded && (
                        <div className="function-body">
                          <pre>{args}</pre>
                          <pre>{result}</pre>
                        </div>
                      )}
                    </div>
                  )
                })
              )}
              <div className="chat-message">
                {markdown}
                {/* Deprecated for now: shows up in main chat box */}
                {/* notification && <div className="chat-notification">{notificationParts}</div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};