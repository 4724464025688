import "./CodeChat.scss";
import { formatDate } from "../../../../utils/time";
import { useEffect, useMemo, useRef } from "react";
import { MiniEditor } from "../../../../components/editor/MiniEditor";
import { AlertTriangle } from "react-feather";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Link } from "react-router-dom";
export interface ChatMessage {
  role: 'user' | 'assistant';
  status: 'pending' | 'success' | 'error';
  unique_id: string;
  created_at: string;
  avatar?: string;
  username?: string;
  events?: StreamEvent[];
  content?: {
    text?: string
  }
}

export interface StreamEvent {
  id: string | null,
  event: string;
  data: any;
  created_at: string;
  elapsed: number;
}

// Add this function to parse multiple newlines
const preserveMultipleNewlines = (text: string) => {
  return text.replace(/\n{2,}/g, match => {
    return '\n\n' + Array(match.length - 1).fill('&nbsp;').join('\n') + '\n\n';
  });
};

function CodeChatMessage({
  message
} : {
  message: ChatMessage;
}) {
  const time = new Date(message.created_at);
  const isAssistant = message.role === 'assistant';
  const isPending = message.status === 'pending';

  const text = message.content?.text || '';

  const markdown = useMemo(() => {
    return <ReactMarkdown 
      remarkPlugins={[remarkGfm]}
      components={{
        a: ({node, ...props}) => <a target="_blank" rel="noopener noreferrer" {...props} />,
        table: ({node, ...props}) => <table {...props} />,
      }}
>
      {text}
    </ReactMarkdown>
  }, [text]);
  
  return (
    <div data-component="CodeChatMessage">
      <div className="chat-body">
        <div className="chat-content">
          <div className="chat-author">
            {(
              message.avatar
                ? <div className="chat-avatar image">
                    <img src={message.avatar} />
                  </div>
                : <div className={isAssistant ? `chat-avatar app` : `chat-avatar`}>
                    {((message.username || message.role)[0] || "A").toUpperCase()}
                  </div>
            )}
            <div className="chat-author-name">
              {message.username || (isAssistant ? 'Assistant' : 'User')}
              {isAssistant && <span className="app">AI</span>}
            </div>
            <div className="chat-time">{formatDate(time)}</div>
          </div>
          {message.events && message.events.length > 0 && (
            <div className="chat-events">
              {message.events.map((event, index) => {
                let file;
                if (
                  event.data &&
                  typeof event.data === 'object' &&
                  event.data.hasOwnProperty('value') &&
                  event.data.hasOwnProperty('type')
                ) {
                  file = event.data;
                }
                return (
                  <div key={event.id || index} className="chat-event" data-event={event.event}>
                    {event.event === '@result' && (
                      <MiniEditor
                        label="Result"
                        copyable={true}
                        value={file || JSON.stringify(event.data, null, 2)}
                        language="javascript"
                        showLines={false}
                        readonly={true}
                      />
                    )}
                    {event.event === '@error' && (
                      <>
                        {typeof event.data === 'string' && <span className="chat-event-entry">{event.data}</span>}
                        {typeof event.data === 'object' && (
                          <MiniEditor
                            icon={AlertTriangle}
                            type="error"
                            label={event.data.type || 'Error'}
                            copyable={true}
                            value={JSON.stringify(event.data, null, 2)}
                            language="json"
                            showLines={false}
                            readonly={true}
                          />
                        )}
                      </>
                    )}
                    {event.event !== '@result' && event.event !== '@error' && (
                      <>
                        <span className="chat-event-type">{event.event}&gt;</span>
                        <span className="chat-event-entry">
                          {typeof event.data === 'string' ? event.data : JSON.stringify(event.data, null, 2)}
                        </span>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {text && (
            <div className="chat-message">
              {markdown}
            </div>
          )}
          {isPending && (
            <div className="chat-typing">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function CodeChat({
  messages,
} : {
  messages: ChatMessage[];
}) {
  const chatRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Hack: Double this up for dev mode -- also works for production
    window.requestAnimationFrame(() => {
      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }
      window.requestAnimationFrame(() => {
        if (chatRef.current) {
          chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
      });
    });
  }, [messages]);
  return (
    <div data-component="CodeChat" ref={chatRef}>
      <div className="description">
        <div>
          <mark className="unclickable">@Gem</mark> &mdash; Code assistant
        </div>
        <span className="spacer" />
      </div>
      {messages.map(chatMessage => {
        return (
          <CodeChatMessage
            message={chatMessage}
            key={`${chatMessage.unique_id}-${chatMessage.events?.length || 0}`}
          />
        );
      })}
    </div>
  );
}