
import { Link } from "react-router-dom";
import "./ConstructionWarning.scss";

export function ConstructionWarning({ center = false }: { center?: boolean }) {
  return (
    <div data-component="ConstructionWarning" data-center={center}>
      <div className="construction-warning-content">
        <img src="/assets/baby_sprite.gif" />
        <span>
          We're nurturing this feature as a beta preview.
          Get started with our developer platform at <Link to="https://github.com/functme/cli">github.com/functme/cli</Link>.
        </span>
      </div>
    </div>
  );
}