import React, { createContext, useContext, useState, ReactNode } from "react";
import { Toast, ToastType } from "./Toast";
import "./ToastContainer.scss";
import { Icon } from "react-feather";

interface ToastItem {
  id: string;
  type: ToastType;
  icon?: Icon;
  message: string;
  duration?: number;
}

interface ToastContextType {
  showToast: (toast: Omit<ToastItem, "id">) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export function ToastProvider({ children }: { children: ReactNode }) {
  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const showToast = ({ type, icon, message, duration }: Omit<ToastItem, "id">) => {
    const id = Date.now().toString() + Math.random().toString(16).substring(2);
    setToasts((prev) => [...prev, { id, type, icon, message, duration }]);
  };

  const dismissToast = (id: string) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <div data-component="ToastContainer" className="toast-container">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            id={toast.id}
            type={toast.type}
            icon={toast.icon}
            message={toast.message}
            duration={toast.duration}
            onDismiss={dismissToast}
          />
        ))}
      </div>
    </ToastContext.Provider>
  );
}

export function useToast() {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
} 