import { MouseEventHandler } from 'react';
import './Toggle.scss';

export default function Toggle({
  label,
  value = false,
  size = "medium",
  type = "default",
  color = "default",
  disabled = false,
  onClick = () => {}
} : {
  label?: string,
  value?: boolean,
  size?: "small" | "medium" | "large",
  type?: "default" | "inverse",
  color?: "default" | "special" | "blurple" | "green" | "red" | "orange" | "blue"
  disabled?: boolean,
  onClick?: MouseEventHandler
}) {
  return (
    <div data-component="Toggle" data-size={size} data-color={color} data-type={type}>
      <label data-disabled={disabled} data-value={value}>
        {label && (
          <span>{label}</span>
        )}
        <span className="toggle-container">
          <span className="toggle-background">
            <span>
              ON
            </span>
            <span>
              OFF
            </span>
          </span>
          <button onClick={onClick}>
            <span className="special-border">
              <span className="overflow" />
            </span>
            <span className="toggle-content"></span>
          </button>
        </span>
      </label>
    </div>
  );
}
