import { useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import {
  CreditCard
} from 'react-feather';
import { useAuth } from "../../../../context/AuthContext";
import { formatCredits, formatCurrency } from "../../../../utils/format";
import Button from "../../../../components/button/Button";
import Select from "../../../../components/select/Select";
import API from "../../../../utils/api";
import { ExtendedHTMLSelectElement } from "../../../../components/select/Select";

import "./BillingCreditsPage.scss";

export default function BillingCreditsPage() {

  const { organization, wallet } = useAuth();
  const [ searchParams ] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  const selectRef = useRef<ExtendedHTMLSelectElement>(null);
  const prices = [1000, 2000, 5000, 10000, 25000, 50000, 100000];
  const priceOptions = prices.map(price => {
    return {label: `${formatCurrency(price)} USD`, value: price};
  });

  const purchaseCredits = async () => {
    if (!selectRef.current) {
      return;
    }
    const option = selectRef.current.option;
    if (option) {
      setIsLoading(true);
      const amount = option.value;
      const purchaseResult = await API.post(
        'v1/purchases',
        {organization: organization.name, amount}
      );
      setIsLoading(false);
      if (purchaseResult?.url) {
        window.location = purchaseResult.url;
      }
    }
  };

  const showSuccess = searchParams.has('success');
  const showFailure = searchParams.has('cancel');

  return (
    <div data-component="BillingCreditsPage">
      <div className="content">
        {showSuccess && (
          <div className="complete-message success">
            Success! Your balance has been updated.
          </div>
        )}
        {!showSuccess && showFailure && (
          <div className="complete-message failure">
            Oops, we couldn't process your payment.<br />
            Please try again.
          </div>
        )}
        <div className="balance-card">
          <div className="balance-details">
            <div className="details-header">Balance</div>
            <div className="my-balance">
              {formatCredits(wallet.microcredit_balance)}
            </div>
          </div>
          <hr />
          <div className="purchase-credits">
            <div className="details-header">Purchase function credits</div>
            <div className="add-dropdown">
              <Select
                options={priceOptions}
                ref={selectRef}
                />
              <Button
                icon={CreditCard}
                color="green"
                label="Purchase credits"
                loading={isLoading}
                onClick={() => purchaseCredits()}
                />
            </div>
          </div>
        </div>  
      </div>
    </div>
  );

};