import { useState, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { AgentSchema, DiscordLinkSchema, StandaloneDiscordLinkSchema } from "../../../../utils/types";
import { Trash, AlignLeft, Link2, PlusCircle, X, Check, Settings, ExternalLink, ArrowDown } from 'react-feather';
import Button from "../../../../components/button/Button";
import ImageUploader from "../../../../components/uploader/ImageUploader"; 
import Textbox from "../../../../components/textbox/Textbox";
import API, { ErrorWithDetails } from "../../../../utils/api";
import { getTimeDelta, formatRecent } from "../../../../utils/time";
import { AlertModal } from "../../../../components/modals/AlertModal";
import { ReactComponent as DiscordLogo } from "../../../../svg/discord-mark-white.svg";
import "./AgentsSettings.scss";
import { InternalLink } from "../../../../components/internal-link/InternalLink";
import { ContentButton } from "../../../../components/button/ContentButton";
import { useAuth } from "../../../../context/AuthContext";

interface Props {
  agent: AgentSchema;
  onAgentUpdated: (agent: AgentSchema) => void;
  onAgentArchived: (id: string) => void;
}

export function AgentsSettings({ agent, onAgentUpdated, onAgentArchived }: Props) {

  const { organization } = useAuth();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const [ viewAgent, setViewAgent ] = useState<AgentSchema>(agent);
  const [ botName, setBotName ] = useState(agent.agentConfigs?.[0]?.name || "");
  const [ botPrompt, setBotPrompt ] = useState(agent.agentConfigs?.[0]?.system_prompt || "");
  const [ updatedBotImageUrl, setUpdatedBotImageUrl ] = useState<string | null | false>(null);
  const [ contentFilterDisabled, setContentFilterDisabled ] = useState(agent.agentConfigs?.[0]?.content_filter_settings === "disabled");

  const [ isSaving, setIsSaving ] = useState(false);
  const [ isArchiving, setIsArchiving ] = useState(false);
  const [ isRemovingCode, setIsRemovingCode ] = useState(false);
  const [ isDeletingDatabase, setIsDeletingDatabase ] = useState(false);
  const [ alertMessage, setAlertMessage ] = useState('');
  const [ showArchiveAlert, setShowArchiveAlert ] = useState(false);
  const [ showRemoveCodeAlert, setShowRemoveCodeAlert ] = useState(false);
  const [ showDeleteDatabaseAlert, setShowDeleteDatabaseAlert ] = useState(false);
  const [ unlinkingDiscordLink, setUnlinkingDiscordLink ] = useState<StandaloneDiscordLinkSchema | null>(null);

  const [ isLinking, setIsLinking ] = useState(false);
  const [ isUnlinkingById, setIsUnlinkingById ] = useState<{[key: string]: boolean}>({});

  const linkError = searchParams.get('link_error');
  const [ error, setError ] = useState<ErrorWithDetails | null>(linkError ? new Error(linkError) : null);

  const refetchAgent = async () => {
    try {
      const agentResult = await API.get('v1/agents/retrieve', { id: agent.unique_id });
      setViewAgent(agentResult as AgentSchema);
      onAgentUpdated(agentResult as AgentSchema);
    } catch (e) {
      setError(e as ErrorWithDetails);
    }
  };

  const archiveAgent = async () => {
    const id = agent.unique_id;
    setIsArchiving(true);
    try {
      const unlinkResponse = await API.del('v1/agents', { id });
      if (unlinkResponse) {
        onAgentArchived(id);
      }
    } catch (e) {
      setError(e as ErrorWithDetails);
    }
    setIsArchiving(false);
  };

  const createDiscordLink = async () => {
    setIsLinking(true);
    try {
      const linkResult = await API.post(
        'v1/discord_links',
        {
          organization: organization.name,
          agent_id: agent.unique_id,
          successPath: `/chat/${agent.unique_id}${searchParams.has('profile') ? '?profile=t' : ''}`
        }
      );
      const url = linkResult?.url;
      if (url) {
        window.location = url;
      }
    } catch (e) {
      setError(e as ErrorWithDetails);
      setIsLinking(false);
    }
  };

  const unlinkDiscord = async (discordLink: StandaloneDiscordLinkSchema) => {
    setIsUnlinkingById(isUnlinkingById => ({...isUnlinkingById, [discordLink.unique_id]: true}));
    try {
      const unlinkResponse = await API.del('v1/discord_links', { id: discordLink.unique_id });
      if (unlinkResponse) {
        await refetchAgent();
      }
    } catch (e) {
      setError(e as ErrorWithDetails);
    }
    setIsUnlinkingById(isUnlinkingById => {
      const newState = { ...isUnlinkingById };
      delete newState[discordLink.unique_id];
      return newState;
    });
  };

  const updateAgent = async (
    {
      content_filter_settings = null,
      image = null
    } :
    {
      content_filter_settings?: "disabled" | "enabled" | null,
      image?: string | null | false
    } = {}
  ) => {
    if (!viewAgent || isSaving) return;
    
    setIsSaving(true);
    try {
      const agentResult = await API.put(
        "v1/agents",
        {
          id: viewAgent.unique_id,
          name: botName,
          prompt: botPrompt,
          image: image
            ? {_base64: image}
            : image,
          content_filter_settings
        }
      );
      setViewAgent(agentResult as AgentSchema);
      setContentFilterDisabled(agentResult?.agentConfigs?.[0]?.content_filter_settings === "disabled");
      setUpdatedBotImageUrl(null);
      setError(null);
      onAgentUpdated(agentResult as AgentSchema);
    } catch (e) {
      setError(e as ErrorWithDetails);
    }
    setIsSaving(false);
  };

  const removeCode = async () => {
    setIsRemovingCode(true);
    try {
      await API.del('v1/packages', { 
        agent_id: agent.unique_id,
        environment: 'development' 
      });
      await refetchAgent();
    } catch (e) {
      setError(e as ErrorWithDetails);
    }
    setIsRemovingCode(false);
  };

  const deleteDatabase = async () => {
    setIsDeletingDatabase(true);
    try {
      const result = await API.del('v1/sqlite_databases', { 
        agent_id: agent.unique_id
      });
      await refetchAgent();
    } catch (e) {
      console.error("Error deleting database:", e);
      setError(e as ErrorWithDetails);
    }
    setIsDeletingDatabase(false);
  };

  useEffect(() => {
    setViewAgent(agent);
    setBotName(agent.agentConfigs?.[0]?.name || "");
    setBotPrompt(agent.agentConfigs?.[0]?.system_prompt || "");
    setContentFilterDisabled(agent.agentConfigs?.[0]?.content_filter_settings === "disabled");
    setUpdatedBotImageUrl(null);
  }, [agent]);

  useEffect(() => {
    searchParams.delete('link_error');
    setSearchParams(searchParams);
  }, [linkError])

  return (
    <>
      {alertMessage && (
        <AlertModal
          message={alertMessage}
          onConfirm={() => setAlertMessage('')}
        />
      )}
      {showArchiveAlert && (
        <AlertModal
          message="Archive this agent? This action cannot be undone."
          onClose={() => setShowArchiveAlert(false)}
          onConfirm={async (complete) => {
            await archiveAgent();
            complete();
          }}
          onCancel={() => {}}
        />
      )}
      {showRemoveCodeAlert && (
        <AlertModal
          message="Remove code package from this agent? This action cannot be undone."
          onClose={() => setShowRemoveCodeAlert(false)}
          onConfirm={async (complete) => {
            await removeCode();
            complete();
            setShowRemoveCodeAlert(false);
          }}
          onCancel={() => {}}
        />
      )}
      {showDeleteDatabaseAlert && (
        <AlertModal
          message="Delete the database for this agent? This action cannot be undone."
          onClose={() => setShowDeleteDatabaseAlert(false)}
          onConfirm={async (complete) => {
            await deleteDatabase();
            complete();
            setShowDeleteDatabaseAlert(false);
          }}
          onCancel={() => {}}
        />
      )}
      {unlinkingDiscordLink && (
        <AlertModal
          message={`Unlink Discord server "${unlinkingDiscordLink.discord_guild_metadata.name}"?`}
          onClose={() => setUnlinkingDiscordLink(null)}
          onConfirm={async (complete) => {
            await unlinkDiscord(unlinkingDiscordLink);
            complete();
            setUnlinkingDiscordLink(null);
          }}
          onCancel={() => setUnlinkingDiscordLink(null)}
        />
      )}
      <div data-component="AgentsSettings">
        <div className="settings">
          <div className="edit-avatar">
            <ImageUploader
              loading={isSaving}
              image={viewAgent?.agentConfigs?.[0]?.profile_image_url}
              onChange={base64Data => {
                // setUpdatedBotImageUrl(base64Data);
                updateAgent({ image: base64Data });
              }}
            />
          </div>
          <div className="agent-name">
            <Textbox
              clickToEdit
              value={botName}
              placeholder="Enter agent name"
              onChange={botName => setBotName(botName)}
              loading={isSaving}
              onSubmit={() => {
                if (botName !== (viewAgent?.agentConfigs[0].name|| "")) {
                  updateAgent()
                }
              }}
              onCancel={() => {
                setBotName(viewAgent?.agentConfigs[0].name || "");
              }}
            />
          </div>
        </div>
        {error && (
          <div className="agents-error">
            <div className="form-row error">
              <span>{error.message}</span>
              <div className="spacer" />
              <ContentButton
                icon={X}
                onClick={() => setError(null)}
              />
            </div>
          </div>
        )}
        <div className="agent-scroll-container">
          <div className="agent-prompt">
            <div className="agent-prompt-heading">
              <AlignLeft /> Instruction prompt
            </div>
            <Textbox
              clickToEdit
              type="multiline"
              lines={3}
              maxLines={8}
              value={botPrompt}
              onChange={botPrompt => setBotPrompt(botPrompt)}
              loading={isSaving}
              onSubmit={() => {
                if (botPrompt !== (viewAgent?.agentConfigs[0].system_prompt || "")) {
                  updateAgent()
                }
              }}
              onCancel={() => {
                setBotPrompt(viewAgent?.agentConfigs[0].system_prompt || "");
              }}
            />
          </div>
          
          {organization.content_filter_settings === "disabled" &&
          ('content_filter_settings' in viewAgent?.agentConfigs?.[0]) && 
          (
            <div className="connections-section">
              <div className="connections-section-heading">
                <Settings /> Settings
              </div>
              <div className="discord-links">
                <div className="agent-settings-row">
                  <div className={`settings-label settings-${contentFilterDisabled ? `disabled` : `enabled`}`}>
                    {!contentFilterDisabled ? <Check /> : <X />}
                    Content filter
                  </div>
                  <div className="settings-button">
                    <ContentButton
                      loading={isSaving}
                      darken={true}
                      onClick={async () => {
                        await updateAgent({ content_filter_settings: contentFilterDisabled ? "enabled" : "disabled" });
                      }}
                    >
                      {contentFilterDisabled ? "Enable" : "Disable"}
                    </ContentButton>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          <div className="connections-section">
            <div className="connections-section-heading">
              <Link2 /> Connections
            </div>
            <div className="discord-links">
              {viewAgent?.discordAgents?.map(discordAgent => {
                const discordLink = discordAgent.discordLink;
                const avatarUrl = discordLink.discord_guild_metadata.icon
                  ? `https://cdn.discordapp.com/icons/${discordLink.discord_guild_id}/${discordLink.discord_guild_metadata.icon}.png`
                  : null;
                const initials = discordLink.discord_guild_metadata.name
                  .split(/\b/)
                  .map((name: string) => name[0])
                  .join('')
                  .replaceAll(' ', '');

                return (
                  <div className="discord-link-entry" key={discordLink.unique_id}>
                    {avatarUrl && (
                      <div className="icon avatar">
                        <img src={avatarUrl} alt="" />
                      </div>
                    )}
                    {!avatarUrl && (
                      <div className="icon avatar">
                        {initials}
                      </div>
                    )}
                    
                    <div className="info">
                      <InternalLink to={`https://discord.com/channels/${discordLink.discord_guild_id}`} target="_blank">
                        {discordLink.discord_guild_metadata.name}&nbsp;
                        <ExternalLink/>
                      </InternalLink>
                      {/* &nbsp;as&nbsp;
                      <strong>{discordLink.discord_bot_metadata?.nick || '(unknown)'}</strong> */}
                    </div>
                    
                    <div className="spacer" />
                    <div data-tooltip="Unlink">
                      <ContentButton
                        icon={Trash}
                        onClick={() => setUnlinkingDiscordLink(discordLink)}
                        loading={isUnlinkingById[discordLink.unique_id]}
                      />
                    </div>
                    {discordLink.discord_bot_metadata?.update_rate_limited_until && (
                      getTimeDelta(discordLink.discord_bot_metadata.update_rate_limited_until).sign === -1
                        ? <div className="warn">
                            unsynced, wait {formatRecent(discordLink.discord_bot_metadata.update_rate_limited_until).replace('in ', '')}
                          </div>
                        : <div className="warn">save to resync</div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="connections-actions">
              <ContentButton
                icon={PlusCircle}
                onClick={createDiscordLink}
                loading={isLinking}
              >
                Link a new Discord server
              </ContentButton>
            </div>
          </div>
        </div>
        <div className="actions">
          <div className="spacer" />
          {viewAgent?.agentDbs?.[0]?.sqliteDatabase && (
            <Button 
              onClick={() => setShowDeleteDatabaseAlert(true)}
              icon={Trash}
              label="Delete database"
              size="small"
              loading={isDeletingDatabase}
            />
          )}
          {viewAgent?.package?.packageVersions?.some(version => 
            version.packageDeployments?.some(deployment => deployment.is_active)
          ) && (
            <Button 
              onClick={() => setShowRemoveCodeAlert(true)}
              icon={ArrowDown}
              label="Remove code"
              size="small"
              loading={isRemovingCode}
            />
          )}
          <Button 
            onClick={() => setShowArchiveAlert(true)}
            icon={Trash}
            label="Archive agent"
            size="small"
            loading={isArchiving}
          />
        </div>
      </div>
    </>
  );
}