import { LoaderFunctionArgs } from "react-router-dom";
import { SubscriptionPlanSchema } from "../../../../utils/types";
import API from "../../../../utils/api";

export interface BillingSubscriptionPageLoaderData {
  plans: SubscriptionPlanSchema[]
};

export default function BillingSubscriptionPageLoader() {
  return async ({ params } : LoaderFunctionArgs) => {
    return await API.get('payments/plans') as BillingSubscriptionPageLoaderData;
  };
};