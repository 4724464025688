import { useState, useEffect } from "react";
import { AgentSchema, ApiKeychainSchema } from "../../../../utils/types";
import { X, Edit2, Check, Trash2, Key, Plus, Save, Edit, ChevronRight, ChevronDown, Info } from 'react-feather';
import API from "../../../../utils/api";
import "./AgentsKeychain.scss";
import Textbox from "../../../../components/textbox/Textbox";
import { ContentButton } from "../../../../components/button/ContentButton";
import { AlertModal } from "../../../../components/modals/AlertModal";
import { MiniEditor } from "../../../../components/editor/MiniEditor";
import { InternalLink } from "../../../../components/internal-link/InternalLink";
import { InfoBox } from "../../../../components/info/InfoBox";

type KeyItem = {
  name: string;
  value: string;
  isNew?: boolean;
  isEditing: boolean;
};

export function AgentsKeychain({
  agent,
  onAgentUpdated
}: {
  agent: AgentSchema,
  onAgentUpdated: (agent: AgentSchema) => void
}) {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [keyItems, setKeyItems] = useState<KeyItem[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [deletingIndex, setDeletingIndex] = useState<number | null>(null);

  // Initialize or update keyItems from agent data
  useEffect(() => {
    const keys = agent.apiKeychain.keychainConfigs?.[0]?.keys || {};
    setKeyItems(Object.keys(keys).map(name => ({
      name,
      value: '', // Values are hidden by default
      isEditing: false
    })));
  }, [agent.apiKeychain.keychainConfigs]);

  const fetchKeyValue = async (name: string) => {
    setIsLoading(true);
    try {
      const keychain = await API.get(
        `v1/api_keychains/details`,
        {
          id: agent.apiKeychain.unique_id
        }
      );
      const keys = keychain?.keychainConfigs?.[0]?.keys || {};
      return keys[name] || '';
    } catch (e) {
      console.error(e);
      return '';
    } finally {
      setIsLoading(false);
    }
  };

  const saveKeyValue = async (name: string, value: string) => {
    setIsSaving(true);
    try {
      // First we get current details
      const keychainDetails = await API.get(
        `v1/api_keychains/details`,
        {
          id: agent.apiKeychain.unique_id
        }
      );
      const keys = keychainDetails?.keychainConfigs?.[0]?.keys || {};
      keys[name] = value;
      
      const keychain = await API.put('v1/api_keychains', {
        id: agent.apiKeychain.unique_id,
        keys
      });
      agent.apiKeychain = keychain as ApiKeychainSchema;
      onAgentUpdated(agent);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      setIsSaving(false);
    }
  };

  const deleteKeyValue = async (name: string) => {
    setIsSaving(true);
    try {
      // First we get current details
      const keychainDetails = await API.get(
        `v1/api_keychains/details`,
        {
          id: agent.apiKeychain.unique_id
        }
      );
      const keys = keychainDetails?.keychainConfigs?.[0]?.keys || {};
      delete keys[name];
      
      const keychain = await API.put('v1/api_keychains', {
        id: agent.apiKeychain.unique_id,
        keys
      });
      agent.apiKeychain = keychain as ApiKeychainSchema;
      onAgentUpdated(agent);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (index: number) => {
    const item = keyItems[index];
    
    // If it's not a new item, fetch its value
    if (!item.isNew) {
      const value = await fetchKeyValue(item.name);
      
      // Update this item to editing mode with its value
      setKeyItems(items => items.map((item, i) => 
        i === index ? {...item, value, isEditing: true} : item
      ));
    } else {
      // Just set this item to editing mode
      setKeyItems(items => items.map((item, i) => 
        i === index ? {...item, isEditing: true} : item
      ));
    }
  };

  const handleSave = async (index: number) => {
    const item = keyItems[index];
    if (!item.name) return;
    
    // Check for duplicate key names if this is a new key
    if (item.isNew) {
      const existingKeyIndex = keyItems.findIndex((k, i) => 
        i !== index && k.name === item.name && !k.isNew
      );
      
      if (existingKeyIndex !== -1) {
        setErrorMessage(`A key with name "${item.name}" already exists. Please choose a different name.`);
        return;
      }
    }
    
    const success = await saveKeyValue(item.name, item.value);
    if (success) {
      // Update item to no longer be in editing mode and not new
      setKeyItems(items => items.map((item, i) => 
        i === index ? {...item, isEditing: false, isNew: false} : item
      ));
    }
  };

  const handleCancel = (index: number) => {
    const item = keyItems[index];
    
    // If this is a new empty item, remove it
    if (item.isNew && !item.name) {
      setKeyItems(items => items.filter((_, i) => i !== index));
    } else {
      // Otherwise just turn off editing
      setKeyItems(items => items.map((item, i) => 
        i === index ? {...item, isEditing: false} : item
      ));
    }
  };

  const confirmDelete = (index: number) => {
    setDeletingIndex(index);
  };

  const handleDelete = async () => {
    if (deletingIndex === null) return;
    
    const index = deletingIndex;
    const item = keyItems[index];
    
    // Set deleting index back to null to close modal
    setDeletingIndex(null);
    
    // Remove the item from our array
    setKeyItems(items => items.filter((_, i) => i !== index));
    
    // If it's a new unsaved item, we're done
    if (item.isNew) return;
    
    // Otherwise delete from API
    await deleteKeyValue(item.name);
  };

  const handleAddNewKey = () => {
    setKeyItems([
      ...keyItems,
      {
        name: '',
        value: '',
        isNew: true,
        isEditing: true
      }
    ]);
  };

  const updateKeyName = (index: number, name: string) => {
    setKeyItems(items => items.map((item, i) => 
      i === index ? {...item, name} : item
    ));
  };

  const updateKeyValue = (index: number, value: string) => {
    setKeyItems(items => items.map((item, i) => 
      i === index ? {...item, value} : item
    ));
  };

  return (
    <div data-component="AgentsKeychain">
      {errorMessage && (
        <AlertModal
          message={errorMessage}
          confirmLabel="OK"
          onConfirm={(complete) => {
            setErrorMessage(null);
            complete();
          }}
          onClose={() => setErrorMessage(null)}
        />
      )}
      
      {deletingIndex !== null && keyItems[deletingIndex] && (
        <AlertModal
          message={`Are you sure you want to delete the key "${keyItems[deletingIndex].name}"? Any packages using this key will stop working.`}
          type="confirm"
          confirmLabel="Delete"
          color="red"
          onConfirm={(complete) => {
            handleDelete();
            complete();
          }}
          onCancel={() => setDeletingIndex(null)}
          onClose={() => setDeletingIndex(null)}
        />
      )}

      <div className="keychain-primer">
        <InfoBox>
          Secrets stored here are used to authenticate your agent with third-party services.<br />
          They can be accessed in package code via <code>context.keychain.key("KEY_NAME")</code>
        </InfoBox>
      </div>
      
      <div className="keychain-list">
        <div className="keychain-rows">
          {!keyItems.length && (
            <div className="key-empty">
              No keys in your keychain yet.
            </div>
          )}
          {keyItems.map((item, index) => (
            <div key={index} className="key-row">
              <div className="key-name">
                <Key className="icon" />
                <Textbox
                  size="small"
                  placeholder="KEY_NAME"
                  value={item.name}
                  monospace={true}
                  onChange={value => updateKeyName(index, value)}
                  disabled={!item.isEditing || !item.isNew}
                />
              </div>
              <div className="key-value">
                <Textbox
                  size="small"
                  placeholder="sk_123..."
                  type={item.isEditing ? "text" : "password"}
                  value={item.isEditing ? item.value : "••••••••••••••••"}
                  monospace={true}
                  onChange={value => updateKeyValue(index, value)}
                  onSubmit={() => item.isEditing && handleSave(index)}
                  disabled={!item.isEditing}
                />
              </div>
              <div className="key-actions">
                {item.isEditing ? (
                  <>
                    <ContentButton
                      icon={Save}
                      color="orange"
                      disabled={!item.name}
                      onClick={() => handleSave(index)}
                      loading={isSaving}
                    />
                    <ContentButton
                      darken={true}
                      icon={X}
                      onClick={() => handleCancel(index)}
                    />
                  </>
                ) : (
                  <>
                    <ContentButton
                      darken={true}
                      icon={Edit}
                      loading={isLoading && keyItems[index].isEditing}
                      onClick={() => handleEdit(index)}
                    />
                    <ContentButton
                      darken={true}
                      icon={Trash2}
                      loading={isSaving && deletingIndex === index}
                      onClick={() => confirmDelete(index)}
                    />
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        
        <div className="add-key-container">
          <ContentButton
            darken={true}
            icon={Plus}
            onClick={handleAddNewKey}
          >
            Add new key
          </ContentButton>
        </div>
      </div>
    </div>
  );
}