import { useState } from 'react';
import { X, Check } from 'react-feather';
import Button from '../../components/button/Button';
import Textbox from '../../components/textbox/Textbox';
import './InsertRowEditor.scss';

interface InsertRowEditorProps {
  tableName: string;
  columns: Array<{
    name: string;
    type: string;
  }>;
  onSave: (row: Record<string, any>) => void;
  onClose: () => void;
}

export function InsertRowEditor({ tableName, columns, onSave, onClose }: InsertRowEditorProps) {

  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState<Record<string, string>>({});
  
  const handleSave = async () => {
    setIsLoading(true);
    // Filter out the ID column and empty values
    const row = Object.fromEntries(
      Object.entries(values)
        .filter(([key, value]) => key !== 'id' && value !== '')
    );
    await onSave(row);
    setIsLoading(false);
  };

  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  return (
    <div data-component="InsertRowEditor">
      <div className="modal-overlay" onMouseDown={handleClose}>
        <div className="modal-content" onMouseDown={e => e.stopPropagation()}>
          <div className="modal-toolbar">
            <h3>Insert row into {tableName}</h3>
            <button className="close-button" onClick={handleClose}>
              <X size={20} />
            </button>
          </div>
          
          <div className="modal-body">
            <div className="form-group">
              {columns.filter(col => col.name !== 'id').map(column => (
                <div key={column.name} className="field">
                  <Textbox
                    size="small"
                    value={values[column.name] || ''}
                    placeholder={column.name}
                    heading={`${column.name} (${column.type})`}
                    onChange={value => setValues(prev => ({
                      ...prev,
                      [column.name]: value
                    }))}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="modal-footer">
            <Button
              label="Cancel"
              icon={X}
              size="small"
              disabled={isLoading}
              onClick={handleClose}
            />
            <Button
              label="Insert row"
              icon={Check}
              color="blue"
              size="small"
              loading={isLoading}
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
