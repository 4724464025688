import { LoaderFunctionArgs } from "react-router-dom";
import { PaymentMethodSchema } from "../../../../utils/types";
import API from "../../../../utils/api";

export interface BillingMethodsPageLoaderData {
  paymentMethods: PaymentMethodSchema[]
};

export default function BillingMethodsPageLoader() {
  return async ({ params } : LoaderFunctionArgs) => {
    return await API.get('payments/payment_methods') as BillingMethodsPageLoaderData;
  };
};