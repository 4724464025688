import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const MOBILE_SCREEN_BREAKPOINT = 800;
const TABLET_SCREEN_BREAKPOINT = 1100;

const isMobileDevice = () => {
  // Check for common mobile device identifiers in the user agent string
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Takes in an optional search param that it will delete
 * when the screen size is no longer mobile.
 * @param searchParam 
 * @returns 
 */
export function useMobileScreenView(searchParam?: string) {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ isMobileScreenView, setIsMobileScreenView ] = useState(window.innerWidth < MOBILE_SCREEN_BREAKPOINT);
  useEffect(() => {
    const checkSize = () => {
      const newIsMobileScreenView = window.innerWidth < MOBILE_SCREEN_BREAKPOINT;
      if (newIsMobileScreenView !== isMobileScreenView) {
        setIsMobileScreenView(newIsMobileScreenView);
        if (!newIsMobileScreenView && searchParam) {
          searchParams.delete(searchParam);
          setSearchParams(searchParams);
        }
      }
    };
    window.addEventListener('resize', checkSize);
    return () => window.removeEventListener('resize', checkSize);
  }, [isMobileScreenView]);
  return isMobileScreenView;

};

export function useTabletScreenView(searchParam?: string) {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ isMobileScreenView, setIsMobileScreenView ] = useState(window.innerWidth < TABLET_SCREEN_BREAKPOINT);
  useEffect(() => {
    const checkSize = () => {
      const newIsMobileScreenView = window.innerWidth < TABLET_SCREEN_BREAKPOINT;
      if (newIsMobileScreenView !== isMobileScreenView) {
        setIsMobileScreenView(newIsMobileScreenView);
        if (!newIsMobileScreenView && searchParam) {
          searchParams.delete(searchParam);
          setSearchParams(searchParams);
        }
      }
    };
    window.addEventListener('resize', checkSize);
    return () => window.removeEventListener('resize', checkSize);
  }, [isMobileScreenView]);
  return isMobileScreenView;
};

export function useMobileDevice() {
  const [ isMobile, setIsMobile ] = useState(isMobileDevice());
  useEffect(() => {
    const checkDevice = () => setIsMobile(isMobileDevice());
    window.addEventListener('resize', checkDevice);
    return () => {
      window.removeEventListener('resize', checkDevice);
    }
  }, []);
  return isMobile;
}