import { Loader } from 'react-feather';
import { useEffect, useRef } from 'react';

import './PageLoading.scss';

const NAV_DURATION = 2_000;
const NAV_COMPLETION_DURATION = 100;
const calculateNavCompletion = (timeDelta: number) => {
  const pct = timeDelta / NAV_DURATION;
  const completion = Math.pow(pct, 1);
  if (completion > 0.95) {
    return 0.95;
  }
  return completion;
};

export function PageLoading() {
  return (
    <div data-component="PageLoading">
      <Loader />
      <img src="/logo/funct-logo-wordmark-filled.svg" />
    </div>
  );
}

export function PageNavigating({
  inProgress = false,
} : {
  inProgress?: boolean
}) {

  const navStartTime = useRef(0);
  const navCompletionPct = useRef(0);
  const navRenderState = useRef<'loading' | 'loading-complete' | 'complete'>('loading')
  const navLoaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inProgress) {
      navStartTime.current = Date.now();
      navRenderState.current = 'loading';
      const render = () => {
        if (navRenderState.current !== 'loading') return;
        const navLoader = navLoaderRef.current;
        if (navLoader) {
          navLoader.style.display = '';
          const timeDelta = Date.now() - navStartTime.current;
          navCompletionPct.current = calculateNavCompletion(timeDelta);
          navLoader.style.width = `${navCompletionPct.current * 100}%`;
        }
        window.requestAnimationFrame(render);
      };
      render();
    } else {
      navRenderState.current = 'loading-complete';
      navStartTime.current = Date.now();
      const initialCompletionPct = navCompletionPct.current;
      const delta = 1 - initialCompletionPct;
      const render = () => {
        if (navRenderState.current !== 'loading-complete') return;
        const navLoader = navLoaderRef.current;
        if (navLoader) {
          const timeDelta = Date.now() - navStartTime.current;
          const completionDelta = timeDelta / NAV_COMPLETION_DURATION;
          if (completionDelta > 1) {
            navCompletionPct.current = 1;
            navLoader.style.display = 'none';
            navLoader.style.width = `${navCompletionPct.current * 100}%`;
            navRenderState.current = 'complete';
          } else {
            navCompletionPct.current = initialCompletionPct + (delta * completionDelta);
            navLoader.style.width = `${navCompletionPct.current * 100}%`;
          }
        }
        window.requestAnimationFrame(render);
      };
      render();
    }
  }, [inProgress]);

  return (
    <div data-component="PageNavigating">
      <div className="navigation-loader" ref={navLoaderRef}></div>
    </div>
  );
}