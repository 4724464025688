import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, Link, useLocation } from 'react-router-dom';
import { ArrowRight, Check, ExternalLink, Star, Smile, Zap, Link2, ArrowLeft, Loader, MessageCircle } from 'react-feather';
import { ReactComponent as DiscordLogo } from '../../../svg/discord-mark-white.svg';

import { useAuth } from '../../../context/AuthContext';
import Button from '../../../components/button/Button';
import { ContentButton } from '../../../components/button/ContentButton';
import Textbox from '../../../components/textbox/Textbox';
import API from '../../../utils/api';
import { AgentSchema } from '../../../utils/types';
import DiscordLinkCard from "../../../components/cards/DiscordLinkCard";
import { AgentTradingCard } from "../../../components/cards/AgentTradingCard";

import './NewAgentFlow.scss';

// Step definitions
type StepId = 'name' | 'prompt' | 'connections' | 'finish';

interface Step {
  id: StepId;
  label: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  component: React.FC<StepComponentProps>;
}

// Component props
interface NewAgentFlowProps {
  onAgentUpdated?: (agent: AgentSchema) => void;
}

interface StepComponentProps {
  agent: AgentSchema | null;
  agentName: string;
  agentPrompt: string;
  setAgentName: (name: string) => void;
  setAgentPrompt: (prompt: string) => void;
  isSaving: boolean;
  onContinue: () => void;
  onPrevious?: () => void;
  onLinkDiscord?: () => void;
}

interface StepTrackerProps {
  steps: Step[];
  currentStepId: StepId;
  agent: AgentSchema | null;
}

// Step Components
const NameStep: React.FC<StepComponentProps> = ({
  agent,
  agentName,
  setAgentName,
  isSaving,
  onContinue
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      const input = inputRef.current;
      if (input) {
        input.focus();
        input.setSelectionRange(input.value.length, input.value.length);
      }
    }, 1);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="content">
      <div className="name-step">
        <h2>Create a new AI agent</h2>
        <p>
          What would you like to call your AI agent?<br />
          Have fun with it, you'll be able to change this at any time.
        </p>
        <div className="form-row">
          <Textbox
            ref={inputRef}
            value={agentName}
            onChange={setAgentName}
            onSubmit={onContinue}
            placeholder="Enter agent name"
          />
        </div>
        <div className="action-row">
          <div className="spacer"></div>
          <Button
            label="Continue"
            endIcon={ArrowRight}
            disabled={!agentName.trim()}
            loading={isSaving}
            onClick={onContinue}
          />
        </div>
      </div>
    </div>
  );
};

const PromptStep: React.FC<StepComponentProps> = ({
  agent,
  agentName,
  agentPrompt,
  setAgentPrompt,
  isSaving,
  onContinue,
  onPrevious
}) => {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      const input = inputRef.current;
      if (input) {
        input.focus();
        input.setSelectionRange(input.value.length, input.value.length);
      }
    }, 1);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="content">
      <div className="prompt-step">
        <h2>{agentName}: Prompt</h2>
        <p>
          How would you like your agent to behave?
          <br />
          This instruction prompt will guide its behavior
          &mdash; but don't worry, you can change it later.
        </p>
        <div className="form-row">
          <Textbox
            ref={inputRef}
            type="multiline"
            lines={6}
            maxLines={6}
            value={agentPrompt}
            onChange={setAgentPrompt}
            onSubmit={onContinue}
            placeholder="Tell your agent what it should do..."
          />
        </div>
        <div className="action-row">
          <ContentButton
            icon={ArrowLeft}
            onClick={onPrevious}
          >
            Back
          </ContentButton>
          <div className="spacer"></div>
          <Button
            label="Okay, prompt is good!"
            endIcon={ArrowRight}
            loading={isSaving}
            onClick={onContinue}
          />
        </div>
      </div>
    </div>
  );
};

const ConnectionsStep: React.FC<StepComponentProps> = ({
  agent,
  agentName,
  isSaving,
  onContinue,
  onPrevious,
  onLinkDiscord
}) => {
  const discordLink = agent?.discordAgents?.[0]?.discordLink;
  
  return (
    <div className="content">
      <div className="connections-step">
        <h2>{agentName}: Connections</h2>
        <p>
          You can chat with your agent directly from Funct
          or use it in other apps, like Discord. 
        </p>
        
        {discordLink && (
          <div className="card-container">
            <DiscordLinkCard 
              discordLink={discordLink}
              standalone={true}
            />
          </div>
        )}
        {!discordLink && (
          <div className="connections-container">
            <div className="discord-connect">
              <Button
                icon={DiscordLogo}
                label="Link to Discord"
                color="blurple"
                loading={isSaving}
                onClick={onLinkDiscord}
              />
              <span className="connect-info">
                Your agent will be available as a bot in your Discord server.
              </span>
            </div>
          </div>
        )}
        
        <div className="action-row">
          <ContentButton
            icon={ArrowLeft}
            onClick={onPrevious}
          >
            Back
          </ContentButton>
          <div className="spacer"></div>
          <Button
            label={!discordLink ? `Skip for now` : `Finalize agent`}
            endIcon={ArrowRight}
            onClick={onContinue}
          />
        </div>
      </div>
    </div>
  );
};

const FinishStep: React.FC<StepComponentProps> = ({
  agent,
  agentName,
  onPrevious
}) => {
  const chatUrl = `/chat/${agent?.unique_id}?welcome=t`;
  
  return (
    <div className="finish-step">  
      <AgentTradingCard
        name={agentName}
        image="/logo/funct-logo-avatar-circle.svg"
        description="Your new AI assistant"
        isSpecial={true}
        button={{
          title: `Chat with me`,
          url: chatUrl
        }}
      />
    </div>
  );
};

// Step Tracker Component
const StepTracker: React.FC<StepTrackerProps> = ({ 
  steps, 
  currentStepId, 
  agent
}) => {
  const location = useLocation();
  const currentStepIndex = steps.findIndex(s => s.id === currentStepId);
  
  // Determine completed steps directly from agent object
  const isStepCompleted = (stepId: StepId): boolean => {
    if (!agent) return false;
    
    switch (stepId) {
      case 'name':
        return !!agent.agentConfigs?.[0]?.name;
      case 'prompt':
        return !!agent.agentConfigs?.[0]?.system_prompt;
      case 'connections':
        return !!agent.discordAgents?.length && !!agent.discordAgents[0]?.discordLink;
      default:
        return false;
    }
  };
  
  // Determine if a step should be clickable
  const isStepClickable = (stepId: StepId, index: number): boolean => {
    // First step is always clickable
    if (stepId === 'name') return true;
    
    // Other steps require an agent to exist
    return !!agent;
  };
  
  // Determine if a step is before or after the current step
  const getStepPosition = (index: number): 'before' | 'current' | 'after' => {
    if (index === currentStepIndex) return 'current';
    if (index < currentStepIndex) return 'before';
    return 'after';
  };
  
  return (
    <div className="step-tracker-container">
      <div className="step-tracker">
        {steps.map((step, index) => {
          // Create URL for link
          const linkTo = `${location.pathname}?step=${step.id}${agent?.unique_id ? `&agent_id=${agent.unique_id}` : ''}`;
          const isCompleted = isStepCompleted(step.id);
          const position = getStepPosition(index);
          
          const stepClassName = `step ${position} ${isCompleted ? 'completed' : ''} ${currentStepId === step.id ? 'active' : ''} ${isStepClickable(step.id, index) ? '' : 'disabled'}`;
          
          return (
            <React.Fragment key={step.id}>
              {index > 0 && (
                <div 
                  className={`step-connector ${index <= currentStepIndex ? 'before' : 'after'}`} 
                />
              )}
              
              {isStepClickable(step.id, index) ? (
                <Link 
                  to={linkTo}
                  className={stepClassName}
                >
                  <div className="step-icon">
                    <step.icon />
                  </div>
                  <div className="step-label">{step.label}</div>
                </Link>
              ) : (
                <div className={stepClassName}>
                  <div className="step-icon">
                    <step.icon />
                  </div>
                  <div className="step-label">{step.label}</div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

// Main Component
export function NewAgentFlow({ onAgentUpdated }: NewAgentFlowProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { organization } = useAuth();
  
  // Define all possible steps
  const steps: Step[] = [
    {
      id: 'name',
      label: 'Name',
      icon: Star,
      component: NameStep
    },
    {
      id: 'prompt',
      label: 'Prompt',
      icon: Smile,
      component: PromptStep
    },
    {
      id: 'connections',
      label: 'Connections',
      icon: Link2,
      component: ConnectionsStep
    },
    {
      id: 'finish',
      label: 'Chat',
      icon: MessageCircle,
      component: FinishStep
    }
  ];
  
  // Get current step from URL or determine based on agent state
  const getCurrentStepId = (): StepId => {
    const stepParam = searchParams.get('step') as StepId;
    
    // If step param exists and is valid, use it
    if (stepParam && steps.some(s => s.id === stepParam)) {
      // If trying to access a step other than 'name' with no agent, return 'name'
      if (!agent && !agentId && stepParam !== 'name') {
        return 'name';
      }
      return stepParam;
    }
    
    // Default to first step
    return 'name';
  };
  
  const [agentId, setAgentId] = useState<string>(searchParams.get('agent_id') || '');
  const [agentName, setAgentName] = useState('');
  const [agentPrompt, setAgentPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [agent, setAgent] = useState<AgentSchema | null>(null);
  
  // Current step
  const currentStepId = getCurrentStepId();
  const currentStep = steps.find(s => s.id === currentStepId) || steps[0];
  
  // Load existing agent if agent_id is provided
  useEffect(() => {
    if (agentId) {
      // Skip loading if we already have this agent in our state
      if (agent && agent.unique_id === agentId) {
        return;
      }
      
      setIsLoading(true);
      (async () => {
        try {
          const agentResult = await API.get('v1/agents/retrieve', { id: agentId });
          const loadedAgent = agentResult as AgentSchema;
          setAgent(loadedAgent);
          setAgentName(loadedAgent.agentConfigs?.[0]?.name || '');
          setAgentPrompt(loadedAgent.agentConfigs?.[0]?.system_prompt || '');
          
          // Notify parent component about the loaded agent
          if (onAgentUpdated) {
            onAgentUpdated(loadedAgent);
          }
          
          // If no step in search params, determine based on agent state
          if (!searchParams.get('step')) {
            let nextStepId: StepId = 'name';
            
            if (loadedAgent.agentConfigs?.[0]?.name) {
              nextStepId = 'prompt'; // Go to prompt step if name exists
              
              if (loadedAgent.agentConfigs?.[0]?.system_prompt) {
                nextStepId = 'connections'; // Go to connections step if prompt exists
              }
            }
            
            updateURLWithStep(nextStepId);
          }
        } catch (e) {
          console.error(e);
          // Reset agent_id and redirect to first step if agent is invalid
          setAgentId('');
          setAgent(null);
          const newParams = new URLSearchParams();
          newParams.set('step', 'name');
          setSearchParams(newParams);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [agentId]);
  
  // Helper to update URL with current step
  const updateURLWithStep = (stepId: StepId) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('step', stepId);
    if (agentId && agent) { // Only include agent_id if we have a valid agent
      newParams.set('agent_id', agentId);
    }
    setSearchParams(newParams);
  };

  const handleCreateAgent = async () => {
    setIsSaving(true);
    try {
      const agentResult = await API.post(
        "v1/agents",
        {
          organization: organization.name,
          name: agentName,
          prompt: `<auto>`
        }
      );
      
      const createdAgent = agentResult as AgentSchema;
      setAgent(createdAgent);
      
      // Update agent ID and add to URL params
      const newAgentId = createdAgent.unique_id;
      setAgentId(newAgentId);
      
      // Update URL with both step and agent_id
      const newParams = new URLSearchParams(searchParams);
      newParams.set('step', 'prompt');
      newParams.set('agent_id', newAgentId);
      setSearchParams(newParams);
      
      setAgentPrompt(createdAgent.agentConfigs[0].system_prompt);
      
      if (onAgentUpdated) {
        onAgentUpdated(createdAgent);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdateAgentName = async () => {
    if (!agentId) return;
    
    setIsSaving(true);
    try {
      const agentResult = await API.put(
        "v1/agents",
        {
          id: agentId,
          name: agentName,
          prompt: `<auto>`
        }
      );
      
      const updatedAgent = agentResult as AgentSchema;
      setAgent(updatedAgent);
      setAgentPrompt(updatedAgent.agentConfigs[0].system_prompt);
      
      // Update URL to next step
      updateURLWithStep('prompt');
      
      // Always notify parent component about the updated agent
      if (onAgentUpdated) {
        onAgentUpdated(updatedAgent);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdatePrompt = async () => {
    if (!agent) return;
    
    setIsSaving(true);
    try {
      const agentResult = await API.put(
        "v1/agents",
        {
          id: agent.unique_id,
          prompt: agentPrompt
        }
      );
      
      const updatedAgent = agentResult as AgentSchema;
      setAgent(updatedAgent);
      
      // Notify parent component about the updated agent
      if (onAgentUpdated) {
        onAgentUpdated(updatedAgent);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  const handleContinue = async () => {
    if (currentStepId === 'name') {
      if (agentId) {
        // If agent exists and name hasn't changed, just go to next step
        if (agentName === agent?.agentConfigs?.[0]?.name) {
          updateURLWithStep('prompt');
        } else {
          // Update existing agent if name has changed
          await handleUpdateAgentName();
          // Make sure onAgentUpdated is called with the latest state
          if (onAgentUpdated && agent) {
            onAgentUpdated({
              ...agent,
              agentConfigs: [
                {
                  ...agent.agentConfigs[0],
                  name: agentName
                }
              ]
            });
          }
        }
      } else {
        // Create new agent if no agent_id exists
        await handleCreateAgent();
      }
    } else if (currentStepId === 'prompt') {
      if (agentPrompt !== agent?.agentConfigs[0]?.system_prompt) {
        await handleUpdatePrompt();
        // Make sure onAgentUpdated is called with the latest state
        if (onAgentUpdated && agent) {
          onAgentUpdated({
            ...agent,
            agentConfigs: [
              {
                ...agent.agentConfigs[0],
                system_prompt: agentPrompt
              }
            ]
          });
        }
      }
      updateURLWithStep('connections');
    } else if (currentStepId === 'connections') {
      // Go to finish step instead of navigating to chat
      updateURLWithStep('finish');
    } else if (currentStepId === 'finish') {
      // Navigate to chat
      navigate(`/chat/${agentId}`);
    }
  };

  const handleLinkDiscord = async () => {
    if (!agent) return;
    
    // If prompt was updated, save it first
    if (agentPrompt !== agent.agentConfigs[0]?.system_prompt) {
      await handleUpdatePrompt();
    }
    
    try {
      const linkResult = await API.post(
        'v1/discord_links',
        {
          organization: organization.name,
          agent_id: agent.unique_id,
          successPath: `/chat/new?agent_id=${agent.unique_id}&step=connections`
        }
      );
      const url = linkResult?.url;
      if (url) {
        window.location = url;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handlePrevious = () => {
    if (currentStepId === 'prompt') {
      updateURLWithStep('name');
    } else if (currentStepId === 'connections') {
      updateURLWithStep('prompt');
    } else if (currentStepId === 'finish') {
      updateURLWithStep('connections');
    }
  };

  // Add this useEffect to clean up invalid URL parameters on initial load
  useEffect(() => {
    // If there's a step param but no agent_id (except for 'name' step), clear the step param
    const stepParam = searchParams.get('step') as StepId;
    const agentIdParam = searchParams.get('agent_id');
    
    if (stepParam && stepParam !== 'name' && !agentIdParam) {
      const newParams = new URLSearchParams();
      newParams.set('step', 'name');
      setSearchParams(newParams);
    }
  }, []); // Empty dependency array to run only on mount

  if (isLoading) {
    return (
      <div data-component="NewAgentFlow" className="loading">
        <div className="loading-text">
          <Loader className="loading-icon" />
        </div>
      </div>
    );
  }

  // Render current step component
  const CurrentStepComponent = currentStep.component;

  return (
    <div data-component="NewAgentFlow" data-state={currentStepId}>
      <StepTracker 
        steps={steps}
        currentStepId={currentStepId}
        agent={agent}
      />
      <CurrentStepComponent
        agent={agent}
        agentName={agentName}
        agentPrompt={agentPrompt}
        setAgentName={setAgentName}
        setAgentPrompt={setAgentPrompt}
        isSaving={isSaving}
        onContinue={handleContinue}
        onPrevious={handlePrevious}
        onLinkDiscord={handleLinkDiscord}
      />
    </div>
  );
}